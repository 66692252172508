import { IObservableArray, observable, makeObservable } from 'mobx';
import CompanyQueryResponseModel from '../common/CompanyQueryResponseModel';
import NameIdSearchResponseModel from '../common/NameIdSearchResponseModel';
import ProvincialTaxRateModel from '../finance/ProvincialTaxRateModel';

class AgentQuerySearchCriteriaResponseModel {
  constructor() {
    makeObservable(this);
    this.Cities = observable.array(new Array<string>());
    this.Companies = observable.array(new Array<CompanyQueryResponseModel>());
    this.Hotels = observable.array(new Array<NameIdSearchResponseModel>());
    this.ProvincialTaxRates = observable.array(new Array<ProvincialTaxRateModel>());
  }

  @observable
  public Cities: IObservableArray<string>;

  @observable
  public Companies: IObservableArray<CompanyQueryResponseModel>;
  @observable
  public Hotels: IObservableArray<NameIdSearchResponseModel>;
  @observable
  public ProvincialTaxRates: IObservableArray<ProvincialTaxRateModel>;
}
export default AgentQuerySearchCriteriaResponseModel;
