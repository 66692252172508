import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const xs = defineStyle({
  fontSize: 'xs'
});

const xsContainer = defineStyle({
  padding: '2px'
});

const sizes = {
  xs: definePartsStyle({ title: xs, description: xs, container: xsContainer })
};

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    borderRadius: '8px',
    padding: '6px 8px 6px 8px'
  }
});

export const alertTheme = defineMultiStyleConfig({ baseStyle, sizes });
