import { observer } from 'mobx-react-lite';
import React from 'react';
import ConfirmationCallout from '../../../../components/callout/ConfirmationCallout';
import ActivityDisplayPriorityNotesField from '../../../../components/forms/inputfields/activities/ActivityDisplayPriorityNotesField';
import HotelBookingQuoteInquiryModel from '../../../../models/QuoteInquiry/HotelBookingQuoteInquiryModel';
import ActivityManagementService from '../../../../services/Activities/ActivityManagementService';
import { PaddedBox } from '../../EditActivity/styledComponents';

interface IInboxContextMenuCommentsCallout {
  model: HotelBookingQuoteInquiryModel;
  activityId: number;
}

const InboxContextMenuCommentsCallout: React.FC<IInboxContextMenuCommentsCallout> = (
  props: IInboxContextMenuCommentsCallout
) => {
  const service = new ActivityManagementService();
  const onSubmit = () => {
    service.updateActivityPriorityNote(props.activityId, props.model.PriorityNote);
  };
  return (
    <ConfirmationCallout
      okCallback={onSubmit}
      header={'Add or edit comment, clicking ok will save and close this window'}>
      <PaddedBox>
        <ActivityDisplayPriorityNotesField model={props.model} />
      </PaddedBox>
    </ConfirmationCallout>
  );
};

export default observer(InboxContextMenuCommentsCallout);
