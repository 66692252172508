import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';
import React from 'react';
import { useId } from 'src/utils/uiFabricHooks';
import FaIcon, { IFaIcon } from './FaIcon';

interface IFaIconWithToolTip extends IFaIcon {
  tooltip: string | JSX.Element;
  directionalHint?: DirectionalHint;
}

const FaIconWithToolTip: React.FC<IFaIconWithToolTip> = (props: IFaIconWithToolTip) => {
  const tooltipId = useId('tooltip');
  return (
    <TooltipHost
      content={props.tooltip}
      delay={TooltipDelay.medium}
      directionalHint={props.directionalHint ? props.directionalHint : DirectionalHint.rightCenter}>
      <FaIcon aria-describedby={tooltipId} {...props} />
    </TooltipHost>
  );
};

export default FaIconWithToolTip;
