import { observable, makeObservable } from 'mobx';
import ShowActiveModel from '../common/ShowActiveModel';

class HotelExpandedQueryModel extends ShowActiveModel {
  constructor() {
    super();
    makeObservable(this);
    this.Query = '';
  }

  @observable
  public Query: string;
  @observable
  public HotelName: boolean = false;
  @observable
  public Email: boolean = false;
  @observable
  public PhoneNumber: boolean = false;
  @observable
  public FaxNumber: boolean = false;
  @observable
  public GeneralManager: boolean = false;
  @observable
  public SalesManager: boolean = false;
  @observable
  public HotelNotes: boolean = false;
  @observable
  public Brand: boolean = false;
  @observable
  public BrandContact: boolean = false;
  @observable
  public BrandManagement: boolean = false;
  @observable
  public BrandOwnership: boolean = false;
  @observable
  public Brandnotes: boolean = false;
  @observable
  public Country: boolean = false;
  @observable
  public City: boolean = false;
  @observable
  public Address: boolean = false;
  @observable
  public Province: boolean = false;
  @observable
  public PostalCode: boolean = false;
  @observable
  public AirportCodes: boolean = false;
}
export default HotelExpandedQueryModel;
