import { Box, Button, Input, ModalFooter, Select, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useContext, useMemo, useRef, useState } from 'react';
import {
  WorkflowGroupActionModel,
  WorkflowGroupActionType,
  WorkflowGroupCurrentStatusModel,
  WorkflowGroupUserModel
} from './Types';
import { TimeOptions } from './Utils';
import { WorkflowContext } from './WorkflowProvider';
import { ChakraDialog } from '@cksoftware/react-base';

interface WorkflowActionButtonProps {
  action: WorkflowGroupActionModel;
  count: number;
  userId: string;
  user: WorkflowGroupUserModel;
  userStatus: WorkflowGroupCurrentStatusModel;
}

export function WorkflowActionButton({ action, count, userId, user, userStatus }: WorkflowActionButtonProps) {
  const [model, invoke] = useContext(WorkflowContext);
  const [status, setStatus] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const disclosure = useDisclosure();
  const timeArray = useMemo(() => {
    if (action.RequiresStatusMessage) {
      return TimeOptions();
    }
    return [];
  }, []);
  const cancelRef = useRef();
  const isActive =
    userStatus.AssignedAction == action.ActionName ||
    (action.Type == WorkflowGroupActionType.Assignment &&
      userStatus.AssignedActionType == WorkflowGroupActionType.Assigned) ||
    action.Type == WorkflowGroupActionType.Status;
  const showCount = action.Type == WorkflowGroupActionType.Assignment;
  const isEnabled =
    // @ts-expect-error added by automation
    (model.data?.CurrentUser?.UserId == model.data.InboxManagerId &&
      // @ts-expect-error added by automation
      model.data.InboxManagerId &&
      ((userStatus.AssignedActionType == WorkflowGroupActionType.Assigned &&
        action.Type == WorkflowGroupActionType.Assignment) ||
        action.Type == WorkflowGroupActionType.Ready ||
        action.Type == WorkflowGroupActionType.Status ||
        (userStatus.AssignedActionType == WorkflowGroupActionType.Ready &&
          action.Type == WorkflowGroupActionType.Assigned))) ||
    (model.data?.CurrentUser?.UserId == userId &&
      ((userStatus.AssignedActionType == WorkflowGroupActionType.Assigned &&
        action.Type == WorkflowGroupActionType.Assignment) ||
        action.Type == WorkflowGroupActionType.Ready ||
        action.Type == WorkflowGroupActionType.Status ||
        (userStatus.AssignedActionType == WorkflowGroupActionType.Ready &&
          action.Type == WorkflowGroupActionType.Assigned))) ||
    (userStatus.AssignedActionType == WorkflowGroupActionType.Status &&
      action.Type != WorkflowGroupActionType.Assignment);

  return (
    <>
      <Tooltip label={`${action.ActionName}`}>
        <Box
          cursor={isEnabled ? 'pointer' : 'not-allowed'}
          userSelect={'none'}
          m={'0'}
          shadow={'black 1px 1px'}
          bg={isActive ? action.ColorHexCode : 'grey'}
          disabled={!isEnabled}
          height={'1.5em'}
          width={'1.5em'}
          position={'relative'}
          p={'0'}
          as='button'
          borderRadius={'0'}
          outline={'none'}
          display={'flex'}
          flexDir={'column'}
          _focus={{
            boxShadow: 'inset 0px 0px 5px white',
            outline: 'none'
          }}
          color={'Menu'}
          onClick={() => {
            if (action.RequiresStatusMessage) {
              disclosure.onOpen();
            } else {
              // @ts-expect-error added by automation
              invoke('ActionAssigned', user, action.SortOrder, action.Id, userId, model.data.WorkflowGroupId, '', null);
            }
          }}>
          <Text pl={'0.1em'} textAlign={'center'} alignSelf={'center'} fontSize={'0.7em'} as={'b'}>
            {action.ActionAbbreviation}
          </Text>
          <Text pos={'absolute'} bottom={'-1.7em'} right={0} fontSize={'0.7em'} pr={'0.2em'}>
            {showCount && typeof count == 'number' && Math.min(count, 999)}
          </Text>
        </Box>
      </Tooltip>
      {action.RequiresStatusMessage && (
        <ChakraDialog header={`Set ${action.ActionName} status`} disclosure={disclosure}>
          <Input
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            placeholder='Away Message'
          />
          <Select
            placeholder='Return Time'
            onChange={(e) => {
              setReturnDate(e.target.value);
            }}>
            {timeArray}
          </Select>
          <ModalFooter>
            <Button
              // @ts-expect-error added by automation
              ref={cancelRef}
              variant={'secondary'}
              onClick={() => {
                setStatus('');
                // @ts-expect-error added by automation
                setReturnDate(undefined);
                disclosure.onClose();
              }}
              mr={3}>
              Cancel
            </Button>
            <Button
              isDisabled={!returnDate || !status}
              onClick={() => {
                invoke(
                  'ActionAssigned',
                  user,
                  action.SortOrder,
                  action.Id,
                  userId,
                  // @ts-expect-error added by automation
                  model.data.WorkflowGroupId,
                  status,
                  returnDate
                );
                setStatus('');
                // @ts-expect-error added by automation
                setReturnDate(undefined);
                disclosure.onClose();
              }}>
              Set Status
            </Button>
          </ModalFooter>
        </ChakraDialog>
      )}
    </>
  );
}
