import { action, observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';
import { IActiveStateModel } from './Interfaces/IActiveStateModel';

class ShowActiveModel extends EntityFormModel implements IActiveStateModel {
  public constructor(active: boolean = true) {
    super();
    makeObservable(this);
    this.Active = active;
  }

  @observable
  public Active: boolean;

  @action
  public Toggle = () => {
    this.Active = !this.Active;
  };
}

export default ShowActiveModel;
