import { CompanyModel } from '../companies/CompanyModel';
import FormModel from '../forms/FormModel';

class AgentQuerySearchCompanyResultModel extends FormModel {
  public constructor() {
    super();
    this.Company = new CompanyModel();
  }

  public Company: CompanyModel;

  public SetData(dbData: any): void {
    this.Company.SetData(dbData.Company);
  }
}
export default AgentQuerySearchCompanyResultModel;
