import { observable, makeObservable, action, IObservableArray } from 'mobx';
import ShowApprovedModel from '../common/ShowApprovedModel';
import CompanyProjectStatus from '../../enums/crm/CompanyProjectStatus';

class GroupQueryModel extends ShowApprovedModel {
  constructor() {
    super();
    makeObservable(this);
    this.Status = CompanyProjectStatus.None;
    this.Filter = '';
    this.Filter = '';
    this.City = '';
    this.Company = '';
    this.AssignedUserIds = observable.array(new Array<number>());
  }

  @observable
  public Status: CompanyProjectStatus;

  @observable
  public City: string;

  @observable
  public Company: string;

  @observable
  public AssignedUserIds: IObservableArray<number>;

  @observable
  // @ts-expect-error added by automation
  public StartDate: string;

  @observable
  // @ts-expect-error added by automation
  public EndDate: string;

  @observable
  public Filter: string;

  @action
  public Reset = () => {
    this.Status = CompanyProjectStatus.None;
    this.Filter = '';
    this.City = '';
    this.Company = '';
    this.AssignedUserIds.length = 0;
    // @ts-expect-error added by automation
    this.StartDate = null;
    // @ts-expect-error added by automation
    this.EndDate = null;
  };
}
export default GroupQueryModel;
