import DropdownItem from '../../models/forms/DropdownItem';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class UserListService extends ServiceBase {
  constructor() {
    super(endpoints.selectList.getAllUsers);
  }

  public getUsers = async () => {
    const response = await this.get<DropdownItem[]>();
    return response.data.map((x) => new DropdownItem(`${x.Text}`, x.Value));
  };
  public getGroupUsers = async () => {
    const origEnpoint = this.endpoint;
    this.endpoint = endpoints.selectList.getAllGroupUsers;
    const response = await this.get<DropdownItem[]>();
    this.endpoint = origEnpoint;
    return response.data.map((x) => new DropdownItem(`${x.Text}`, x.Value));
  };
}

export default UserListService;
