import { initializeIcons } from '@fluentui/react';

import { Box } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'bootstrap/dist/css/bootstrap.css';
import dayjs from 'dayjs';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { RouterProvider } from 'react-router-dom';
import ActivityOpenNotificationProvider from 'src-new/features/inbox/activityOpenNotification/ActivityOpenNotificationProvider';
import { useFrontierAuth } from 'src-new/util/auth/useFrontierAuth';
import { useUserStore } from 'src/stores/useMobxStores';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import { ErrorContainer } from './ErrorContainer';
import { FrontierRoutes } from './routes/FrontierRoutes';
// don't allow state modifications outside actions
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});
//configure({ enforceActions: 'observed' });

//This adds support for UTC and local timezone conversions to dayjs
dayjs.extend(dayjsPluginUTC);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);

//This loads icons for the MS Office UI Fabric library throughout the application
initializeIcons();

const tanStackClient = new QueryClient();

export let portalRef: any;

const App = () => {
  const userStore = useUserStore();
  const { initUser, hasLoaded } = useFrontierAuth();
  const portalRef = useRef(null);
  window.global ||= window;

  useEffect(() => {
    async function initAuth() {
      await initUser();
      await userStore.LazyUserModel.current();
    }

    initAuth();
  }, []);

  return (
    <QueryClientProvider client={tanStackClient}>
      <ThemeProvider theme={theme}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ActivityOpenNotificationProvider>
          <ErrorContainer>
            <Box ref={portalRef}>
              {hasLoaded && !userStore.LazyUserModel.pending && <RouterProvider router={FrontierRoutes} />}
            </Box>
          </ErrorContainer>
        </ActivityOpenNotificationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default observer(App);
