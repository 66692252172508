import { observable, makeObservable } from 'mobx';
import { IFileModel } from '../activities/IFileModelInterface';
import { INotesModel } from '../common/Interfaces/INotesModel';
import EntityFormModel from '../forms/EntityFormModel';

export class HotelCustomPaymentModel extends EntityFormModel implements INotesModel, IFileModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public Notes: string;
}
