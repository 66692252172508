import { computed, makeObservable } from 'mobx';
import ActivityPagingModel from '../../models/activities/ActivityPagingModel';
import ActivitySortFilterModel from '../../models/activities/ActivitySortFilterModel';

class InboxManagerPrioritySortProvider {
  public ActivitySortFilterModel: ActivitySortFilterModel;
  private pagingModel: ActivityPagingModel;

  public constructor(pagingModel: ActivityPagingModel) {
    this.pagingModel = pagingModel;
    makeObservable(this);
    this.ActivitySortFilterModel = new ActivitySortFilterModel();
  }

  @computed
  public get PriorityList() {
    return this.pagingModel.Activities.slice()
      .filter((x) => x.ManagerIndex != null)
      .sort((a, b) => {
        if (a.ManagerIndex === null) return 1; // or -1 to move null to the beginning
        if (b.ManagerIndex === null) return -1; // or 1 to move null to the beginning
        return a.ManagerIndex - b.ManagerIndex;
      });
  }
}

export default InboxManagerPrioritySortProvider;
