import { IObservableArray, action, makeObservable, observable } from 'mobx';
import CompanyProjectGroupType from 'src/enums/crm/CompanyProjectGroupType';
import CompanyProjectApproval from '../../enums/crm/CompanyProjectApproval';
import CompanyProjectStatus from '../../enums/crm/CompanyProjectStatus';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import HotelBookingQuoteInquiryHotelRateModel from '../QuoteInquiry/HotelBookingQuoteInquiryHotelRateModel';
import ActivityModel from '../activities/ActivityModel';
import { IActiveStateModel } from '../common/Interfaces/IActiveStateModel';
import ICityModel from '../common/Interfaces/ICityModel';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';
import FopModel from '../groups/FopModel';
import GroupAccountingNoteModel from '../groups/GroupAccountingNoteModel';
import { Hotel } from '../hotel/Hotel';
import { CompanyProjectState } from './CompanyProjectState';

export class CompanyProject extends TimeSeriesEntityFormModel implements ICityModel, IActiveStateModel {
  public constructor() {
    super();
    makeObservable(this);
    this.Active = true;
    this.Hotels = observable.array(new Array<Hotel>());
    this.ProjectStates = observable.array(new Array<CompanyProjectState>());
    this.Activity = new ActivityModel();
    this.Notes = new Array<string>();
    this.AccountingNotes = new Array<GroupAccountingNoteModel>();
    this.FopList = new Array<FopModel>() as IObservableArray;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['Hotels', 'Activities', 'ProjectStates', 'FopList']);
    arraySetData(this.Hotels, Hotel, dbData.Hotels);
    if (this.ProjectStates != null && dbData.ProjectStates != null) {
      arraySetData(this.ProjectStates, CompanyProjectState, dbData.ProjectStates);
    }
    arraySetData(this.FopList, FopModel, dbData.FopList);
  }

  @observable
  public FopList: IObservableArray<FopModel>;
  @observable
  // @ts-expect-error added by automation
  public Amenity: string;
  @observable
  // @ts-expect-error added by automation
  public ClientName: string;
  @observable
  // @ts-expect-error added by automation
  public ContactName: string;
  @observable
  // @ts-expect-error added by automation
  public PhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public ProjectLocation: string;
  @observable
  // @ts-expect-error added by automation
  public NumberWorkingOnProject: number;
  @observable
  // @ts-expect-error added by automation
  public IsNightShift: boolean;
  @observable
  // @ts-expect-error added by automation
  public ProjectStage: string;
  @observable
  // @ts-expect-error added by automation
  public NightShiftTime: string;
  @observable
  // @ts-expect-error added by automation
  public DelayDescription: string;
  @observable
  // @ts-expect-error added by automation
  public RampDescription: string;
  @observable
  // @ts-expect-error added by automation
  public DidStartInReservations: boolean;
  @observable
  // @ts-expect-error added by automation
  public RoomsRequiredDuration: number;
  @observable
  // @ts-expect-error added by automation
  public ParkingRequirements: string;
  @observable
  // @ts-expect-error added by automation
  public IsJobCodingSame: string;
  @observable
  // @ts-expect-error added by automation
  public Budget: number;
  @observable
  // @ts-expect-error added by automation
  public CityDescription: string;
  @observable
  // @ts-expect-error added by automation
  public HotelDescription: string;
  @observable
  public Active: boolean;
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public ContactId: number;
  @observable
  // @ts-expect-error added by automation
  public Information: string;
  @observable
  // @ts-expect-error added by automation
  public Updates: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;
  @observable
  // @ts-expect-error added by automation
  public Status: CompanyProjectStatus;
  @observable
  public Hotels: IObservableArray<Hotel>;

  @observable
  public ProjectStates: IObservableArray<CompanyProjectState>;

  @observable
  // @ts-expect-error added by automation
  public approval: CompanyProjectApproval;

  @observable
  // @ts-expect-error added by automation
  public HotelContact: string;
  @observable
  // @ts-expect-error added by automation
  public Rate: string;
  @observable
  // @ts-expect-error added by automation
  public BookingMethod: string;
  @observable
  // @ts-expect-error added by automation
  public Coding: string;
  @observable
  // @ts-expect-error added by automation
  public Fop: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
  @observable
  // @ts-expect-error added by automation
  public CompanyName: string;
  @observable
  // @ts-expect-error added by automation
  public AssignedToUserId: string;
  @observable
  // @ts-expect-error added by automation
  public AssignedToUserName: string;
  @observable
  // @ts-expect-error added by automation
  public TypeOfGroup: CompanyProjectGroupType;
  @observable
  // @ts-expect-error added by automation
  public NumberOfRooms: string;
  @observable
  // @ts-expect-error added by automation
  public RoomType: string;
  @observable
  // @ts-expect-error added by automation
  public BillingPeriod: string;
  @observable
  // @ts-expect-error added by automation
  public BarRate: string;
  @observable
  public Activity: ActivityModel;
  @observable
  // @ts-expect-error added by automation
  public Commission: string;

  @observable
  // @ts-expect-error added by automation
  public isBreakfast: boolean;
  @observable
  // @ts-expect-error added by automation
  public isLaundryServices: boolean;
  @observable
  // @ts-expect-error added by automation
  public isMeetingRoom: boolean;
  @observable
  // @ts-expect-error added by automation
  public isPool: boolean;
  @observable
  // @ts-expect-error added by automation
  public isRestaurant: boolean;
  @observable
  // @ts-expect-error added by automation
  public isBaggedLunch: boolean;
  @observable
  // @ts-expect-error added by automation
  public isOtherAmenity: boolean;

  //Only used in companyproject crm display
  @observable
  public Notes: Array<string>;

  @observable
  public AccountingNotes: Array<GroupAccountingNoteModel>;

  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;

  @observable
  // @ts-expect-error added by automation
  public isInquiryComplete: boolean;

  @observable
  // @ts-expect-error added by automation
  public inquiryStatus: number;

  @observable
  // @ts-expect-error added by automation
  public assignmentStatus: number;

  @observable
  // @ts-expect-error added by automation
  public sourcingStatus: number;

  @observable
  // @ts-expect-error added by automation
  public contractStatus: number;

  @observable
  // @ts-expect-error added by automation
  public launchStatus: number;

  @observable
  // @ts-expect-error added by automation
  public maintenanceStatus: number;

  @observable
  // @ts-expect-error added by automation
  public completionStatus: number;

  @observable
  // @ts-expect-error added by automation
  public IsDirectBill: boolean;

  @observable
  // @ts-expect-error added by automation
  public IsShortPay: boolean;

  @observable
  // @ts-expect-error added by automation
  public ShortPay: number;

  @observable
  // @ts-expect-error added by automation
  public earlycheckoutpolicy: string;

  @observable
  // @ts-expect-error added by automation
  public DirectBillHow: string;

  @observable
  // @ts-expect-error added by automation
  public cancellationPolicy: string;

  @observable
  // @ts-expect-error added by automation
  public ItinerarySelectedRate: HotelBookingQuoteInquiryHotelRateModel;

  @action
  public AddFop = () => {
    let newFop = new FopModel();
    this.FopList.push(newFop);
  };

  @action
  public RemoveFop = (index: number) => {
    this.FopList.splice(index, 1);
  };
}
