import styled from 'styled-components';

interface IStyledDetailsWrapper {
  height: string;
}
export const StyledDetailsWrapper = styled.div<IStyledDetailsWrapper>`
  width: 100%;
  height: ${(props) => props.height};
  overflow: auto;
  z-index: 0;
  position: relative;
`;
interface IActivityReportWrapper {
  active?: boolean;
}
export const ActivityReportWrapper = styled.div<IActivityReportWrapper>`
  width: calc(100vw - 15px);
  margin-bottom: 1em;
  height: ${(props) => (props.active ? '66vh' : '63vh')};
  overflow: auto;
  z-index: 0;
  position: relative;
`;
export const ActivityReportDialogWrapper = styled.div<IActivityReportWrapper>`
  width: 100%;
  margin-bottom: 1em;
  height: 63vh;
  overflow: auto;
  z-index: 0;
  position: relative;
`;

interface Color {
  backgroundColor?: string;
  color: string;
}
export const PriorityDisplay = styled.div<Color>`
  font-size: 13px;
  min-width: 32px;
  padding-left: 5px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.color};
`;

export const ActivityReportPriorityDisplay = styled(PriorityDisplay)<Color>`
  margin-right: 40px;
`;
