import { makeObservable, observable } from 'mobx';
import SystemRefreshHub from '../hubs/SystemRefresh/SystemRefreshHub';

class SystemRefreshStore {
  // @ts-expect-error added by automation
  private hub: SystemRefreshHub;

  @observable
  public IsRefreshing: boolean;

  @observable
  public RefreshMinutesLeft: number;

  constructor() {
    this.RefreshMinutesLeft = 10;
    this.IsRefreshing = false;
    makeObservable(this);
  }
}

export default SystemRefreshStore;
