import { makeObservable, observable } from 'mobx';
import ActivityAlertLevel from '../../enums/activities/ActivityAlertLevel';
import ActivityStatus from '../../enums/activities/ActivityStatus';
import EntityFormModel from '../forms/EntityFormModel';
import { IEmailActivityOverviewModel } from './IEmailActivityOverviewModel';
import MyActivityModel from './MyActivityModel';
import { getFirstActivityEmailNote } from 'src/utils/activities/activityModelHelpers';
import ActivityModel from './ActivityModel';

class ActivityContextMenuDefinitionModel extends EntityFormModel {
  public constructor() {
    super();
    makeObservable(this);
  }

  @observable
  // @ts-expect-error added by automation
  public AssignedToUserId: string;

  @observable
  // @ts-expect-error added by automation
  public AlertLevel: ActivityAlertLevel;

  @observable
  // @ts-expect-error added by automation
  public ActivityType: number;

  @observable
  // @ts-expect-error added by automation
  public HotelBookingQuoteInquiryId: number;

  @observable
  // @ts-expect-error added by automation
  public ItinerarySelectedRateId: number;

  @observable
  // @ts-expect-error added by automation
  public PriorityNote: string;

  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;

  @observable
  // @ts-expect-error added by automation
  public Subject: string;

  @observable
  // @ts-expect-error added by automation
  public From: string;

  @observable
  // @ts-expect-error added by automation
  public Status: ActivityStatus;

  @observable
  public ReservationNumber: string | undefined;

  public SetDataFromOverviewModel = (data: IEmailActivityOverviewModel) => {
    this.Id = data.ActivityId;
    this.AssignedToUserId = data.AssignedToUserId;
    this.AlertLevel = data.AlertLevel;
    this.ActivityType = data.ActivityType;
    // @ts-expect-error added by automation
    this.HotelBookingQuoteInquiryId = data.HotelBookingQuoteInquiryId;
    // @ts-expect-error added by automation
    this.ItinerarySelectedRateId = data.ItinerarySelectedRateId;
    this.PriorityNote = data.PriorityNote;
    this.CreatedOn = data.ActivityCreatedOn;
    this.Subject = data.Subject;
    this.From = data.From;
    this.Status = data.Status;
    this.ReservationNumber = data.ReservationNumber;
  };

  public SetDataFromActivityModel = (data: MyActivityModel) => {
    this.Id = data.Id;
    this.AssignedToUserId = data.AssignedToUserId;
    this.AlertLevel = data.AlertLevel;
    this.ActivityType = data.ActivityType;
    this.HotelBookingQuoteInquiryId = data.HotelBookingQuoteInquiryId;
    this.ItinerarySelectedRateId = data.ItinerarySelectedRateId;
    this.PriorityNote = data.PriorityNote;
    this.CreatedOn = data.CreatedOn;
    const email = getFirstActivityEmailNote(data);
    // @ts-expect-error added by automation
    this.Subject = email?.ClientEmail.Subject;
    // @ts-expect-error added by automation
    this.From = email?.ClientEmail.From;
    this.Status = data.Status;
  };
  public SetDataForFolio = (data: ActivityModel) => {
    this.Id = data.Id;
    this.AssignedToUserId = data.AssignedToUserId;
    this.AlertLevel = data.AlertLevel;
    this.ActivityType = data.ActivityType;
    this.HotelBookingQuoteInquiryId = data.HotelBookingQuoteInquiry.Id;
    this.ItinerarySelectedRateId = data.HotelBookingQuoteInquiry.ItinerarySelectedRateId;
    this.PriorityNote = data.HotelBookingQuoteInquiry.PriorityNote;
    this.CreatedOn = data.CreatedOn;
    const email = getFirstActivityEmailNote(data);
    // @ts-expect-error added by automation
    this.Subject = email?.ClientEmail.Subject;
    // @ts-expect-error added by automation
    this.From = email?.ClientEmail.From;
    this.Status = data.Status;
  };
}

export default ActivityContextMenuDefinitionModel;
