import { action, observable, makeObservable } from 'mobx';

class SpinnerOverlayState {
  @observable
  public Message: string;

  @observable
  public IsOpen: boolean;

  public constructor() {
    makeObservable(this);
    this.IsOpen = false;
    this.Message = '';
  }

  @action
  public open = (message: string = '') => {
    this.IsOpen = true;
    this.Message = message;
  };

  @action
  public close = () => {
    this.IsOpen = false;
  };
}

export default SpinnerOverlayState;
