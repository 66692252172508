import { ActivityNote } from 'src-new/features/inbox/types/Activity';

//these are used to display info based on the type i.e. Tooltip values in
export enum ModelType {
  Inbound = 'inbound',
  Outbound = 'outbound',
  FollowUp = 'follow up',
  Note = 'note',
  File = 'file',
  Mir = 'mir file',
  System = 'System'
}

export interface ExtractedElements {
  width?: string;
  modelType: ModelType;
  item: ActivityNote;
  isPdf?: boolean;
}
