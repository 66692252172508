import { action, IObservableArray, makeObservable, observable } from 'mobx';
import AgentQueryTravellersResponseModel from 'src/models/agent/AgentQueryTravellersResponseModel';
import AgentQueryModel from '../models/agent/AgentQueryModel';
import AgentQueryResponseModel from '../models/agent/AgentQueryResponseModel';
import AgentQuerySearchCriteriaResponseModel from '../models/agent/AgentQuerySearchCriteriaResponseModel';
import HotelVerificationReturnModel from '../models/authorization/HotelVerificationReturnModel';
import DropdownItem from '../models/forms/DropdownItem';
import { Amenity } from '../models/hotel/Amenity';
import { Hotel } from '../models/hotel/Hotel';
import AgentQuerySearchModel from '../models/search/AgentQuerySearchModel';
import AgentHotelQueryService from '../services/Agent/AgentHotelQueryService';
import HotelAutoCompleteService from '../services/UserManagement/HotelAutoCompleteService';
import { stringIsNullOrEmpty } from '../utils/helpers/stringHelpers';

class AgentStore {
  constructor() {
    makeObservable(this);
    this.ResponseModel = new AgentQueryResponseModel();
    this.QueryModel = new AgentQueryModel();
    this.SearchCriteriaModel = new AgentQuerySearchCriteriaResponseModel();
    this.GeneralSearchModel = new AgentQuerySearchModel();
    this.AgentRatePanelOpen = false;
    this.Amenities = observable.array(new Array<Amenity>());
    this.HotelRatesToCompare = new Array<Hotel>();
    this.HotelsDropdown = observable.array(new Array<DropdownItem>());
    this.CheckHotelGalileoId = new HotelVerificationReturnModel();
    this.CheckHotelNonGdsId = new HotelVerificationReturnModel();
    this.TravellersList = observable.array(new Array<AgentQueryTravellersResponseModel>());
  }

  public QueryModel: AgentQueryModel;
  public ResponseModel: AgentQueryResponseModel;
  public SearchCriteriaModel: AgentQuerySearchCriteriaResponseModel;
  public GeneralSearchModel: AgentQuerySearchModel;
  public CheckHotelGalileoId: HotelVerificationReturnModel;
  public CheckHotelNonGdsId: HotelVerificationReturnModel;

  @observable
  public AgentRatePanelOpen: boolean;

  @observable
  public HotelRatesToCompare: Array<Hotel>;

  @observable
  public HotelsDropdown: IObservableArray<DropdownItem>;
  @observable
  public Amenities: IObservableArray<Amenity>;

  @observable
  public TravellersList: IObservableArray<AgentQueryTravellersResponseModel>;

  @action
  public ResetQueryModel = () => {
    this.QueryModel.Reset();
  };

  @action
  public ResetGeneralSearchModel = () => {
    this.GeneralSearchModel.Reset();
  };

  @action
  public PopulateSearchCriteria = async () => {
    const service = new AgentHotelQueryService();
    service.GetCriteria(this);
  };

  @action
  public PopulateHotelsDropdown = async (cityName: string) => {
    if (stringIsNullOrEmpty(cityName)) {
      this.HotelsDropdown.replace([]);
      return;
    }
    const service = new HotelAutoCompleteService();
    const options = await service.getHotelsByCity(cityName);
    this.HotelsDropdown.replace(options);
  };
}
export default AgentStore;
