import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormProvider, useForm, UseFormReturn, ValidationMode } from 'react-hook-form';
import { Form } from 'reactstrap';

interface IFormWrapper {
  onSubmit?: (data: any) => void;
  children?: React.ReactNode;
  mode?: keyof ValidationMode;
  form?: UseFormReturn;
}

const FormWrapper = (props: IFormWrapper) => {
  const { onSubmit, children, mode } = props;
  const innerForm = useForm({ mode });
  const onSubmitCallback = React.useCallback(
    // @ts-expect-error added by automation
    (data) => {
      if (onSubmit) {
        onSubmit(data);
      }
    },
    [onSubmit]
  );

  const form = props.form ?? innerForm;

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmitCallback)}>{children}</Form>
    </FormProvider>
  );
};

export default observer(FormWrapper);
