export enum MirProcessingStatus {
  None = 0,
  Created = 1,
  PreCheckedPassed = 2,
  PreCheckFailed = 3,
  ValidationFailed = 4,
  Validated = 5,
  GroupsExported = 6,
  Exported = 7
}
