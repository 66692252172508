import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const smallDisplay = defineStyle({
  color: 'black',
  fontWeight: 'medium',
  fontSize: 'small',
  display: 'inline',
  padding: 0,
  margin: 0
});

const smallLabel = defineStyle({
  color: 'black',
  fontWeight: 'bold',
  fontSize: 'small',
  display: 'inline'
});

const singleRateDisplay = defineStyle({
  color: 'secondary.500',
  fontWeight: 'bold',
  padding: 0,
  margin: 0
});

const doubleRateDisplay = defineStyle({
  color: 'blue',
  fontWeight: 'bold',
  padding: 0,
  margin: 0
});

const campRateDisplay = defineStyle({
  color: 'primary.500',
  fontWeight: 'bold',
  padding: 0,
  margin: 0
});

const groupRateDisplay = defineStyle({
  color: 'purple.100',
  fontWeight: 'bold',
  padding: 0,
  margin: 0
});

export const textTheme = defineStyleConfig({
  variants: { smallDisplay, smallLabel, singleRateDisplay, campRateDisplay, groupRateDisplay, doubleRateDisplay }
});
