import { IObservableArray, observable, makeObservable } from 'mobx';

class AgentQueryNotesWrapper {
  constructor() {
    makeObservable(this);
    this.CodeRedNotes = observable.array(new Array<string>());
    this.AgentNotes = observable.array(new Array<string>());
  }
  @observable
  // @ts-expect-error added by automation
  public Id: number;
  @observable
  public CodeRedNotes: IObservableArray<string>;
  @observable
  public AgentNotes: IObservableArray<string>;
}
export default AgentQueryNotesWrapper;
