import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

const variants = {
  nonBlocking: definePartsStyle({
    overlay: {
      pointerEvents: 'none',
      background: 'transparent'
    },
    dialogContainer: {
      pointerEvents: 'none',
      background: 'transparent'
    },
    dialog: {
      pointerEvents: 'auto',
      maxH: '30vh',
      boxShadow: '0px -3px 9px -1px rgba(189, 189, 189, 0.75);'
    }
  })
};

export const drawerTheme = defineMultiStyleConfig({
  variants
});
