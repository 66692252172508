import { Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { PropsWithChildren } from 'react';
import formatters from 'src/common/formatters';

export function TimeOptions() {
  let now = dayjs();
  let slots: number;
  slots = ((24 - now.hour()) * 60) / 15;
  let arr = Array(slots - 1).fill(0); //subtrack one for additon bellow
  now = now.minute(Math.ceil(now.minute() / 15) * 15);
  return arr.map((_, i) => {
    let value = now.utc().format(formatters.momentDateTimeUSA);
    let display = now.local().format(`hh:mm A`);
    now = now.add(15, 'minute');
    return (
      <option value={value} key={i + 'shiftStart-workflow'}>
        {display}
      </option>
    );
  });
}

interface LinkButtonProps extends PropsWithChildren {
  onClick?: () => void;
  fontSize?: string;
}
export function LinkButton({ children, onClick, fontSize }: LinkButtonProps) {
  return (
    <Text as={'u'} fontSize={fontSize ?? 'md'} userSelect={'none'}>
      <Link color='blue' onClick={onClick}>
        {children}
      </Link>
    </Text>
  );
}
