import { API_URL } from '../../lib/config';

class Endpoint {
  public serviceName: string;
  public endpointUrl: string;

  constructor(serviceName: string, endpointUrl: string) {
    this.serviceName = serviceName;
    this.endpointUrl = API_URL + endpointUrl;
  }
}

export default Endpoint;
