import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ActivitySetAsFolioModel from '../../../../models/QuoteInquiry/ActivitySetAsFolioModel';
import InputDefinitionBuilder from '../../../../utils/inputDefinitions/InputDefinitionBuilder';
import StandardTextBoxField from '../../textbox/StandardTextBoxField';
import { IInputFieldProps } from '../common/IInputFieldProps';

interface IActivitySetFolioEmailField extends IInputFieldProps {
  model: ActivitySetAsFolioModel;
  multiline?: boolean;
}

const ActivitySetFolioEmailField: React.FC<IActivitySetFolioEmailField> = (props: IActivitySetFolioEmailField) => {
  const [inputDefinition] = React.useState(new InputDefinitionBuilder('Email').Build());

  return (
    <StandardTextBoxField
      name='Email'
      value={props.model.Email}
      onModelChange={props.model.OnModelChange}
      inputDefinition={inputDefinition}
      disabled
      multiline={props.multiline}
    />
  );
};

export default observer(ActivitySetFolioEmailField);
