import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { getAxios } from 'src/lib/axios';
import banana from '../media/errorAnimation/banana.json';
import computer from '../media/errorAnimation/computer.json';
import internet from '../media/errorAnimation/internet.json';
import reaper from '../media/errorAnimation/reaper.json';
import skull from '../media/errorAnimation/skull.json';

export const RouteError = () => {
  const images = [skull, internet, reaper, banana, computer];
  let randomIndex = Math.floor(Math.random() * images.length);
  let image = images[randomIndex];
  const auth = useAuth();
  const error = useRouteError();
  let errorMessage: string;
  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = `${JSON.stringify(error.data)} ${error.status} ${error.statusText}`;
  } else if (error instanceof Error) {
    errorMessage = `${error.message} ${error.stack} ${error.cause} ${error.name}`;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    errorMessage = 'Unknown error';
  }

  useEffect(() => {
    if (errorMessage.indexOf('Failed to fetch dynamically imported') >= 0) {
      setTimeout(() => {
        window.location.reload();
      }, 10000);
    } else {
      try {
        getAxios().post(
          `/api/errorreport?url=${window.location.toString()}&user=${auth?.user?.Email ?? 'None'}&roles=${auth?.user?.Roles.join() ?? 'None'}&errorMessage=${errorMessage}`
        );
      } catch {}
    }
  }, []);

  if (errorMessage.indexOf('Failed to fetch dynamically imported') >= 0) {
    return (
      <Flex w={'100%'} alignItems={'center'} marginTop={'16px'} flexDir={'column'}>
        <Box textAlign={'center'}>
          <Heading>New Version Available</Heading>
          <Text>
            A new version of the application has been deployed.
            <br />
            Your browser will be refreshed in 10 seconds.
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex w={'100%'} alignItems={'center'} marginTop={'16px'} flexDir={'column'}>
      <Box textAlign={'center'}>
        <Lottie
          animationData={image}
          loop={true}
          style={{
            height: '250px'
          }}
        />

        <Heading>Sorry, this page has crashed.</Heading>
        <Text>
          We have attempted to dispatch a notification, but system outages may prevent that.
          <br />
          Please try to refresh your browser and try again.
          <br />
          If this issue persists, please contact support with a full screenshot of this page.
        </Text>
      </Box>
      <Box textAlign={'left'} maxW={'fit-content'}>
        <Heading fontSize={'sm'} size='xs'>
          Technical Info:
        </Heading>
        <Text fontSize={'sm'}>
          <b>Current URL:</b> {window.location.toString()} <br />
          <b>User:</b> {auth?.user?.Email} <br />
          <b>Roles:</b> {auth?.user?.Roles.join()}
        </Text>
        <Text textAlign={'left'}>
          <pre>{errorMessage}</pre>
        </Text>
      </Box>
    </Flex>
  );
};
