import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

export class CompanyProjectState extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  // @ts-expect-error added by automation
  public CompanyProjectId: number;
  @observable
  // @ts-expect-error added by automation
  public Step: string;
  @observable
  // @ts-expect-error added by automation
  public Approval: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
}
export default CompanyProjectState;
