import { Link, Avatar, Grid, GridItem, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { WorkflowGroupActionModel, WorkflowGroupActionType, WorkflowGroupUserModel } from './Types';
import { LinkButton } from './Utils';
import { WorkflowActionButton } from './WorkflowActions';
import { WorkflowContext } from './WorkflowProvider';
import { UserStatus } from 'src/enums/common/UserStatus';
import useTimer from 'src-new/util/hooks/useTimer';
dayjs.extend(durationPlugin);

export interface WorkflowUserProps {
  user: WorkflowGroupUserModel;
  actions: WorkflowGroupActionModel[];
  inboxManagerId?: string;
  inboxManager?: string;
}

export function WorkflowUser({ user, actions, inboxManagerId }: WorkflowUserProps) {
  const [model, invoke] = useContext(WorkflowContext);
  const timer = useTimer({ increment: true, startTime: user.CurrentStatus.StatusChangedOn });
  const awayTimer = useTimer({ increment: false, startTime: user.CurrentStatus.StatusReturnTime });
  const [bgColor, setBgColor] = useState(
    `WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`
  );
  const auth = useAuth();

  const amInboxCoordinator = useMemo(() => {
    return auth.user?.UserId == inboxManagerId;
  }, [auth.user?.UserId, inboxManagerId]);

  useEffect(() => {
    if (amInboxCoordinator && timer.minutes() >= 20) {
      setBgColor(
        bgColor != `WorkflowGroupActionType.${WorkflowGroupActionType[WorkflowGroupActionType.Status]}`
          ? `WorkflowGroupActionType.${WorkflowGroupActionType[WorkflowGroupActionType.Status]}`
          : `WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`
      );
    } else {
      setBgColor(`WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`);
    }
  }, [timer, user.CurrentStatus.AssignedActionType, amInboxCoordinator]);

  return (
    <Grid
      bg={bgColor}
      templateColumns={'repeat(4,1fr)'}
      templateRows={'1fr 1fr'}
      border={'1px'}
      height={'52px'}
      borderColor={'black'}
      p={1}
      width={amInboxCoordinator ? '250px' : '130px'}>
      <GridItem>
        <Tooltip label={user.Name}>
          <Avatar
            size={'xs'}
            textTransform={'none'}
            cursor={'grab'}
            name={user.Name}
            backgroundColor={user.BubbleColor}
            getInitials={(n) =>
              n
                .split(/\s+/)
                .filter((word) => word !== '')
                .map((word, index) => (index === 0 ? word.slice(0, 2) : index === 1 ? word.charAt(0) : ''))
                .join('')
            }></Avatar>
        </Tooltip>
      </GridItem>
      <GridItem colSpan={3}>
        <HStack gap={1}>
          {user.ActionTotal.map((total, index) => {
            let action = actions[index];
            if (action.Type == WorkflowGroupActionType.Assignment || amInboxCoordinator) {
              return (
                <WorkflowActionButton
                  action={action}
                  key={`${action.ActionName}-${user.UserId}`}
                  userId={user.UserId}
                  count={total}
                  user={user}
                  userStatus={user.CurrentStatus}
                />
              );
            }
            return null;
          })}
        </HStack>
      </GridItem>
      <GridItem colSpan={3} overflow={'clip'}>
        {user.CurrentStatus.Status == UserStatus.Away ? (
          <Tooltip label={user.CurrentStatus.StatusMessage}>
            <Text mt={1} fontSize={amInboxCoordinator ? 'xs' : '0.5em'} mb={0} lineHeight={1}>
              {`Returning at ${dayjs(user.CurrentStatus.StatusReturnTime + 'Z').format('hh:mm')} (${awayTimer.format('m')}m)`}
            </Text>
          </Tooltip>
        ) : (
          <Tooltip label={''}>
            <Text fontSize={'xs'} mb={2} mt={1} width={amInboxCoordinator ? '23em' : '12em'} noOfLines={0}>
              {`${user.CurrentStatus.StatusMessage} ${amInboxCoordinator ? `(${timer.format('m')}m ${timer.format('s')}s)` : ''}`}
            </Text>
          </Tooltip>
        )}
      </GridItem>
      <GridItem ml={'auto'}>
        {auth.isInRole([ApplicationRoles.inboxCoordinator], false) && (
          <Tooltip label='Set Coordinator'>
            <Text as={'u'} fontSize={'0.6em'} userSelect={'none'}>
              <Link
                color='blue'
                onClick={() => {
                  // @ts-expect-error added by automation
                  invoke('SetInboxManager', user.UserId, user.Name, model.data.WorkflowGroupId);
                }}>
                Set
              </Link>
            </Text>
          </Tooltip>
        )}
        {amInboxCoordinator && (
          <LinkButton
            fontSize='0.6em'
            onClick={() => {
              // @ts-expect-error added by automation
              invoke('UndoLast', user, user.UserId, model.data.WorkflowGroupId);
            }}>
            Undo
          </LinkButton>
        )}
      </GridItem>
    </Grid>
  );
}
