import { useAuth } from '@cksoftware/react-base';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { InvokeRpc, ServerRpc } from './types';

interface ClientRPC {
  [key: string]: (...args: any[]) => any;
}

type HubReturn<T extends ServerRpc<T>> = [InvokeRpc<T>, HubConnection];

export default function useHookHub<T extends ServerRpc<T>>(
  url: string,
  client: ClientRPC,
  onReconnect?: () => void
): HubReturn<T> {
  // @ts-expect-error added by automation
  const [connection, setConnection] = useState<HubConnection>(null);
  const auth = useAuth();
  useEffect(() => {
    if (auth.isAuthenticated) {
      return setupConnection(url, client, setConnection, onReconnect);
    }
  }, [auth.isAuthenticated]);

  return [
    // @ts-expect-error added by automation
    connection?.state == HubConnectionState.Connected
      ? (methodName, ...args) => {
          return connection?.invoke<any>(methodName.toString(), ...args);
        }
      : undefined,
    connection
  ];
}

function setupConnection(
  url: string,
  client: ClientRPC,
  callback: (conn: HubConnection) => void,
  onReconnect?: () => void
) {
  let initConnection = new HubConnectionBuilder()
    .withUrl(url)
    .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 120000])
    .build();

  //setting up rpc called based on param
  let functions = Object.keys(client);
  for (const procedure of functions) {
    initConnection.on(procedure, (...args) => {
      client[procedure](...args);
    });
  }
  initConnection
    .start()
    .then(() => {
      callback(initConnection);
    })
    .catch((e) => {
      Sentry.captureException(e);
    });

  initConnection.onreconnected(() => {
    if (onReconnect) {
      onReconnect?.();
    }
  });

  return () => {
    initConnection.stop().catch((e) => {
      Sentry.captureException(e);
    });
  };
}
