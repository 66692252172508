import React from 'react';
import styled from '../../utils/styled/styled';

interface IStyledI {
  clickable?: boolean;
  altBaseClassName?: string;
  marginTop?: string;
  marginRight?: string;
  paddingLeft?: string;
}

export interface IFaIcon extends IStyledI {
  iconName: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const StyledI = styled.i<IStyledI>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  margin-right: ${(props) => props.marginRight};
  margin-top: ${(props) => props.marginTop};
  padding-left: ${(props) => props.paddingLeft};
`;

const FaIcon: React.FC<IFaIcon> = (props: IFaIcon) => {
  const base = props.altBaseClassName ?? 'fas';
  return <StyledI className={`${base} ${props.iconName}`} {...props}></StyledI>;
};

export default FaIcon;
