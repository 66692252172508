import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';

export const AgentQueryNavItem: NavItem = {
  text: 'Agent Query',
  allowedRoles: [ApplicationRoles.staff],
  layout: <Layout />,
  path: applicationRoutes.agent.query,
  async lazy() {
    let component = await import('src/containers/agentquery/AgentQueryContainer');
    return { Component: component.default };
  },
  subItems: [
    {
      path: `${applicationRoutes.agent.query}/:id`,
      async lazy() {
        let component = await import('src/containers/agent/ActivityInquiry/ActivityAgentQueryContainer');
        return { Component: component.default };
      }
    },
    {
      path: `${applicationRoutes.agent.activityInquiry}/:id`,
      async lazy() {
        let component = await import('src/containers/agent/ActivityInquiry/ActivityInquiryAgentQueryContainer');
        return { Component: component.default };
      }
    },
    {
      path: `${applicationRoutes.agent.obtInquiry}/:id`,
      async lazy() {
        let component = await import('src-new/features/inbox/hotelSelection/HotelQuoteSelection');
        return { Component: component.HotelQuoteSelection };
      }
    }
  ]
};
