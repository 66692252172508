import { IObservableArray, isObservableArray } from 'mobx';
import FormModel from '../../models/forms/FormModel';

export const arraySetData = (formModelArray: Array<FormModel>, TCreator: new () => FormModel, data: Array<any>) => {
  const copy = isObservableArray(data) ? data.slice() : data;
  if (isObservableArray(formModelArray)) {
    // @ts-expect-error added by automation
    const newArray = [];
    // @ts-expect-error added by automation
    copyDataIntoArray(newArray, TCreator, copy);
    const observableArray = formModelArray as IObservableArray<any>;
    // @ts-expect-error added by automation
    observableArray.replace(newArray);
  } else {
    formModelArray.length = 0;
    copyDataIntoArray(formModelArray, TCreator, copy);
  }
};

const copyDataIntoArray = (modelArray: Array<FormModel>, TCreator: new () => FormModel, dataToCopy: Array<any>) => {
  if (dataToCopy == null) {
    return new Array<FormModel>();
  }

  dataToCopy.forEach((x) => {
    const t = new TCreator();
    t.SetData(x);
    modelArray.push(t);
  });
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const arrayGroupBy = <K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
