import { observer } from 'mobx-react-lite';
import React from 'react';
import IInputField from '../common/IInputField';
import StandardTextBox from './StandardTextBox';

interface IStandardTextBoxField extends IInputField {
  value?: string | number;
  autoComplete?: string;
  multiline?: boolean;
  autoAdjustHeight?: boolean;
  borderless?: boolean;
  prefix?: string;
  onEnter?: () => void;
}

const StandardTextBoxField = (props: IStandardTextBoxField) => {
  const { id, name, type, value, inputDefinition, disabled, onModelChange, onBlur } = props;

  const onChangeCallback = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      // @ts-expect-error added by automation
      onModelChange(props.name, newValue);
    },
    [onModelChange, props.name]
  );

  const onBlurCallback = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <StandardTextBox
      id={id}
      name={name}
      overrideName={props.overrideName}
      type={type}
      value={value}
      disabled={disabled}
      autoComplete={props.autoComplete}
      inputDefinition={inputDefinition}
      onChange={onChangeCallback}
      onBlur={onBlurCallback}
      multiline={props.multiline}
      borderless={props.borderless}
      prefix={props.prefix}
      autoAdjustHeight={props.autoAdjustHeight}
      onEnter={props.onEnter}
    />
  );
};

export default observer(StandardTextBoxField);
