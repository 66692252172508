import { makeObservable, observable } from 'mobx';

import EntityFormModel from '../forms/EntityFormModel';

class FormOfPaymentModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public PaymentForm: string;

  constructor() {
    super();

    makeObservable(this);
  }
}

export default FormOfPaymentModel;
