import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

class HotelVerificationReturnModel extends FormModel {
  @observable
  public Exists: boolean;

  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;

  constructor() {
    super();
    this.Exists = false;
    makeObservable(this);
  }
}

export default HotelVerificationReturnModel;
