import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  ModalFooter,
  Select,
  Switch,
  UseDisclosureReturn
} from '@chakra-ui/react';
import { ChakraDialog, useAuth } from '@cksoftware/react-base';
import dayjs from 'dayjs';
import { useState } from 'react';
import formatters from 'src/common/formatters';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { WorkflowInitialJoinModel, WorkflowJoinStatus } from './Types';
import { TimeOptions } from './Utils';

type JoinWorkflowModal = {
  joinStatus: WorkflowJoinStatus;
  onJoin(model: WorkflowInitialJoinModel, isSpectating: boolean): void;
  doNotPrompt: boolean;
  disclosure: UseDisclosureReturn;
};

export const JoinWorkflowModal = (props: JoinWorkflowModal) => {
  const auth = useAuth();
  const [joinWorkflowGroupData, setJoinWorkflowGroupData] = useState<WorkflowInitialJoinModel>();
  const hideClose = auth.isInRole([ApplicationRoles.alwaysJoinWorkflow], false);
  const [hideOptions, setHideOptions] = useState(props.joinStatus == WorkflowJoinStatus.Spectate);

  const handleInputChange = (e: any) => {
    const { name, value, type } = e.target;
    if (type == 'select-one' && value) {
      let startDate = dayjs();
      let check = dayjs(value);
      if (name == 'EndDate') {
        if (check.isSameOrBefore(startDate)) {
          setBadEndShift(true);
        } else if (name == 'EndDate') {
          setBadEndShift(false);
        }
        // @ts-expect-error added by automation
        setJoinWorkflowGroupData({
          ...joinWorkflowGroupData,
          [name]: value,
          StartDate: startDate.utc().format(formatters.momentDateTimeUSA),
          WorkflowGroupId: 0
        });
      }
    } else {
      // @ts-expect-error added by automation
      setJoinWorkflowGroupData({ ...joinWorkflowGroupData, [name]: value, WorkflowGroupId: 0 });
    }
  };

  const [badEndShift, setBadEndShift] = useState(false);

  return (
    <>
      <ChakraDialog
        header='Join Workflow Tool'
        disableCloseOnClick={hideClose}
        hideCloseButton={hideClose}
        disclosure={props.disclosure}>
        {props.joinStatus == WorkflowJoinStatus.Spectate && (
          <FormControl display='flex' alignItems='center'>
            <FormLabel htmlFor='email-alerts' mb='0'>
              Spectate
            </FormLabel>
            <Checkbox
              isChecked={hideOptions}
              onChange={(e) => {
                setHideOptions(e.target.checked);
              }}
            />
          </FormControl>
        )}
        {(props.joinStatus == WorkflowJoinStatus.Prompt || !hideOptions) && (
          <>
            {auth.isInRole([ApplicationRoles.inboxCoordinator])}
            {
              <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor='email-alerts' mb='0'>
                  Coordinator
                </FormLabel>
                <Switch
                  name='IsInboxManager'
                  onChange={(e) => {
                    // @ts-expect-error added by automation
                    setJoinWorkflowGroupData({
                      ...joinWorkflowGroupData,
                      ['IsInboxManager']: e.target.checked
                    });
                  }}
                />
              </FormControl>
            }

            <HStack pt='5'>
              <FormControl>
                <FormLabel>Shift End</FormLabel>
                <Select
                  required
                  isInvalid={badEndShift}
                  _invalid={{
                    bg: 'red'
                  }}
                  name='EndDate'
                  placeholder='Select option'
                  onChange={handleInputChange}>
                  {TimeOptions()}
                </Select>
              </FormControl>
            </HStack>
          </>
        )}
        <ModalFooter>
          {!hideClose && (
            <Button variant={'secondary'} mr={3} onClick={props.disclosure.onClose}>
              Close
            </Button>
          )}
          <Button
            isDisabled={!joinWorkflowGroupData?.EndDate && !hideOptions}
            onClick={() => {
              props.disclosure.onClose();
              // @ts-expect-error added by automation
              props.onJoin(joinWorkflowGroupData, hideOptions);
            }}>
            Join
          </Button>
        </ModalFooter>
      </ChakraDialog>
    </>
  );
};
