import { ActivityCancellationPolicyModel } from '../../models/activities/ActivityCancellationPolicyModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class ActivityCancellationPolicyService extends ServiceBase {
  constructor() {
    super(endpoints.admin.cancellationPolicyManagement);
  }

  public getAll = async () => {
    return await this.get<Array<ActivityCancellationPolicyModel>>({ id: '' });
  };

  public add = async (model: ActivityCancellationPolicyModel) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: ActivityCancellationPolicyModel) => {
    await this.put({ id: '' }, model);
  };
  public deleteAccount = async (id: number) => {
    await this.delete({ id: `${id}` });
  };
}

export default ActivityCancellationPolicyService;
