import ActivityMassMergeNotesModel from 'src/models/activities/ActivityMassMergeNotesModel';
import { ActivityDeletedPostModel } from '../../models/activities/ActivityDeletedPostModel';
import ActivityMassUpdateModel from '../../models/activities/ActivityMassUpdateModel';
import ActivityModel from '../../models/activities/ActivityModel';
import ActivityPostModel from '../../models/activities/ActivityPostModel';
import NewActivityModel from '../../models/activities/NewActivityModel';
import FullNameIdSearchResponseModel from '../../models/common/FullNameIdSearchResponseModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class ActivityManagementService extends ServiceBase {
  constructor() {
    super(endpoints.activity.activity);
  }

  public addActivity = async (model: NewActivityModel) => {
    const data = new FormData();
    data.append('activity', JSON.stringify(model.Model));
    if (model.AttachedFile) {
      data.append('UploadFile', model.AttachedFile);
    }
    await this.post({ route: 'NewActivity' }, data);
  };

  public getActivity = async (id: number) => {
    return await this.get<ActivityModel>({ route: id });
  };

  public editActivity = async (model: ActivityModel, attachedFile?: File) => {
    const data = new FormData();
    data.append('activity', JSON.stringify(model));
    if (attachedFile) {
      data.append('UploadFile', attachedFile);
    }
    await this.put({ route: 'UpdateActivity' }, data);
  };
  public editMyActivity = async (model: ActivityModel, attachedFile?: File) => {
    const data = new FormData();
    data.append('activity', JSON.stringify(model));
    if (attachedFile) {
      data.append('UploadFile', attachedFile);
    }
    await this.put({ route: 'UpdateActivity' }, data);
  };

  public updateActivityPriorityNote = async (modelId: number, updatedString: string) => {
    await this.put({ route: 'UpdateActivity/PriorityNote' }, { modelId, updatedString });
  };

  public updateActivityAlertLevel = async (model: ActivityPostModel) => {
    await this.put({ route: 'UpdateActivity/AlertLevel' }, model);
  };

  public updateActivityStatus = async (model: ActivityPostModel) => {
    await this.put({ route: 'UpdateActivity/Status' }, model);
  };

  public updateActivityAssignedUser = async (modelId: number, updatedString: string) => {
    await this.post({ route: 'UpdateActivity/AssignedUser' }, { modelId, updatedString });
  };

  public updateActivityCompany = async (modelId: number, Id: number) => {
    await this.post({ route: 'UpdateActivity/Company' }, { modelId, Id });
  };

  public updateActivityCity = async (modelId: number, updatedString: string) => {
    await this.post({ route: 'UpdateActivity/City' }, { modelId, updatedString });
  };

  public updateActivityHotel = async (modelId: number, Id: number) => {
    await this.post({ route: 'UpdateActivity/Hotel' }, { modelId, Id });
  };

  public updateActivityDeleted = async (model: ActivityDeletedPostModel) => {
    await this.post({ route: 'UpdateActivity/Deleted' }, model);
  };

  public updateActivityTravellers = async (modelId: number, travellers: Array<FullNameIdSearchResponseModel>) => {
    await this.post({ route: 'UpdateActivity/Travellers' }, { modelId, travellers });
  };

  public updateActivityMergeNotes = async (model: ActivityMassMergeNotesModel) => {
    const response = await this.post({ route: 'UpdateActivity/MergeNotes' }, model);
    return response.data;
  };

  public massUpdateActivityType = async (model: ActivityMassUpdateModel) => {
    await this.put({ route: 'UpdateActivity/MassUpdate' }, model);
  };
}

export default ActivityManagementService;
