import { action, makeObservable, runInAction } from 'mobx';
import AgentQueryNotesWrapper from '../../models/agent/AgentQueryNotesWrapper';
import AgentQueryResponseModel from '../../models/agent/AgentQueryResponseModel';
import AgentQuerySearchCriteriaResponseModel from '../../models/agent/AgentQuerySearchCriteriaResponseModel';
import ProvincialTaxRateModel from '../../models/finance/ProvincialTaxRateModel';
import { Hotel } from '../../models/hotel/Hotel';
import AgentStore from '../../stores/agentStore';
import ServiceBase from '../Common/ServiceBase';
import endpoints from '../Common/endpoints';

class AgentHotelQueryService extends ServiceBase {
  constructor() {
    super(endpoints.agent.query);
    makeObservable(this);
  }

  @action
  public Search = async (store: AgentStore) => {
    store.QueryModel.SetTravellerIds();
    const response = await this.post<AgentQueryResponseModel>({}, store.QueryModel);
    runInAction(() => {
      store.ResponseModel.Hotels.length = 0;
      store.ResponseModel.NearbyCityHotels.length = 0;
      store.ResponseModel.ProjectHotels.length = 0;
      store.ResponseModel.CompanyProjectNotes.length = 0;
      store.ResponseModel.HotelNotes.length = 0;
      //this optional is a bandaid, not sure how its erroring out through undefined but this should prevent the fe crash
      response.data.Hotels?.forEach((x) => {
        const hotel = new Hotel();
        hotel.SetData(x);
        store.ResponseModel.Hotels.push(hotel);
      });
      response.data.NearbyCityHotels?.forEach((x) => {
        const hotel = new Hotel();
        hotel.SetData(x);
        store.ResponseModel.NearbyCityHotels.push(hotel);
      });
      response.data.ProjectHotels?.forEach((x) => {
        const hotel = new Hotel();
        hotel.SetData(x);
        store.ResponseModel.ProjectHotels.push(hotel);
      });
      store.ResponseModel.CompanyModel.SetData(response.data.CompanyModel);
      store.ResponseModel.CompanyNotes.CodeRedNotes.replace(response.data.CompanyNotes.CodeRedNotes);
      store.ResponseModel.CompanyNotes.AgentNotes.replace(response.data.CompanyNotes.AgentNotes);
      response.data.CompanyProjectNotes.forEach((x) => {
        const notesWrapper = new AgentQueryNotesWrapper();
        notesWrapper.CodeRedNotes.replace(x.CodeRedNotes);
        notesWrapper.AgentNotes.replace(x.AgentNotes);
        notesWrapper.Id = x.Id;
        store.ResponseModel.CompanyProjectNotes.push(notesWrapper);
      });
      response.data.HotelNotes.forEach((x) => {
        const notesWrapper = new AgentQueryNotesWrapper();
        notesWrapper.CodeRedNotes.replace(x.CodeRedNotes);
        notesWrapper.AgentNotes.replace(x.AgentNotes);
        notesWrapper.Id = x.Id;
        store.ResponseModel.HotelNotes.push(notesWrapper);
      });
    });
  };

  @action
  public GetCriteria = async (store: AgentStore) => {
    const response = await this.get<AgentQuerySearchCriteriaResponseModel>();
    runInAction(() => {
      store.SearchCriteriaModel.Cities.replace(response.data.Cities);
      store.SearchCriteriaModel.Companies.replace(response.data.Companies);
      store.SearchCriteriaModel.Hotels.replace(response.data.Hotels);
      store.SearchCriteriaModel.ProvincialTaxRates.length = 0;
      response.data.ProvincialTaxRates.forEach((x) => {
        const add = new ProvincialTaxRateModel();
        add.SetData(x);
        store.SearchCriteriaModel.ProvincialTaxRates.push(add);
      });
    });
  };
}

export default AgentHotelQueryService;
