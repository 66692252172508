import { observable, makeObservable } from 'mobx';

import EntityFormModel from '../forms/EntityFormModel';
import { IFileModel } from './IFileModelInterface';

class ClientEmailAttachmentModel extends EntityFormModel implements IFileModel {
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public IsFolio: boolean;

  constructor() {
    super();

    makeObservable(this);
  }
}

export default ClientEmailAttachmentModel;
