import { action, computed, makeObservable, observable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class HotelBookingQuoteInquiryTaxRateModel extends EntityFormModel {
  public constructor() {
    super();
    makeObservable(this);
  }
  private formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  @observable
  // @ts-expect-error added by automation
  public Gst: number;
  @observable
  // @ts-expect-error added by automation
  public Pst: number;
  @observable
  // @ts-expect-error added by automation
  public Hst: number;
  @observable
  // @ts-expect-error added by automation
  public TaxGstHstBaseRateValue: number;
  @observable
  // @ts-expect-error added by automation
  public TaxPstBaseRateValue: number;
  @observable
  // @ts-expect-error added by automation
  public OtherFeeTotalValue: number;
  @observable
  // @ts-expect-error added by automation
  public TotalTaxes: number;
  @observable
  // @ts-expect-error added by automation
  public OtherFeeTaxes: number;
  @observable
  // @ts-expect-error added by automation
  public Commissionable: number;
  @observable
  // @ts-expect-error added by automation
  public ServiceFee: number;
  @observable
  // @ts-expect-error added by automation
  public ServiceFeeTax: number;
  @observable
  // @ts-expect-error added by automation
  public Commision: number;
  @observable
  // @ts-expect-error added by automation
  public CommisionTax: number;
  @observable
  // @ts-expect-error added by automation
  public CampMarkUp: number;
  @observable
  // @ts-expect-error added by automation
  public CampMarkUpTax: number;
  @observable
  // @ts-expect-error added by automation
  public CommisionPercent: number;
  @observable
  // @ts-expect-error added by automation
  public TotalGst: number;
  @observable
  // @ts-expect-error added by automation
  public TotalPst: number;
  @observable
  // @ts-expect-error added by automation
  public TotalLevy: number;
  @observable
  // @ts-expect-error added by automation
  public TotalLevyTaxes: number;

  @computed
  public get TaxRateString() {
    if (this.Hst > 0) {
      return `HST: ${this.Hst}%`;
    }

    if (this.Pst > 0) {
      return `GST: ${this.Gst}%, PST: ${this.Pst}%`;
    }

    return `GST: ${this.Gst}%`;
  }
  @computed
  public get TaxGstHstBaseRateString() {
    return this.formatter.format(this.TaxGstHstBaseRateValue);
  }

  @computed
  public get TaxPstBaseRateString() {
    return this.formatter.format(this.TaxPstBaseRateValue);
  }
  @computed
  public get ServiceFeeTaxString() {
    return this.formatter.format(this.ServiceFeeTax);
  }
  @computed
  public get OtherFeeString() {
    return this.formatter.format(this.OtherFeeTotalValue + this.OtherFeeTaxes);
  }
  @action
  public checkNumberIfNullOrUndefined(name: string, value: number) {
    if (value === null || value === undefined) {
      // @ts-expect-error added by automation
      this[name] = 0;
    } else {
      // @ts-expect-error added by automation
      this[name] = value;
    }
  }
  @action
  public CalculateServiceFeeTax() {
    this.ServiceFeeTax = this.ServiceFee * 0.05;
  }
}

export default HotelBookingQuoteInquiryTaxRateModel;
