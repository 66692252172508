import * as signalR from '@microsoft/signalr';
import * as Sentry from '@sentry/react';
import Endpoint from '../services/Common/Endpoint';
import { getFormattedUrl } from '../utils/helpers/stringHelpers';

export const setHubError = (handler: (error: any, connectionStatus: signalR.HubConnectionState) => void) => {
  onHubError = handler;
};

let onHubError: (error: any, connectionStatus: signalR.HubConnectionState) => void;

abstract class HubBase {
  // @ts-expect-error added by automation
  public connection: signalR.HubConnection;
  protected transformedUrl: string;

  protected endpoint: Endpoint;

  constructor(endpoint: Endpoint, params?: object) {
    this.endpoint = endpoint;
    this.transformedUrl = `${getFormattedUrl(this.endpoint, params)}`;
    this.initializeConnection();
  }

  private initializeConnection = () => {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.transformedUrl)
      .withAutomaticReconnect([0, 2000, 10000, 30000, 60000])
      .build();
  };

  protected startConnection = async () => {
    await this.connection.start();
  };

  protected errorHandler = (err: any) => {
    Sentry.captureException(err);
    if (onHubError) {
      onHubError(err, this.connection.state);
    }
  };

  public stopConnection = async () => {
    await this.connection.stop();
  };
}

export default HubBase;
