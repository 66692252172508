import { createContext, PropsWithChildren, useState } from 'react';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import useHookHub from 'src-new/util/hubs/useHookHub';

type ActivityOpenNotificationContextType = {
  onOpenActivity: (activityId: number) => void;
  onCloseActivity: (activityId: number) => void;
  openBy: string | undefined;
};

type ActivityOpenNotificationServerRpc = {
  OnCloseActivity: (activityId: number) => void;
  OnOpenActivity: (activityId: number) => string | undefined;
};

export const ActivityOpenNotificationContext = createContext<ActivityOpenNotificationContextType | null>(null);
export default function ActivityOpenNotificationProvider(props: PropsWithChildren) {
  const [openBy, setOpenBy] = useState<string | undefined>(undefined);

  const [invoke] = useHookHub<ActivityOpenNotificationServerRpc>(ApiEndpoints.hubs.activityNotificationHub, {});

  const onOpenActivity = (activityId: number) => {
    if (invoke) {
      invoke('OnOpenActivity', activityId).then((val) => {
        setOpenBy(val);
      });
    }
  };

  const onCloseActivity = async (activityId: number) => {
    if (invoke) {
      await invoke('OnCloseActivity', activityId);
    }
  };
  return (
    <ActivityOpenNotificationContext.Provider value={{ onOpenActivity, onCloseActivity, openBy }}>
      {props.children}
    </ActivityOpenNotificationContext.Provider>
  );
}
