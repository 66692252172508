import { CreditCardTypeModel } from 'src/models/admin/activity/CreditCardTypeModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';
import Result from 'src/models/common/Result';

class AdminCreditCardTypeService extends ServiceBase {
  constructor() {
    super(endpoints.admin.creditCardTypeManagement);
  }

  public getAll = async () => {
    return await this.get<Result<Array<CreditCardTypeModel>>>({ route: 'GetAll' });
  };

  public add = async (model: CreditCardTypeModel) => {
    await this.post({ route: 'Create' }, model);
  };

  public edit = async (model: CreditCardTypeModel) => {
    await this.put({ route: 'Update' }, model);
  };
  public deleteAccount = async (model: CreditCardTypeModel) => {
    await this.post({ route: `ToggleActive` }, model );
  };
}

export default AdminCreditCardTypeService;
