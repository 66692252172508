import { useQuery } from '@tanstack/react-query';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { DropdownItemType } from 'src-new/globalModels/dropdownItem';
import { getAxios } from 'src/lib/axios';
import DropdownItem from 'src/models/forms/DropdownItem';

async function getUsersInRoles(roles: string[], includeActivityAssign: boolean): Promise<DropdownItemType[]> {
  return (
    await getAxios().get<DropdownItemType[]>(ApiEndpoints.selectLists.usersInRoles, {
      params: { roles: roles, includeActivityAssign: includeActivityAssign },
      paramsSerializer: {
        indexes: null
      }
    })
  ).data;
}

export function useUsersInRolesDropdownQuery(roles: string[], includeActivityAssign: boolean) {
  return useQuery({
    queryKey: ['usersInRoles', roles],
    queryFn: () => getUsersInRoles(roles, includeActivityAssign),
    select: (data) => data.map((item) => new DropdownItem(item.Text, item.Value))
  });
}
