import { IColumn } from '@fluentui/react';

export const BranchOfficeAgentColumns: IColumn[] = [
  { key: 'CompanyName', name: 'Company Name', fieldName: 'CompanyName', minWidth: 150, isResizable: true },
  { key: 'Name', name: 'Name', fieldName: 'Name', minWidth: 150, isResizable: true },
  { key: 'PhoneNumber', name: 'Phone Number', fieldName: 'PhoneNumber', minWidth: 100, isResizable: true },
  { key: 'FaxNumber', name: 'Fax Number', fieldName: 'FaxNumber', minWidth: 100, isResizable: true },
  { key: 'Description', name: 'Description', fieldName: 'Description', minWidth: 400, isResizable: true },
  {
    key: 'AddressString',
    name: 'Address',
    fieldName: 'AddressString',
    minWidth: 300,
    isResizable: true
  }
];

export const BranchOfficecolumns: IColumn[] = [
  { key: 'CompanyName', name: 'Company Name', fieldName: 'CompanyName', minWidth: 150, isResizable: true },
  { key: 'Name', name: 'Name', fieldName: 'Name', minWidth: 150, isResizable: true },
  { key: 'PhoneNumber', name: 'Phone Number', fieldName: 'PhoneNumber', minWidth: 100, isResizable: true },
  { key: 'FaxNumber', name: 'Fax Number', fieldName: 'FaxNumber', minWidth: 100, isResizable: true },
  { key: 'Description', name: 'Description', fieldName: 'Description', minWidth: 400, isResizable: true },
  {
    key: 'AddressString',
    name: 'Address',
    fieldName: 'AddressString',
    minWidth: 300,
    isResizable: true
  },
  {
    key: 'Actions',
    name: 'Actions',
    fieldName: 'Actions',
    minWidth: 75,
    isResizable: true
  }
];

export const companyColumns = [
  { key: 'Name', name: 'Name', fieldName: 'Name', minWidth: 200, isResizable: true },
  { key: 'City', name: 'City', fieldName: 'City', minWidth: 200, isResizable: true },
  {
    key: 'PhoneNumber',
    name: 'PhoneNumber',
    fieldName: 'PhoneNumber',
    minWidth: 200,
    isResizable: true
  },
  {
    key: 'FaxNumber',
    name: 'FaxNumber',
    fieldName: 'FaxNumber',
    minWidth: 200,
    isResizable: true
  },
  {
    key: 'Actions',
    name: 'Actions',
    fieldName: 'Actions',
    minWidth: 75,
    isResizable: true
  }
];

export const companyAgentColumns = [
  { key: 'Name', name: 'Name', fieldName: 'Name', minWidth: 200, isResizable: true },
  { key: 'City', name: 'City', fieldName: 'City', minWidth: 200, isResizable: true },
  {
    key: 'PhoneNumber',
    name: 'PhoneNumber',
    fieldName: 'PhoneNumber',
    minWidth: 200,
    isResizable: true
  },
  {
    key: 'FaxNumber',
    name: 'FaxNumber',
    fieldName: 'FaxNumber',
    minWidth: 200,
    isResizable: true
  }
];
