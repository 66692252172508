import AdditionalChargeModel from '../../models/companies/AdditionalChargeModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class AdditionalChargeService extends ServiceBase {
  constructor() {
    super(endpoints.admin.additionalChargeManagement);
  }

  public getAll = async () => {
    return await this.get<Array<AdditionalChargeModel>>({ id: '' });
  };

  public add = async (model: AdditionalChargeModel) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: AdditionalChargeModel) => {
    await this.put({ id: '' }, model);
  };
}

export default AdditionalChargeService;
