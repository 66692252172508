import * as React from 'react';
import AgentStore from './agentStore';
import ApplicationManagementStore from './applicationManagementStore';
import CrmStore from './crmStore';
import SystemRefreshStore from './systemRefreshStore';
import UserStore from './userStore';
import UtilityStore from './utilityStore';

const createStores = () => {
  let utilStore = new UtilityStore();
  return {
    userStore: new UserStore(),
    crmStore: new CrmStore(utilStore),
    agentStore: new AgentStore(),
    applicationManagementStore: new ApplicationManagementStore(),
    utilityStore: utilStore,
    systemRefreshStore: new SystemRefreshStore()
  };
};

const StoresContext = React.createContext(createStores());

const useStores = () => React.useContext(StoresContext);

export const useUserStore = () => {
  return useStores().userStore;
};

export const useCrmStore = () => {
  return useStores().crmStore;
};

export const useAgentStore = () => {
  return useStores().agentStore;
};

export const useUtilityStore = () => {
  return useStores().utilityStore;
};

export const useApplicationManagementStore = () => {
  return useStores().applicationManagementStore;
};
