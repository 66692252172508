import { observable, makeObservable } from 'mobx';
import RateType from '../../enums/crm/RateType';
import { IHotelRate } from '../common/Interfaces/IHotelRateType';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';

export class HotelRate extends TimeSeriesEntityFormModel implements IHotelRate {
  public constructor(defaultDatesToStartAndEndOfYear: boolean = false) {
    super(defaultDatesToStartAndEndOfYear);
    makeObservable(this);
    this.SingleRate = 0;
    this.DoubleRate = 0;
    this.RateType = RateType.Normal;
  }

  @observable
  // @ts-expect-error added by automation
  public Description: string;

  @observable
  public RateType: RateType;

  @observable
  public SingleRate: number;

  @observable
  public DoubleRate?: number;

  @observable
  public CampFee?: number;

  @observable
  // @ts-expect-error added by automation
  public CampFeeNote: string;

  @observable
  public CampMarkup?: number;

  @observable
  public CampNetRate?: number;
}
