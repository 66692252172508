import { MenuItemProps, MenuList } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { NavMenuSubItem } from './NavMenuSubItem';
import { NavItem } from './types/NavItem';

type NavMenuSubItemListProps = {
  subItems: NavItem[];
  children?: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
};

export const NavMenuSubItemList = ({ subItems, children }: NavMenuSubItemListProps) => {
  return (
    <MenuList>
      {subItems
        .filter((value) => value.text != undefined)
        .map((item, key) => {
          item.isActive = window.location.href.indexOf(item.path!) > -1;
          return <NavMenuSubItem key={key} item={item} />;
        })}
      {children}
    </MenuList>
  );
};
