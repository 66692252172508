import { observer } from 'mobx-react-lite';
import React from 'react';
import ConfirmationCallout from '../../../../components/callout/ConfirmationCallout';
import StandardTextBoxField from '../../../../components/forms/textbox/StandardTextBoxField';
import InputDefinitionBuilder from '../../../../utils/inputDefinitions/InputDefinitionBuilder';

import ActivityMergePostModel from '../../../../models/activities/ActivityPostModel';
import ActivityStatus from '../../../../enums/activities/ActivityStatus';
import { PaddedBox } from '../../EditActivity/styledComponents';

interface IInboxContextMenuSetCancelledCallout {
  model: ActivityMergePostModel;
  onSave: (level: ActivityStatus) => void;
}

const InboxContextMenuSetCancelledCallout: React.FC<IInboxContextMenuSetCancelledCallout> = (
  props: IInboxContextMenuSetCancelledCallout
) => {
  const [inputDefinition] = React.useState(new InputDefinitionBuilder('Reason For Cancellation').Build());
  const onSubmit = () => {
    props.onSave(ActivityStatus.Cancelled);
  };

  return (
    <ConfirmationCallout okCallback={onSubmit} header={"Add to Folio's Rules List"}>
      <PaddedBox>
        <StandardTextBoxField
          inputDefinition={inputDefinition}
          name={'CancelledReason'}
          value={props.model.CancelledReason}
          onModelChange={props.model.OnModelChange}
        />
      </PaddedBox>
    </ConfirmationCallout>
  );
};

export default observer(InboxContextMenuSetCancelledCallout);
