import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import UserModel from '../models/account/UserModel';
import UserQueryModel from '../models/account/UserQueryModel';
import { ActivityCancellationPolicyModel } from '../models/activities/ActivityCancellationPolicyModel';
import Announcement from '../models/common/AnnouncementModel';
import ShowActiveModel from '../models/common/ShowActiveModel';
import AdditionalChargeModel from '../models/companies/AdditionalChargeModel';

import { CreditCardTypeModel } from 'src/models/admin/activity/CreditCardTypeModel';
import AdminCreditCardTypeService from 'src/services/Admin/AdminCreditCardTypeService';
import ReportingRequirement from '../models/companies/ReportingRequirement';
import { LoyaltyAccount } from '../models/contacts/LoyaltyAccount';
import { EmailSignatureModel } from '../models/email/EmailSignatureModel';
import DailyHotelRateModel from '../models/finance/DailyHotelRateModel';
import { Amenity } from '../models/hotel/Amenity';
import ActivityCancellationPolicyService from '../services/Admin/ActivityCancellationPolicyService';
import ActivityRoomTypeService from '../services/Admin/ActivityRoomTypeServices';
import AdditionalChargeService from '../services/Admin/AdditionalChargeService';
import AmenityService from '../services/Admin/AmenityService';
import AnnouncementService from '../services/Admin/AnnouncementService';
import EmailSignatureService from '../services/Admin/EmailSignatureService';
import ItinerarySignatureService from '../services/Admin/ItinerarySignatureService';
import LoyaltyAccountService from '../services/Admin/LoyaltyAccountService';
import ReportingRequirementService from '../services/Admin/ReportingRequirementService';
import DailyHotelRateService from '../services/Finance/DailyHotelRateService';
import AdminUserRolesService from '../services/UserManagement/AdminUserRolesService';
import { ActivityRoomType } from './../models/activities/ActivityRoomType';
import ItineraryHeaderService from 'src/services/Admin/ItineraryHeaderService';
import { ItineraryHeaderModel } from 'src/models/activities/ItineraryHeaderModel';

class ApplicationManagementStore {
  constructor() {
    makeObservable(this);
    this.Users = new Array<UserModel>();
    this.Roles = new Array<string>();
    this.ReportingRequirement = new Array<ReportingRequirement>();
    this.AdditionalCharges = new Array<AdditionalChargeModel>();
    this.LoyaltyAccounts = new Array<LoyaltyAccount>();
    this.RoomTypes = new Array<ActivityRoomType>();
    this.DailyHotelRates = new Array<DailyHotelRateModel>();
    this.EmailSignatures = new Array<EmailSignatureModel>();
    this.Amenities = new Array<Amenity>();
    this.AddNewUserModel = new UserModel();
    this.UserQueryModel = new UserQueryModel();
    this.Announcement = new Announcement();
    this.showAnnouncement = new ShowActiveModel();
    this.ItinerarySignatures = new EmailSignatureModel();
    this.CancellationPolicies = new Array<ActivityCancellationPolicyModel>();
    this.ItineraryHeaders = new Array<ItineraryHeaderModel>();
    this.CreditCardTypes = new Array<CreditCardTypeModel>();
  }

  @observable
  public Users: Array<UserModel>;
  @observable
  public showAnnouncement: ShowActiveModel;
  @observable
  public ReportingRequirement: Array<ReportingRequirement>;
  @observable
  public AdditionalCharges: Array<AdditionalChargeModel>;

  @observable
  public CreditCardTypes: Array<CreditCardTypeModel>;

  @observable
  public Announcement: Announcement;
  @observable
  public LoyaltyAccounts: Array<LoyaltyAccount>;
  @observable
  public RoomTypes: Array<ActivityRoomType>;
  @observable
  public CancellationPolicies: Array<ActivityCancellationPolicyModel>;
  @observable
  public ItineraryHeaders: Array<ItineraryHeaderModel>;
  @observable
  public DailyHotelRates: Array<DailyHotelRateModel>;
  @observable
  public EmailSignatures: Array<EmailSignatureModel>;
  @observable
  public ItinerarySignatures: EmailSignatureModel;
  @observable
  public Amenities: Array<Amenity>;

  @observable
  public Roles: Array<string>;

  public AddNewUserModel: UserModel;
  public UserQueryModel: UserQueryModel;

  @action
  public LoadRoles = async () => {
    const roleService = new AdminUserRolesService();
    await roleService.GetAllSystemRoles(this);
  };

  @action
  public LoadReportingRequirements = async () => {
    const service = new ReportingRequirementService();
    const response = await service.getAll();
    runInAction(() => {
      this.ReportingRequirement.length = 0;
      response.data.forEach((x) => {
        const field = new ReportingRequirement();
        field.SetData(x);
        this.ReportingRequirement.push(field);
      });
    });
  };
  @action
  public LoadAdditionalCharges = async () => {
    const service = new AdditionalChargeService();
    const response = await service.getAll();
    runInAction(() => {
      this.AdditionalCharges.length = 0;
      response.data.forEach((x) => {
        const field = new AdditionalChargeModel();
        field.SetData(x);
        this.AdditionalCharges.push(field);
      });
    });
  };

  @action
  public LoadAnnouncement = async () => {
    const service = new AnnouncementService();
    const response = await service.getRecent();
    runInAction(() => {
      this.Announcement.SetData(response.data);
    });
  };

  @action
  public LoadLoyaltyAccounts = async () => {
    const service = new LoyaltyAccountService();
    const response = await service.getAll();
    runInAction(() => {
      this.LoyaltyAccounts.length = 0;
      response.data.forEach((x) => {
        const field = new LoyaltyAccount();
        field.SetData(x);
        this.LoyaltyAccounts.push(field);
      });
    });
  };

  @action
  public LoadActivityRoomTypes = async () => {
    const service = new ActivityRoomTypeService();
    const response = await service.getAll();
    runInAction(() => {
      this.RoomTypes.length = 0;
      response.data.forEach((x) => {
        const field = new ActivityRoomType();
        field.SetData(x);
        this.RoomTypes.push(field);
      });
    });
  };

  @action
  public LoadCancellationPolicies = async () => {
    const service = new ActivityCancellationPolicyService();
    const response = await service.getAll();
    runInAction(() => {
      this.CancellationPolicies.length = 0;
      response.data.forEach((x) => {
        const field = new ActivityCancellationPolicyModel();
        field.SetData(x);
        this.CancellationPolicies.push(field);
      });
    });
  };

  @action
  public LoadItineraryHeaders = async () => {
    const service = new ItineraryHeaderService();
    const response = await service.getAll();
    runInAction(() => {
      this.ItineraryHeaders.length = 0;
      response.data.forEach((x) => {
        const field = new ItineraryHeaderModel();
        field.SetData(x);
        this.ItineraryHeaders.push(field);
      });
    });
  };

  @action
  public LoadCreditCard = async () => {
    const service = new AdminCreditCardTypeService();
    const response = await service.getAll();
    runInAction(() => {
      this.CreditCardTypes.length = 0;
      response.data.Value.forEach((x) => {
        const field = new CreditCardTypeModel();
        field.SetData(x);
        this.CreditCardTypes.push(field);
      });
    });
  };

  @action
  public LoadDailyHotelRates = async () => {
    const service = new DailyHotelRateService();
    const response = await service.getAll();
    runInAction(() => {
      this.DailyHotelRates.length = 0;
      response.data.forEach((x) => {
        const field = new DailyHotelRateModel();
        field.SetData(x);
        this.DailyHotelRates.push(field);
      });
    });
  };

  @action
  public LoadEmailSignatures = async () => {
    const service = new EmailSignatureService();
    const response = await service.getAll();
    runInAction(() => {
      this.EmailSignatures.length = 0;
      response.data.forEach((x) => {
        const field = new EmailSignatureModel();
        field.SetData(x);
        this.EmailSignatures.push(field);
      });
    });
  };
  @action
  public LoadItinerarySignature = async () => {
    const service = new ItinerarySignatureService();
    const response = await service.getAll();
    runInAction(() => {
      this.ItinerarySignatures.SetData(response.data);
    });
  };

  @action
  public LoadAmenities = async () => {
    const service = new AmenityService();
    const response = await service.getAll();
    runInAction(() => {
      this.Amenities.length = 0;
      response.data.forEach((x) => {
        const am = new Amenity();
        am.SetData(x);
        this.Amenities.push(am);
      });
    });
  };

  @computed
  public get UsersFiltered() {
    return this.Users.filter((x) => this.UserQueryModel.Active === x.Active);
  }
}
export default ApplicationManagementStore;
