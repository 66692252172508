import ActivityStatus from '../../enums/activities/ActivityStatus';
import { IEmailActivityOverviewModel } from '../activities/IEmailActivityOverviewModel';

export class EmailOverviewTabModel {
  public constructor(id: number, title: string, status: ActivityStatus, data?: IEmailActivityOverviewModel) {
    this.ActivityId = id;
    const length = 30;
    if (title?.length > length) {
      this.Title = title.slice(0, length) + '...';
    } else {
      this.Title = title;
    }
    this.Status = status;
    this.EmailData = data;
  }

  public EmailData?: IEmailActivityOverviewModel;
  public ActivityId: number;
  public Title: string;
  public Status: ActivityStatus;
}
