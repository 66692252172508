import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class QuoteInquiryCreditCardTypeModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public CreditCardTypeId: number;
  @observable
  // @ts-expect-error added by automation
  public CardValue: string;
  @observable
  // @ts-expect-error added by automation
  public CurrencyCode: number;
}
export default QuoteInquiryCreditCardTypeModel;
