import dayjs from 'dayjs';
import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import formatters from '../../common/formatters';
import ActivityAlertLevel from '../../enums/activities/ActivityAlertLevel';
import ActivityStatus from '../../enums/activities/ActivityStatus';
import ActivityType from '../../enums/activities/ActivityType';
import { getMyActivityTitle } from '../../utils/activities/activityModelHelpers';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { SetDatePropertyName, SetEndDate, SetStartDate, sortModelsByDate } from '../../utils/helpers/dateHelpers';
import { isNullOrUndefined } from '../../utils/helpers/typeGuards';
import EntityFormModel from '../forms/EntityFormModel';
import ActivityNoteModel from './ActivityNoteModel';

class MyActivityModel extends EntityFormModel {
  public constructor() {
    super();
    makeObservable(this);
    this.Notes = observable.array(new Array<ActivityNoteModel>());
    this.TravellerNames = new Array<string>();
    this.NewReply = false;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['Notes']);
    arraySetData(this.Notes, ActivityNoteModel, dbData.Notes);
    SetStartDate(dbData, this);
    SetEndDate(dbData, this);
    SetDatePropertyName(dbData, this, 'DueDate');
  }

  @observable
  // @ts-expect-error added by automation
  public ActivityType: ActivityType;
  @observable
  // @ts-expect-error added by automation
  public AssignedToUserId: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
  @observable
  // @ts-expect-error added by automation
  public CompanyName: string;
  @observable
  public NewReply: boolean;
  @observable
  // @ts-expect-error added by automation
  public HotelId: number;
  @observable
  // @ts-expect-error added by automation
  public HotelName: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  // @ts-expect-error added by automation
  public DueDate: string;
  @observable
  // @ts-expect-error added by automation
  public DueDateComment: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public AssignedUserName: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public Status: ActivityStatus;
  @observable
  // @ts-expect-error added by automation
  public AlertLevel: ActivityAlertLevel;
  @observable
  public Notes: IObservableArray<ActivityNoteModel>;
  @observable
  // @ts-expect-error added by automation
  public StartDate: string;
  @observable
  // @ts-expect-error added by automation
  public EndDate: string;
  @observable
  // @ts-expect-error added by automation
  public PriorityNote: string;
  @observable
  // @ts-expect-error added by automation
  public HotelBookingQuoteInquiryId: number;
  @observable
  // @ts-expect-error added by automation
  public OriginalFlsq: number;
  @observable
  // @ts-expect-error added by automation
  public ItinerarySelectedRateId: number;
  @observable
  public TravellerNames: Array<string>;
  @observable
  // @ts-expect-error added by automation
  public Subject: string;
  @observable
  // @ts-expect-error added by automation
  public CityName: string;

  @computed
  public get DueDateMoment() {
    return this.DueDate ? dayjs.utc(this.DueDate).local() : null;
  }

  @computed
  public get CreatedOnMoment() {
    return this.CreatedOn ? dayjs.utc(this.CreatedOn).local() : null;
  }

  @computed
  public get CreatedInformation(): string {
    return `${this.CreatedBy} - ${this.CreatedOnFormatted}`;
  }

  @computed
  public get CreatedOnFormatted(): string {
    return `${dayjs.utc(this.CreatedOn).local().format(formatters.momentDateTimeUSA)}`;
  }

  @computed
  public get LatestEmailDate() {
    const emailNotes = this.Notes.filter((x) => !isNullOrUndefined(x.ClientEmailId) && x.ClientEmailId !== 0);
    if (emailNotes.length === 0) {
      return null;
    }
    const latest = emailNotes.slice().sort((a, b) => sortModelsByDate(a, b, 'CreatedOnMoment', true))[0];
    return latest.CreatedOnMoment;
  }

  @computed
  public get ActivityTitle() {
    return getMyActivityTitle(this);
  }
}

export default MyActivityModel;
