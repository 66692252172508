import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import { IFileModel } from '../activities/IFileModelInterface';
import ICityModel from '../common/Interfaces/ICityModel';
import IHotelIdModel from '../common/Interfaces/IHotelIdModel';
import EntityFormModel from '../forms/EntityFormModel';
import { HotelRoomType } from '../hotel/HotelRoomType';

export class PreferredHotelModel extends EntityFormModel implements ICityModel, IHotelIdModel, IFileModel {
  constructor() {
    super();
    makeObservable(this);
    this.HotelName = 'Save';
    this.PreferredRoomTypes = observable.array(new Array<HotelRoomType>());
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['RoomTypes']);
    if (dbData.PreferredRoomTypes) {
      arraySetData(this.PreferredRoomTypes, HotelRoomType, dbData.PreferredRoomTypes);
    }
  }

  @observable
  // @ts-expect-error added by automation
  public HotelId: number;
  @observable
  public HotelName: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;

  @observable
  // @ts-expect-error added by automation
  public Province: string;

  @observable
  // @ts-expect-error added by automation
  public PreferredRateUrl: string;
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public PreferredHotelNote: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
  @observable
  public PreferredRoomTypes: IObservableArray<HotelRoomType>;

  @computed
  public get ActiveRates() {
    return this.PreferredRoomTypes.filter((value) => {
      return value.Active;
    })
      .flatMap((x) => x.Rates)
      .filter((x) => x.IsCurrent);
  }

  @computed
  public get HasPreferredRate() {
    if (
      stringIsNullOrEmpty(this.PreferredHotelNote) &&
      this.PreferredRoomTypes == null &&
      this.FileName == null &&
      this.PreferredRateUrl == null
    ) {
      return false;
    }
    return true;
  }
}
