import ReportingRequirement from '../../models/companies/ReportingRequirement';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class ReportingRequirementService extends ServiceBase {
  constructor() {
    super(endpoints.admin.reportingRequirementManagement);
  }

  public getAll = async () => {
    return await this.get<Array<ReportingRequirement>>({ id: '' });
  };

  public add = async (model: ReportingRequirement) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: ReportingRequirement) => {
    await this.put({ id: '' }, model);
  };
}

export default ReportingRequirementService;
