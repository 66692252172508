import { action, computed, makeObservable, observable } from 'mobx';
import { FrontierUser } from 'src-new/globalModels/frontierUser';
import { ApplicationRoles } from '../../constants/applicationRoles';
import { IEmailModel } from '../common/Interfaces/IEmailModel';
import { INameModel } from '../common/Interfaces/INameModel';
import { IPhoneNumberModel } from '../common/Interfaces/IPhoneNumberModel';
import FormModel from '../forms/FormModel';

class UserModel extends FormModel implements INameModel, IPhoneNumberModel, IEmailModel {
  @observable
  public Email: string;

  @observable
  public PhoneNumber: string;

  @observable
  public Name: string;

  @observable
  public NickName: string;

  @observable
  public GdsId: string;

  @observable
  public TwoFactorEnabled: boolean;

  @observable
  public UserId: string;

  @observable
  public Roles: Array<string>;

  @observable
  public Active: boolean;

  @observable
  public EnforceTwoFactor: boolean;

  @observable
  public IsGroupDepartment: boolean;

  @observable
  public EnableWorkflowSystem: boolean;

  @computed
  public get Initials() {
    const nameParts = this.Name.split(' ');
    const formattedFirstName = nameParts[0].slice(0, 2);
    const formattedLastName = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0) : '';
    return `${formattedFirstName}${formattedLastName}`;
  }
  constructor() {
    super();
    makeObservable(this);
    this.Roles = new Array<string>();
    this.Active = true;
    this.Email = '';
    this.PhoneNumber = '';
    this.Name = '';
    this.NickName = '';
    this.UserId = '';
    this.GdsId = '';
    this.EnableWorkflowSystem = false;
    this.TwoFactorEnabled = false;
    this.EnforceTwoFactor = false;
    this.IsGroupDepartment = false;
  }

  public IsInRole = (roles: Array<string>) => {
    if (!this.Roles) {
      return false;
    }

    if (this.Roles.map((x) => x.toLowerCase()).includes(ApplicationRoles.systemAdmin.toLowerCase())) {
      //Admin can always do everything
      return true;
    }
    return this.Roles.map((x) => x.toLowerCase()).some((x) => roles.map((x) => x.toLowerCase()).includes(x));
  };

  @action
  public SetFromNewUserModel = (model: FrontierUser) => {
    this.Active = !model.IsDisabled;
    this.Email = model.Email!;
    this.Name = model.Name!;
    this.NickName = model.NickName!;
    this.PhoneNumber = model.PhoneNumber!;
    this.UserId = model.UserId!;
    this.Roles = model.Roles!;
    this.IsGroupDepartment = model.IsGroupDepartment!;
    this.GdsId = model.GdsId!;
    this.EnableWorkflowSystem = model.EnableWorkflowSystem!;
  };

  @action
  public Reset = () => {
    this.Email = '';
    this.PhoneNumber = '';
    this.Name = '';
    this.NickName = '';
    this.UserId = '';
    this.Roles.length = 0;
    this.Active = false;
  };
}

export default UserModel;
