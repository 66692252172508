import { ChakraBaseProvider } from '@chakra-ui/react';
import '@fontsource-variable/montserrat';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { chakraTheme } from 'src-new/theme/chakraTheme';
import App from './app/App';
import { AllAccessRoles } from './constants/applicationRoles';
import { getAxios } from './lib/axios';
import AppSettingsService from './services/AppSettings/AppSettingsService';

AppSettingsService.getAppSettings().then((x) => {
  Sentry.init({
    dsn: x.FrontEndDsn,
    integrations: [],
    tracesSampleRate: x.SampleRate,
    replaysSessionSampleRate: x.SampleRate,
    replaysOnErrorSampleRate: x.SampleRate,
    debug: false
  });

  (window as any).getAxios = getAxios;
  (window as any).superAdminRoles = AllAccessRoles;

  const container = document.getElementById('root')!;
  const root = createRoot(container);
  root.render(
    <Sentry.ErrorBoundary>
      <ChakraBaseProvider theme={chakraTheme} resetCSS={true}>
        <App />
      </ChakraBaseProvider>
    </Sentry.ErrorBoundary>
  );
});
