import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class ActivityPostModel extends EntityFormModel {
  public constructor(modelId?: number, Id?: number) {
    super();
    makeObservable(this);
    // @ts-expect-error added by automation
    this.ModelId = modelId;
    // @ts-expect-error added by automation
    this.Id = Id;
  }
  @observable
  public ModelId: number;
  @observable
  // @ts-expect-error added by automation
  public CancelledReason: string;
  @observable
  // @ts-expect-error added by automation
  public IsFolio: boolean;
}

export default ActivityPostModel;
