import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';

export const FolioNavItems: NavItem = {
  text: 'Folio',
  allowedRoles: [ApplicationRoles.folio, ApplicationRoles.reservations],
  layout: <Layout />,
  path: applicationRoutes.agent.activityFolio,
  async lazy() {
    let component = await import('src-new/features/folio/folio');
    return { Component: component.default };
  }
};
