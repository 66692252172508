export enum ActivitySort {
  DueDate,
  Priority,
  CreatedOn,
  ClientName,
  AssignedUser,
  Subject,
  From,
  Comments,
  EmailRequest,
  HotelName,
  CompanyName,
  TravellerNames,
  StartDate,
  EndDate,
  CompanyNameFLSQ
}
