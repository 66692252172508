import { IColumn } from '@fluentui/react';
import dayjs from 'dayjs';
import MyActivityModel from 'src/models/activities/MyActivityModel';
import formatters, {
  formatDateMonthDayYearTimeUSA,
  formatDayMonthYear,
  formatMonthNameDayShortYear
} from '../../common/formatters';
import { Flex } from '../../components/base/flex';
import FaIconWithToolTip from '../../components/common/FaIconWithToolTip';
import TruncateStringWithToolTip from '../../components/common/TruncateStringWithToolTip';
import ActivityStatusLabel from '../../constants/ActivityStatusLabels';
import ActivityEmailSetSpam from '../../containers/agent/Inbox/Components/ActivityEmailSetSpam';
import InboxHubContextMenu from '../../containers/agent/Inbox/ContextMenu/InboxHubContextMenu';
import OverviewTableSubjectDisplay from '../../containers/agent/Inbox/OverviewTable/OverviewTableSubjectDisplay';
import ActivityContextMenuDefinitionModel from '../../models/activities/ActivityContextMenuDefinitionModel';
import ActivityModel from '../../models/activities/ActivityModel';
import FileModel from '../../models/activities/FileModel';
import { IEmailActivityOverviewModel } from '../../models/activities/IEmailActivityOverviewModel';
import { SetDate } from '../helpers/dateHelpers';
import { stringIsNullOrEmpty } from '../helpers/stringHelpers';
import { isNullOrUndefined } from '../helpers/typeGuards';
import styled from '../styled/styled';
import ActivityEmailPriorityDisplay from './ActivityEmailPriorityDisplay';
import ActivityOpenMerged from './ActivityOpenMerged';
import RemoveFromManagerList from 'src/containers/agent/InboxManager/InboxManager/RemoveFromManagerList';
import { Box } from '@chakra-ui/react';

//unused for now, can remove if they don't want to go back
const RedSpan = styled.span`
  color: red;
  font-weight: bold;
`;

//need to pull info from both attachment - notes and client email attachments
export const getFileAttachmentActivityNotes = (model: ActivityModel, isFolio?: boolean, hideMir?: boolean) => {
  const fileList = new Array<FileModel>();
  let notes = model.Notes.filter(
    (x) => !stringIsNullOrEmpty(x.FileHandle) && !x.FileName.toLowerCase().endsWith('eml')
  );
  if (isFolio) {
    notes = notes.filter((x) => !x.IsFolio);
  }
  if (hideMir) {
    notes = notes.filter((x) => !x.FileName.endsWith('.MIR'));
  }

  notes.map((value) =>
    fileList.push(new FileModel(value.FileName, value.FileHandle, value.Id, value.IsFolio, value.CreatedOn))
  );
  let emailAttachments = model.Notes.map((value) => value.ClientEmail.Attachments).flat();
  if (hideMir) {
    emailAttachments = emailAttachments.filter((x) => !x.FileName.endsWith('.MIR'));
  }
  emailAttachments.map((x) => fileList.push(new FileModel(x.FileName, x.FileHandle, x.Id, x.IsFolio, x.CreatedOn)));
  return fileList;
};

export const getFirstActivityEmailNote = (model: ActivityModel | MyActivityModel) => {
  if (isNullOrUndefined(model)) {
    return null;
  }
  let emailActivityNotes = model.Notes.filter((x) => !isNullOrUndefined(x.ClientEmail));
  return emailActivityNotes.shift();
};

export const getActivityTitle = (model: ActivityModel) => {
  const subject = getFirstActivityEmailNote(model)?.ClientEmail.Subject;
  let toReturn = { Title: '', OriginalFlsqList: new Array<string>() };
  !isNullOrUndefined(model.CompanyName) && (toReturn.Title += model.CompanyName);
  !isNullOrUndefined(model.GetHotelName) && (toReturn.Title += ' ' + model.GetHotelName);
  !isNullOrUndefined(model.HotelBookingQuoteInquiry.StartDate) &&
    (toReturn.Title += ' ' + formatMonthNameDayShortYear(model.HotelBookingQuoteInquiry.StartDate));
  !isNullOrUndefined(model.HotelBookingQuoteInquiry.EndDate) &&
    (toReturn.Title += ' ' + formatMonthNameDayShortYear(model.HotelBookingQuoteInquiry.EndDate));
  !isNullOrUndefined(model.HotelBookingQuoteInquiry.Id) &&
    !isNullOrUndefined(model.CompanyName) &&
    (toReturn.Title += ' FLSQ - ' + String(model.HotelBookingQuoteInquiry.Id).padStart(6, '0'));
  if (model.HotelBookingQuoteInquiry.OriginalFlsqs && model.HotelBookingQuoteInquiry.OriginalFlsqs.length > 0) {
    // eslint-disable-next-line array-callback-return
    model.HotelBookingQuoteInquiry.OriginalFlsqs.map((value) => {
      toReturn.OriginalFlsqList.push(String(value).padStart(6, '0'));
    });
  }
  // @ts-expect-error added by automation
  stringIsNullOrEmpty(toReturn.Title) && (toReturn.Title = subject);
  stringIsNullOrEmpty(toReturn.Title) && (toReturn.Title = 'New Tab');
  return toReturn;
};

export const getActivityCancelTitle = (model: ActivityModel) => {
  let toReturn = '';
  if (model.HotelBookingQuoteInquiry.CancelledAgent) {
    toReturn = 'Cancelled by Agent';
  } else {
    toReturn = 'Cancelled by Company';
  }
  if (!model.HotelBookingQuoteInquiry.CancellationFees || model.HotelBookingQuoteInquiry.CancellationFees == 0) {
    toReturn += ' - No Fees';
  } else {
    toReturn += ' - With Fees';
  }
  return toReturn;
};

export const getMyActivityTitle = (model: MyActivityModel) => {
  let toReturn = '';
  !isNullOrUndefined(model.CompanyName) && (toReturn += model.CompanyName);
  !isNullOrUndefined(model.HotelName) && (toReturn += ' ' + model.HotelName);
  !isNullOrUndefined(model.StartDate) && (toReturn += ' ' + formatMonthNameDayShortYear(model.StartDate));
  !isNullOrUndefined(model.EndDate) && (toReturn += ' ' + formatMonthNameDayShortYear(model.EndDate));
  !isNullOrUndefined(model.HotelBookingQuoteInquiryId) &&
    !isNullOrUndefined(model.CompanyName) &&
    (toReturn += ' FLSQ - ' + String(model.HotelBookingQuoteInquiryId).padStart(6, '0'));
  !isNullOrUndefined(model.OriginalFlsq) &&
    !isNullOrUndefined(model.CompanyName) &&
    (toReturn += ' Original FLSQ - ' + String(model.OriginalFlsq).padStart(6, '0'));
  stringIsNullOrEmpty(toReturn) && (toReturn = model.Subject);
  return stringIsNullOrEmpty(toReturn) ? 'New Tab' : toReturn;
};

export const getActivityOverviewTitle = (model: IEmailActivityOverviewModel) => {
  let subject = model.Subject;
  let toReturn = '';
  let name =
    !isNullOrUndefined(model.CompanyNickName) && model.CompanyNickName != ''
      ? model.CompanyNickName
      : model.CompanyName;
  !isNullOrUndefined(name) && (toReturn += name);
  !isNullOrUndefined(model.HotelName) && (toReturn += ' ' + model.HotelName);
  !isNullOrUndefined(model.StartDate) && (toReturn += ' ' + formatMonthNameDayShortYear(SetDate(model.StartDate)));
  !isNullOrUndefined(model.EndDate) && (toReturn += ' ' + formatMonthNameDayShortYear(SetDate(model.EndDate)));
  return stringIsNullOrEmpty(toReturn) ? (
    <>
      {model.IsCancelled && <RedSpan>Cancelled -</RedSpan>}
      <span>{subject}</span>
    </>
  ) : (
    <span>
      {model.IsCancelled && <RedSpan>Cancelled -</RedSpan>}
      {toReturn}{' '}
      {!isNullOrUndefined(model.HotelBookingQuoteInquiryId) && !isNullOrUndefined(model.CompanyName) && (
        <b>FLSQ - {String(model.HotelBookingQuoteInquiryId).padStart(6, '0')}</b>
      )}{' '}
      {model.OriginalFlsqs && model.OriginalFlsqs.length > 0 && !isNullOrUndefined(model.CompanyName) && (
        <span style={{ fontStyle: 'italic' }}>
          Original FLSQs -{' '}
          {model.OriginalFlsqs.map((value, index) => (
            <span key={index}>{`${String(value).padStart(6, '0')} `}</span>
          ))}
        </span>
      )}
    </span>
  );
};

export const showActivityEmailCreated = (item: IEmailActivityOverviewModel) => {
  return formatDateMonthDayYearTimeUSA(`${item.ActivityCreatedOn}Z`);
};
const showActivityEmailDate = (date: string) => {
  if (!stringIsNullOrEmpty(date)) {
    let dbStartDate = date as string;
    if (dbStartDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dbStartDate = dbStartDate.substring(0, dbStartDate.length - 1);
    }
    date = dayjs(dbStartDate, formatters.momentDate).format(formatters.momentDate);
  }
  return formatDateMonthDayYearTimeUSA(date);
};
export const showActivityEmailDateNoTime = (date: string) => {
  if (!stringIsNullOrEmpty(date)) {
    let dbStartDate = date as string;
    if (dbStartDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dbStartDate = dbStartDate.substring(0, dbStartDate.length - 1);
    }
    date = dayjs(dbStartDate, formatters.momentDate).format(formatters.momentDate);
  }
  return formatDayMonthYear(date);
};
export const renderActivityOverviewTable = (item: IEmailActivityOverviewModel, index: number, column: IColumn) => {
  const LastWorked = !stringIsNullOrEmpty(item.LastWorkedOnUser) ? `LWO: ` + item.LastWorkedOnUser : '';
  switch (column.key) {
    case 'Id':
      return <TruncateStringWithToolTip content={item.ActivityId.toString()} />;
    case 'From':
      return <TruncateStringWithToolTip content={stringIsNullOrEmpty(item.From) ? item.AssignedUserName : item.From} />;
    case 'Subject':
      return <OverviewTableSubjectDisplay model={item} />;
    case 'Received':
      return <TruncateStringWithToolTip content={showActivityEmailCreated(item)} />;
    case 'MergedActivityId':
      return <ActivityOpenMerged item={item} />;
    case 'EmailRequest':
      return <TruncateStringWithToolTip content={item.EmailRequestSent === true ? 'Yes' : 'No'} />;
    case 'Status':
      return <div>{ActivityStatusLabel.get(item.Status)}</div>;
    case 'DueDate':
      // @ts-expect-error added by automation
      return <TruncateStringWithToolTip content={showActivityEmailDate(item.DueDate)} />;
    case 'StartDate':
      // @ts-expect-error added by automation
      return <TruncateStringWithToolTip content={showActivityEmailDateNoTime(item.StartDate)} />;
    case 'EndDate':
      // @ts-expect-error added by automation
      return <TruncateStringWithToolTip content={showActivityEmailDateNoTime(item.EndDate)} />;
    case 'ConfirmationNumber':
      return (
        <div>
          {!stringIsNullOrEmpty(item.ConfirmationNumber) && <div>Conf #:{item.ConfirmationNumber}</div>}
          {!stringIsNullOrEmpty(item.ReservationNumber) && <div>Rec Loc: {item.ReservationNumber}</div>}
          {!stringIsNullOrEmpty(item.InvoiceNumber) && <div>Inv #: {item.InvoiceNumber}</div>}
        </div>
      );
    case 'CreatedByObt':
      return <div>{item.CreatedByObt ? 'Yes' : 'No'}</div>;
    case 'Assigned':
      return (
        <div>
          <TruncateStringWithToolTip content={item.AssignedUserName} bold />
          <br />
          <TruncateStringWithToolTip content={LastWorked} italic />
        </div>
      );
    case 'Travellers':
      return !!item.TravellerNames && <TruncateStringWithToolTip content={item.TravellerNames.join(', ')} />;
    case 'ReportTravellers':
      return (
        <div>
          {item.TravellerNames?.map((x, key) => (
            <span key={key}>
              <TruncateStringWithToolTip content={x} bold />
              <br />
            </span>
          ))}
          {!stringIsNullOrEmpty(item.AuthorizedBooker) && (
            <TruncateStringWithToolTip content={`AB: ${item.AuthorizedBooker}`} italic />
          )}
        </div>
      );
    case 'Comments':
      return (
        <>
          {' '}
          <TruncateStringWithToolTip content={item.PriorityNote} />
          {item.WaitingSince && (
            <Box fontSize={'x-small'}>
              <b>Waiting: </b>
              <i>{formatDateMonthDayYearTimeUSA(typeof item.WaitingSince == 'string' ? item.WaitingSince : '')}</i>
            </Box>
          )}
        </>
      );
    case 'OriginalFLSQ':
      return (
        <div>
          {item.OriginalFlsq != null && <span>{String(item.OriginalFlsq).padStart(6, '0')}</span>}
          {item.OriginalFlsqs &&
            item.OriginalFlsqs.length > 0 &&
            item.OriginalFlsqs.map((value, index) => <div key={index}>{String(value).padStart(6, '0')}</div>)}
        </div>
      );
    case 'FLSQ':
      return (
        item.HotelBookingQuoteInquiryId != null && (
          <span>{String(item.HotelBookingQuoteInquiryId).padStart(6, '0')}</span>
        )
      );
    case 'Priority':
      return (
        <Flex justifyEnd>
          {item.NewReply && <FaIconWithToolTip tooltip={'New Reply'} iconName={'fa-envelope'} />}
          <ActivityEmailPriorityDisplay model={item} />
        </Flex>
      );
    case 'Delete':
      return <ActivityEmailSetSpam model={item} />;
    case 'Assignments':
      const model = new ActivityContextMenuDefinitionModel();
      model.SetDataFromOverviewModel(item);
      return <InboxHubContextMenu model={model} activityList />;
    case 'LongTermNote':
      return <TruncateStringWithToolTip content={item.LongTermNote} />;
    case 'FollowUpNotes':
      return (
        <div>
          {item.FollowUpNotes?.map((x) => (
            <span key={index}>
              <TruncateStringWithToolTip content={x} />
              <br />
            </span>
          ))}
        </div>
      );
    case 'RemoveFromManager':
      return <RemoveFromManagerList activityId={item.ActivityId} />;
    case 'Drag':
      return <FaIconWithToolTip clickable tooltip={'Drag'} iconName={'fa-grip-lines'} />;
    default:
      // @ts-expect-error added by automation
      return item[column.key];
  }
};
