import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class QuoteInquiryAdditionalChargeModel extends EntityFormModel {
  constructor() {
    super();
    this.Rate = 0;
    this.Tax = 0;
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  public Rate: number;
  @observable
  public Tax: number;
}
export default QuoteInquiryAdditionalChargeModel;
