import { makeObservable, observable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class DailyHotelRateModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public Name: string;

  constructor() {
    super();

    makeObservable(this);
  }
}

export default DailyHotelRateModel;
