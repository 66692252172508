enum ActivityAlertLevel {
  None = 0,
  Normal = 1,
  Today = 2,
  Upcoming = 3,
  Urgent = 4,
  Tomorrow = 5,
  AgentNote = 10,
  CodeRed = 20,
  Informational = 30
}

export default ActivityAlertLevel;
