import { computed, makeObservable, observable } from 'mobx';
import CompanyStatus from '../../enums/crm/CompanyStatus';
import { stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import { Address } from '../common/Address';
import { IEmailModel } from '../common/Interfaces/IEmailModel';
import { IFaxNumberModel } from '../common/Interfaces/IFaxNumberModel';
import { INameModel } from '../common/Interfaces/INameModel';
import { IPhoneNumberModel } from '../common/Interfaces/IPhoneNumberModel';
import EntityFormModel from '../forms/EntityFormModel';

export class BranchOfficeModel
  extends EntityFormModel
  implements INameModel, IPhoneNumberModel, IFaxNumberModel, IEmailModel
{
  public constructor(_: CompanyStatus = CompanyStatus.Lead) {
    super();
    makeObservable(this);
    this.Address = new Address();
  }

  @observable
  // @ts-expect-error added by automation
  public PhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public FaxNumber: string;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  public Address: Address;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;

  @computed
  public get AddressString() {
    return this.Address.GetFullAddressString();
  }

  @computed
  public get DescriptionAddress() {
    let toReturn = this.Name;
    if (!stringIsNullOrEmpty(this.Name)) {
      toReturn += ' - ';
    }
    if (!stringIsNullOrEmpty(this.Description)) {
      toReturn = `${this.Description} - `;
    }
    return toReturn + this.AddressString;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['Address']);
    this.Address.SetData(dbData.Address);
  }
}
