import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexItem } from '../../../../components/base/flex';
import FaIconWithToolTip from '../../../../components/common/FaIconWithToolTip';
import ActivityStatus from '../../../../enums/activities/ActivityStatus';
import ActivityMergePostModel from '../../../../models/activities/ActivityPostModel';
import ActivityManagementService from '../../../../services/Activities/ActivityManagementService';
import { useUtilityStore } from '../../../../stores/useMobxStores';
import { IEmailActivityOverviewModel } from '../../../../models/activities/IEmailActivityOverviewModel';
import styled from '../../../../utils/styled/styled';

interface IActivityEmailSetSpam {
  model: IEmailActivityOverviewModel;
}

const IconPadding = styled.div`
  padding-left: 2px;
  padding-right: 2px;
`;

const ActivityEmailSetSpam: React.FC<IActivityEmailSetSpam> = (props) => {
  const utilStore = useUtilityStore();
  const ref = React.useRef<HTMLInputElement>();

  const updateActivityStatus = React.useCallback(() => {
    async function save() {
      const postModel = new ActivityMergePostModel(props.model.ActivityId, +props.model.Status);
      const service = new ActivityManagementService();
      await service.updateActivityStatus(postModel);
    }
    props.model.Status = ActivityStatus.Delete;
    save();
  }, [props.model]);

  const onConfirm = React.useCallback(() => {
    utilStore.calloutState.showGenericConfirmation(
      // @ts-expect-error added by automation
      ref,
      `Are you sure you want to set this as Deleted?`,
      updateActivityStatus
    );
  }, [updateActivityStatus, utilStore.calloutState]);

  return (
    <FlexItem ref={ref}>
      <IconPadding>
        <FaIconWithToolTip
          tooltip={`Set as Deleted`}
          altBaseClassName={'far'}
          clickable
          onClick={onConfirm}
          iconName={'fa-trash-alt'}
        />
      </IconPadding>
    </FlexItem>
  );
};

export default observer(ActivityEmailSetSpam);
