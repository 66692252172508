import { TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import styled from '../../utils/styled/styled';

interface ITruncate {
  content: string;
  bold?: boolean;
  italic?: boolean;
}
interface IStyledSpan { 
  bold?: boolean;
  italic?: boolean;
}

const StyledSpan = styled.span<IStyledSpan>`
  font-weight: ${(props) => props.bold && "bold" };
  font-style: ${(props) => props.italic && "italic" };
  font-size: ${(props) => props.italic && ".7Rem"};
`;

const TruncateStringWithToolTip: React.FC<ITruncate> = (props: ITruncate) => {
  return (
    <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={props.content}>
      <StyledSpan bold={props.bold} italic={props.italic}>{props.content}</StyledSpan>
    </TooltipHost>
  );
};

export default TruncateStringWithToolTip;
