import DropdownItem from '../../models/forms/DropdownItem';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class HotelAutoCompleteService extends ServiceBase {
  constructor() {
    super(endpoints.selectList.hotelsByCity);
  }

  public getHotelsByCity = async (city: string) => {
    const response = await this.get<DropdownItem[]>({ city });
    let toReturn = new Array<DropdownItem>();
    // eslint-disable-next-line array-callback-return
    response.data.map((value) => {
      toReturn.push(new DropdownItem(value.Text, value.Value, value.Data));
    });
    return toReturn;
  };
  public getActiveHotelsByCity = async (city: string) => {
    const origEnpoint = this.endpoint;
    this.endpoint = endpoints.selectList.ActiveHotelsByCity;
    const response = await this.get<DropdownItem[]>({ city });
    let toReturn = new Array<DropdownItem>();
    // eslint-disable-next-line array-callback-return
    response.data.map((value) => {
      toReturn.push(
        new DropdownItem(value.Data.ToBeApproved ? value.Text + ' - Not Verified' : value.Text, value.Value, value.Data)
      );
    });
    this.endpoint = origEnpoint;
    return toReturn;
  };
}

export default HotelAutoCompleteService;
