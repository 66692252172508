import { observable, makeObservable } from 'mobx';
import { formatCurrency } from '../../utils/helpers/numberHelpers';
import EntityFormModel from '../forms/EntityFormModel';
import { formatDate } from '../../common/formatters';
import { SetDate } from '../../utils/helpers/dateHelpers';

export class Amenity extends EntityFormModel {
  public constructor(type: string = '', id: number = 0) {
    super();
    makeObservable(this);
    this.Id = id;
    this.AmenityType = type;
  }
  public SetData(dbData: any): void {
    super.SetData(dbData, []);
    SetDate(dbData.LastUpdated);
  }

  @observable
  public AmenityType: string;
  @observable
  // @ts-expect-error added by automation
  public AmenityIcon: string;
  @observable
  // @ts-expect-error added by automation
  public AmenityDescription: string;
  @observable
  // @ts-expect-error added by automation
  public HotelAmenityDescription: string;
  @observable
  public AmenityCost?: number;
  @observable
  // @ts-expect-error added by automation
  public LastUpdated: string;

  public getAmenityCost(): string {
    if (this.AmenityCost == null || this.AmenityCost === 0) {
      return 'Free';
    }

    return formatCurrency(this.AmenityCost, false);
  }
  public getAmenityCreatedOn(): string {
    return formatDate(this.LastUpdated);
  }
}
