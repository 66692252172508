import { observer } from 'mobx-react-lite';
import React from 'react';
import FaIconWithToolTip from 'src/components/common/FaIconWithToolTip';
import InboxManagerService from 'src/services/InboxManager/InboxManagerService';

interface IRemoveFromManagerList {
  activityId: number;
}

const RemoveFromManagerList: React.FC<IRemoveFromManagerList> = (props) => {
  const onRemoveFromManagerList = async () => {
    const service = new InboxManagerService();
    await service.removeFromManagerList(props.activityId);
  };

  return (
    <FaIconWithToolTip
      clickable
      onClick={onRemoveFromManagerList}
      tooltip={'Remove from priority list'}
      iconName={'fa-minus'}
    />
  );
};
export default observer(RemoveFromManagerList);
