/**
 *
 * numberHelpers
 *
 */
export const formatCurrency = (
  amount: number | undefined | null,
  excludeCents: boolean = false,
  roundUp: boolean = false,
  currency: string = 'USD',
  locale: string = 'en-US'
): string => {
  if (amount === undefined || amount == null || isNaN(amount)) {
    amount = 0;
  }
  if (roundUp) {
    amount = Math.ceil(amount);
  }
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: excludeCents ? 0 : 2,
    maximumFractionDigits: excludeCents ? 0 : 2
  });
};

export const formatPercentage = (amount: number | undefined | null, locale: string = 'en-US'): string => {
  if (amount === undefined || amount == null || isNaN(amount)) {
    amount = 0;
  }
  //gotta convert it as it's stored in the DB as 0.1 but we return it to the front end as 10
  const convertedAmount = amount / 100;
  return convertedAmount.toLocaleString(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

export const getNumericValueFromForm = (isNumericOrBoolean: boolean | undefined, value: number | string | null) => {
  if (value == null || value === undefined) {
    return null;
  }
  if (isNumericOrBoolean) {
    const lower = value.toString().toLowerCase();
    if (lower === 'true') {
      return true;
    }
    if (lower === 'false') {
      return false;
    }
    return Number(value);
  } else {
    return value;
  }
};
