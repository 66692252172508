import { IStackTokens } from '@fluentui/react';
import styled from '../../utils/styled/styled';

export const StyledSearchInputDiv = styled.div`
  width: 200px;
`;
export const StyledFLSQInputDiv = styled.div`
  width: 100px;
`;

export const themedSmallStackTokens: IStackTokens = {
  childrenGap: 's1',
  padding: 's1'
};
