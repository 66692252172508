import Announcement from '../../models/common/AnnouncementModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class AnnouncementService extends ServiceBase {
  constructor() {
    super(endpoints.admin.announcementManagement);
  }

  public getRecent = async () => {
    return await this.get<Announcement>({});
  };

  public add = async (model: Announcement) => {
    await this.post({}, model);
  };
}

export default AnnouncementService;
