import { IStackTokens, ScrollablePane, Stack } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useBoolean } from 'src/utils/uiFabricHooks';
import { MarginTopButton } from '../../components/base/buttons';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import ModalWithButtons from '../../components/dialog/ModalWithButtons';
import SearchFirstNameField from '../../components/forms/inputfields/search/SearchFirstNameField';
import SearchLastNameField from '../../components/forms/inputfields/search/SearchLastNameField';
import SearchPhoneNumberField from '../../components/forms/inputfields/search/SearchPhoneNumberField';
import SearchTextField from '../../components/forms/inputfields/search/SearchTextField';
import AgentQuerySearchResultModel from '../../models/search/AgentQuerySearchResultModel';
import SearchService from '../../services/Search/SearchService';
import { useAgentStore } from '../../stores/useMobxStores';
import styled from '../../utils/styled/styled';
import BranchOfficeResultTable from './BranchOfficeResultTable';
import CompanyResultTable from './CompanyResultTable';
import TravellerResultTable from './TravellerResultTable';
import { LimitedGrowthWrapper } from './styledComponents';

const StyledBody = styled.div`
  width: 100%;
`;

interface IGeneralSearchDialog {
  allowEditUser: boolean;
}

const themedSmallStackTokens: IStackTokens = {
  childrenGap: 's1',
  padding: 's1'
};

const GeneralSearchDialog: React.FC<IGeneralSearchDialog> = (props: IGeneralSearchDialog) => {
  const store = useAgentStore();
  const [loading, { toggle: toggleLoading }] = useBoolean(false);

  // @ts-expect-error added by automation
  const [result, setResult] = useState<AgentQuerySearchResultModel>(null);

  const searchCallback = useCallback(() => {
    // Create an scoped async function in the hook
    async function search() {
      const service = new SearchService();
      toggleLoading();
      const searchResults = await service.Search(store.GeneralSearchModel);
      setResult(searchResults);
      toggleLoading();
    }
    search();
  }, [store]);

  const hasSearched = useCallback(() => {
    return result != null;
  }, [result]);

  const showNoResultsMessage = useCallback(() => {
    if (!hasSearched()) {
      return false;
    }
    if (result?.Companies?.length > 0) {
      return false;
    }
    if (result?.BranchOffices?.length > 0) {
      return false;
    }
    if (result?.Travellers?.length > 0) {
      return false;
    }
    return true;
  }, [hasSearched, result?.BranchOffices?.length, result?.Companies?.length, result?.Travellers?.length]);

  return (
    <ModalWithButtons hideOkButton showCancelButton cancelButtonText='Close'>
      <StyledBody>
        <Stack horizontal tokens={themedSmallStackTokens}>
          <SearchTextField model={store.GeneralSearchModel} onEnter={searchCallback} />
          <SearchPhoneNumberField model={store.GeneralSearchModel} onEnter={searchCallback} />
          <SearchFirstNameField model={store.GeneralSearchModel} onEnter={searchCallback} />
          <SearchLastNameField model={store.GeneralSearchModel} onEnter={searchCallback} />
          <MarginTopButton onClick={searchCallback}>Search</MarginTopButton>
          <MarginTopButton
            onClick={() => {
              store.ResetGeneralSearchModel();
              // @ts-expect-error added by automation
              setResult(null);
            }}>
            Clear
          </MarginTopButton>
        </Stack>
        <LimitedGrowthWrapper>
          <ScrollablePane style={{ padding: '0px' }}>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {result?.Companies?.length > 0 && <CompanyResultTable companies={result?.Companies} />}
                {result?.BranchOffices?.length > 0 && <BranchOfficeResultTable branchOffices={result?.BranchOffices} />}
                {result?.Travellers?.length > 0 && (
                  <TravellerResultTable allowEditUser={props.allowEditUser} travellers={result?.Travellers} />
                )}
                {!hasSearched() && <div>Please enter some search criteria</div>}
                {showNoResultsMessage() && <div>No Results</div>}
              </>
            )}
          </ScrollablePane>
        </LimitedGrowthWrapper>
      </StyledBody>
    </ModalWithButtons>
  );
};
export default observer(GeneralSearchDialog);
