import {
  DefaultButton,
  DirectionalHint,
  IButtonProps,
  ITooltipProps,
  PrimaryButton,
  TooltipHost
} from '@fluentui/react';
import styled from '../../utils/styled/styled';

export const ClsDefaultButton = (props: IButtonProps) => <DefaultButton {...props} />;

export const ClsPrimaryButton = (props: IButtonProps) => <PrimaryButton {...props} />;

export const ClsSubmitButton = (props: IButtonProps) => <ClsPrimaryButton type='submit' {...props} />;

interface IDisableButtonProps extends IButtonProps {
  disabledMessage?: Array<string>;
  directionalHint?: DirectionalHint;
  disabledHeader?: string;
}
export const ClsSubmitButtonDisable = (props: IDisableButtonProps) => {
  if (props.disabled) {
    const tooltipProps: ITooltipProps = {
      onRenderContent: () => (
        <>
          <span>{props.disabledHeader ? props.disabledHeader : 'Missing Values:'}</span>
          <ul style={{ margin: 10, padding: 0 }}>
            {props.disabledMessage?.map((message, index) => <li key={index}>{message}</li>)}
          </ul>
        </>
      )
    };
    return (
      <TooltipHost
        directionalHint={!!props.directionalHint ? props.directionalHint : DirectionalHint.topCenter}
        tooltipProps={tooltipProps}>
        <ClsSubmitButton {...props} />
      </TooltipHost>
    );
  } else return <ClsSubmitButton {...props} />;
};

export const ClsPrimaryButtonDisable = (props: IDisableButtonProps) => {
  if (props.disabled) {
    const tooltipProps: ITooltipProps = {
      onRenderContent: () => (
        <>
          <span>Missing Values:</span>
          <ul style={{ margin: 10, padding: 0 }}>
            {props.disabledMessage?.map((message, index) => <li key={index}>{message}</li>)}
          </ul>
        </>
      )
    };
    return (
      <TooltipHost
        directionalHint={!!props.directionalHint ? props.directionalHint : DirectionalHint.topCenter}
        tooltipProps={tooltipProps}>
        <ClsPrimaryButton {...props} />
      </TooltipHost>
    );
  } else return <ClsPrimaryButton {...props} />;
};

export const PaddedSubmitButton = styled(ClsSubmitButton)`
  margin-top: 5px;
`;

export const PaddedClsPrimaryButton = styled(ClsPrimaryButton)`
  margin-top: 5px;
`;

export const PaddedClsDefaultButton = styled(ClsDefaultButton)`
  margin-top: 5px;
`;

export const MarginTopSubmitButton = styled(ClsSubmitButton)`
  margin-top: 31px;
`;

export const MarginTopButton = styled(ClsPrimaryButton)`
  margin-top: 31px;
`;
