import { Box, Tooltip } from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';

export type ToolTipButtonProps = {
  children: ReactElement;
  toolTip?: string;
  disabledWithMessage?: {
    disabledMessages: string[] | string;
  };
};

export default (props: ToolTipButtonProps) => {
  const hasTooltip = useMemo(() => {
    return props.toolTip || (props.disabledWithMessage && props.disabledWithMessage.disabledMessages);
  }, [props.toolTip, props.disabledWithMessage]);

  const getLabel = () => {
    if (!props?.disabledWithMessage?.disabledMessages) {
      return <Box>{props.toolTip}</Box>;
    }

    return (
      <Box>
        {Array.isArray(props.disabledWithMessage.disabledMessages) ? (
          props.disabledWithMessage.disabledMessages.map((message, index) => <Box key={index}>{message}</Box>)
        ) : (
          <Box>{props.disabledWithMessage.disabledMessages}</Box>
        )}
      </Box>
    );
  };

  if (hasTooltip) {
    return (
      <Box>
        <Tooltip label={getLabel()}>{props.children}</Tooltip>
      </Box>
    );
  }
  return props.children;
};
