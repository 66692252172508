import { observable, makeObservable } from 'mobx';
import AgentQuerySearchBranchOfficeResultModel from './AgentQuerySearchBranchOfficeResultModel';
import AgentQuerySearchCompanyResultModel from './AgentQuerySearchCompanyResultModel';
import AgentQuerySearchTravellerResultModel from './AgentQuerySearchTravellerResultModel';

class AgentQuerySearchResultModel {
  public constructor() {
    makeObservable(this);
    this.BranchOffices = [];
    this.Companies = [];
    this.Travellers = [];
  }

  @observable
  public BranchOffices: Array<AgentQuerySearchBranchOfficeResultModel>;
  @observable
  public Companies: Array<AgentQuerySearchCompanyResultModel>;
  @observable
  public Travellers: Array<AgentQuerySearchTravellerResultModel>;
}
export default AgentQuerySearchResultModel;
