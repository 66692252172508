import Endpoint from './Endpoint';

const endpoints = {
  dataAccess: {
    getFile: new Endpoint('getFile', '/api/File?fileHandle={{fileHandle}}')
  },
  admin: {
    userManagement: new Endpoint('userManagement', '/api/admin/UserManagement/{{userId}}'),
    folioManagement: new Endpoint('folioManagement', '/api/admin/FolioManagement/{{id}}'),
    resetPassword: new Endpoint('resetPassword', '/api/admin/UserManagement/ResetPassword'),
    changePassword: new Endpoint('changePassword', '/api/admin/UserManagement/ChangePassword'),
    reportingFieldManagement: new Endpoint('reportingFieldManagement', '/api/admin/ReportingField/{{id}}'),
    reportingRequirementManagement: new Endpoint(
      'ReportingRequirementManagement',
      '/api/admin/ReportingRequirement/{{id}}'
    ),
    additionalChargeManagement: new Endpoint('additionalChargeManagement', '/api/admin/AdditionalCharge/{{id}}'),
    announcementManagement: new Endpoint('announcementFieldManagement', '/api/admin/Announcement'),
    quickDocumentManagement: new Endpoint('quickDocumentManagement', '/api/admin/DocumentManagement/{{id}}'),
    loyaltyAccountManagement: new Endpoint('loyaltyAccountManagement', '/api/admin/LoyaltyAccount/{{id}}'),
    roomTypeManagement: new Endpoint('roomTypeManagement', '/api/admin/ActivityRoomType/{{id}}'),
    cancellationPolicyManagement: new Endpoint('cancellationPolicy', '/api/admin/ActivityCancellationPolicy/{{id}}'),
    itineraryHeaderManagement: new Endpoint('itineraryHeader', '/api/admin/ItineraryHeader/{{id}}'),
    creditCardTypeManagement: new Endpoint('creditCardType', '/api/admin/CreditCardType/{{route}}'),
    emailSignatureManagement: new Endpoint('emailSignatureManagement', '/api/admin/EmailSignature/{{id}}'),
    itinerarySignatureManagement: new Endpoint('itinerarySignatureManagement', '/api/admin/ItinerarySignature'),
    amenitiesManagement: new Endpoint('amenitiesManagement', '/api/admin/Amenities/{{id}}'),
    getAdminUserManagementModel: new Endpoint(
      'getAdminUserManagementModel',
      '/api/admin/UserManagement/GetAdminUserManagementModel'
    )
  },
  finance: {
    dailyHotelRate: new Endpoint('dailyHotelRate', '/api/finance/DailyHotelRate/{{id}}')
  },
  email: {
    composeEmail: new Endpoint('composeEmail', '/api/ComposeEmail')
  },
  agent: {
    query: new Endpoint('agentQuery', '/api/hotelsearch'),
    companyContacts: new Endpoint('companyContactSummary', '/api/companycontacts/{{route}}'),
    activityEmail: new Endpoint('activityEmail', '/api/activityEmail/CheckEmail'),
    verify: new Endpoint('duplicate', '/api/HotelVerification/{{route}}')
  },
  search: {
    search: new Endpoint('search', '/api/Search'),
    hotelGetBasicHotelModel: new Endpoint('hotel', '/api/HotelSearch/GetBasicHotelModel/{{id}}'),
    searchActivity: new Endpoint('activitySearch', '/api/activitysearch/{{route}}'),
    searchActivityTraveller: new Endpoint('searchActivityTraveller', '/api/activitytravellersearch'),
    activityReport: new Endpoint('activityReport', '/api/ActivityReport'),
    activityFolio: new Endpoint('activityFolio', '/api/ActivityFolio/{{route}}'),
    invoiceGroup: new Endpoint('activityFolio', '/api/InvoiceGroup/{{route}}')
  },
  crm: {
    hotel: new Endpoint('hotel', '/api/hotel/{{id}}'),
    hotelGetBasicHotelModel: new Endpoint('hotel', '/api/hotel/GetBasicHotelModel/{{id}}'),
    hotelGetActiveContract: new Endpoint('hotel', '/api/hotel/ActiveContract'),
    hotelSearch: new Endpoint('hotel', '/api/hotel/search'),
    hotelNamesSearch: new Endpoint('hotel', '/api/hotel/searchNames'),
    hotelMassUpdateBasicFields: new Endpoint('hotelMassUpdate', '/api/HotelMassUpdate/MassUpdateBasicFields'),
    hotelMassUpdateRates: new Endpoint('hotelMassUpdateRates', '/api/HotelMassUpdate/MassUpdateRates'),
    hotelMassUpdateGetHotelData: new Endpoint('hotelMassUpdate', '/api/HotelMassUpdate/GetHotelData'),
    company: new Endpoint('company', '/api/company/{{id}}'),
    companyProjectCrm: new Endpoint('CompanyProjectCrm', '/api/CompanyProjectCrm/{{route}}'),
    companyUpdate: new Endpoint('company', '/api/CompanyUpdate'),
    companySearch: new Endpoint('company', '/api/company/search'),
    hotelCrmFormHelper: new Endpoint('hotelCrmFormHelper', '/api/HotelCrmFormHelper')
  },
  activity: {
    activity: new Endpoint('activity', '/api/activity/{{route}}'),
    quoteInquiry: new Endpoint('quoteInquiry', '/api/QuoteInquiry/{{route}}'),
    quoteManagement: new Endpoint('quoteInquiry', '/api/QuoteManagement/{{route}}'),
    preReconciled: new Endpoint('quoteInquiry', '/api/PrereconciledHelper/{{route}}'),
    sendQuoteEmail: new Endpoint('sendQuoteEmail', '/api/SendEmailQuoteItinerary/Quote'),
    sendItineraryEmail: new Endpoint('sendItineraryEmail', '/api/SendEmailQuoteItinerary/Itinerary'),
    sendInvoiceEmail: new Endpoint('sendItineraryEmail', '/api/SendEmailQuoteItinerary/Invoice'),
    sendEmailReportingRequirement: new Endpoint('sendItineraryEmail', '/api/SendEmailReportingRequirement/Requirement'),
    setFolioEmail: new Endpoint('setFolioEmail', '/api/FolioEmail/{{route}}'),
    activityInformation: new Endpoint('activityInformation', '/api/ActivityInformation/{{route}}'),
    activityFormHelper: new Endpoint('activityFormHelper', '/api/ActivityFormHelper'),
    razorViews: {
      quoteItineraryPreview: new Endpoint('view.quotePreview', '/QuoteItineraryPreview/{{route}}')
    },
    activityAdmin: new Endpoint('activityAdmin', '/api/ActivityAdmin/{{route}}')
  },
  InboxManager: {
    inboxManager: new Endpoint('inboxManager', '/api/InboxManagerPriorityList/{{route}}')
  },
  autoAssign: {
    myActivityAutoAssign: new Endpoint('myActivityAutoAssign', '/api/MyActivityAutoAssign')
  },
  selectList: {
    getAllSystemRoles: new Endpoint('getAllSystemRoles', '/api/SelectList/GetSystemRoles'),
    getAllUsers: new Endpoint('getAllSystemRoles', '/api/SelectList/Users'),
    getAllGroupUsers: new Endpoint('getAllSystemRoles', '/api/SelectList/Users/group'),
    getAllAgents: new Endpoint('getAllSystemRoles', '/api/SelectList/agents'),
    hotelsByCity: new Endpoint('hotelsByCity', '/api/SelectList/HotelsByCity?city={{city}}'),
    ActiveHotelsByCity: new Endpoint('hotelsByCity', '/api/SelectList/ActiveHotelsByCity?city={{city}}')
  },
  auditing: {
    getAudits: new Endpoint('getAuditForHotel', '/api/Auditing/{{route}}')
  },
  accountManagement: new Endpoint('getUser', '/api/AccountManagement'),
  appSettings: new Endpoint('AppSettingsController', '/api/AppSettings'),
  workingHours: new Endpoint('WorkingHours', '/api/WorkingHours'),
  workflowHub: new Endpoint('workflowHub', '/hubs/workflowHub')
};

export default endpoints;
