import { Contact } from '../contacts/Contact';
import FormModel from '../forms/FormModel';

class AgentQuerySearchTravellerResultModel extends FormModel {
  public constructor() {
    super();
    this.Traveller = new Contact();
  }

  public Traveller: Contact;
  // @ts-expect-error added by automation
  public CompanyName: string;
  // @ts-expect-error added by automation
  public CodingRequirements: string;
  // @ts-expect-error added by automation
  public CompanyId: number;

  public SetData(dbData: any): void {
    this.CompanyName = dbData.CompanyName;
    this.CodingRequirements = dbData.CodingRequirements;
    this.CompanyId = dbData.CompanyId;
    this.Traveller.SetData(dbData.Traveller);
  }
}
export default AgentQuerySearchTravellerResultModel;
