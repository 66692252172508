import { Spinner } from 'reactstrap';
import styled from '../../utils/styled/styled';

const LoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerContainer>
      <Spinner color='primary' />
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
