import { observable, makeObservable, IObservableArray, computed } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import EntityFormModel from '../forms/EntityFormModel';
import HotelBookingReportingRequirementModel from '../activities/HotelBookingReportingRequirementModel';

class QuoteInquiryTravellerModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
    this.ReportingRequirements = new Array<HotelBookingReportingRequirementModel>() as IObservableArray;
  }
  public SetData(dbData: any): void {
    if (this.ReportingRequirements != undefined) {
      super.SetData(dbData, ['ReportingRequirements']);
      arraySetData(this.ReportingRequirements, HotelBookingReportingRequirementModel, dbData.ReportingRequirements);
    }
  }
  @observable
  // @ts-expect-error added by automation
  public FirstName: string;
  @observable
  // @ts-expect-error added by automation
  public LastName: string;
  @observable
  // @ts-expect-error added by automation
  public NickName: string;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public MobilePhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public BusinessPhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public NonStaticValue: boolean;
  @observable
  public ReportingRequirements: IObservableArray<HotelBookingReportingRequirementModel>;
  @observable
  // @ts-expect-error added by automation
  public ToBeVerified: boolean;
  @observable
  // @ts-expect-error added by automation
  public IncludeInQuote: boolean;

  @computed
  public get FullName() {
    return this.FirstName + ' ' + this.LastName;
  }
}
export default QuoteInquiryTravellerModel;
