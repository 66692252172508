import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const frontierHeader = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '5px'
});

const modalHeader = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '0px'
});

const fieldArray = defineStyle({
  fontSize: '16px',
  marginBottom: '4px'
});

export const headerTheme = defineStyleConfig({
  variants: {
    frontierHeader,
    modalHeader,
    fieldArray
  },
  defaultProps: { size: 'md' }
});
