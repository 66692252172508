import { makeObservable, observable } from 'mobx';
import { IFileModel } from '../activities/IFileModelInterface';
import EntityFormModel from '../forms/EntityFormModel';

export class CompanyContractModel extends EntityFormModel implements IFileModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public PaymentTerms: string;
  @observable
  // @ts-expect-error added by automation
  public ContractTerms: string;
  @observable
  // @ts-expect-error added by automation
  public InvoiceProcedure: string;
  @observable
  // @ts-expect-error added by automation
  public HowTheyPay: string;
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
}
