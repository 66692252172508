/**
 *
 * styled
 *
 * This is a wrapper for several modules in styled-components
 *
 */

import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';
import { ITheme } from '../../theme';

const { default: styled, css } = styledComponents as unknown as ThemedStyledComponentsModule<ITheme>;

export { css };
export default styled;
