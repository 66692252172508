import { IObservableArray, observable, makeObservable } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { IActiveStateModel } from '../common/Interfaces/IActiveStateModel';
import EntityFormModel from '../forms/EntityFormModel';
import { HotelRate } from './HotelRate';

export class HotelRoomType extends EntityFormModel implements IActiveStateModel {
  public constructor() {
    super();
    makeObservable(this);
    this.Active = true;
    this.Rates = observable.array(new Array<HotelRate>());
    this.ActiveRate = new HotelRate();
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['Rates', 'ActiveRate']);
    arraySetData(this.Rates, HotelRate, dbData.Rates);
    this.ActiveRate.SetData(dbData.ActiveRate);
  }

  @observable
  public Active: boolean;
  @observable
  // @ts-expect-error added by automation
  public RoomType: string;
  @observable
  // @ts-expect-error added by automation
  public IsCompany: boolean;
  @observable
  public Rates: IObservableArray<HotelRate>;
  @observable
  public ActiveRate: HotelRate;
}
