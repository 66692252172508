import { Spinner, Stack, Text } from '@chakra-ui/react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

export const QueryStatus = () => {
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  return (
    <Stack width={'20px'}>
      {isMutating > 0 ? <Spinner color={'primary.500'} /> : isFetching > 1 ? <Spinner color='secondary.300' /> : null}
    </Stack>
  );
};
