import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const frontierInput = definePartsStyle({
  addon: {
    border: '1px solid',
    borderColor: '#969696',
    background: '#f5f5f5',
    borderRadius: '4px',
    color: 'gray.500',
    height: '35px',
    padding: '12px',
    marginBottom: '4px'
  },
  field: {
    background: 'white',
    borderRadius: '4px',
    border: '1px solid #969696',
    marginBottom: '4px',
    height: '35px',
    padding: '12px',

    _hover: {
      borderColor: '#c0bfbf'
    },
    _focus: {
      borderColor: '#1054c0',
      backgroundColor: '#fafafa'
    },
    _invalid: {
      border: '1px dashed red'
    },
    _readOnly: {
      opacity: '0.5'
    }
  },
  icon: {
    borderRadius: '0'
  }
});

const inlineInput = definePartsStyle({
  field: {
    color: 'black',
    borderRadius: '8px',
    bg: 'gray.100',
    _hover: {
      bg: 'gray.200'
    },
    _focus: {
      bg: 'gray.100',
      borderColor: 'blue.100'
    }
  }
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    frontierInput,
    inlineInput
  },
  defaultProps: {
    variant: 'frontierInput'
  }
});
