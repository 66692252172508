import { action, makeObservable, observable } from 'mobx';
import { IActiveStateModel } from 'src/models/common/Interfaces/IActiveStateModel';
import EntityFormModel from 'src/models/forms/EntityFormModel';
import CurrencyCode from './CurrencyCode';
import CreditCardPaymentEmailType from './CreditCardPaymentEmailType';

export class CreditCardTypeModel extends EntityFormModel implements IActiveStateModel {
  @observable
  // @ts-expect-error added by automation
  public CardType: string;
  @observable
  // @ts-expect-error added by automation
  public Active: boolean;
  @observable
  public CurrencyCode: CurrencyCode;
  @observable
  // @ts-expect-error added by automation
  public ExpectsPaymentEmail: boolean;
  @observable
  public PaymentEmailType: CreditCardPaymentEmailType;

  constructor() {
    super();
    makeObservable(this);
    this.CurrencyCode = CurrencyCode.CAD; // default CAD.
    this.PaymentEmailType = CreditCardPaymentEmailType.VirtualCard;
  }
  @action
  public Toggle = () => {
    this.ExpectsPaymentEmail = !this.ExpectsPaymentEmail;
  };
}
