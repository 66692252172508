import { IObservableArray, action, computed, makeObservable, observable } from 'mobx';
import { arraySetData } from 'src/utils/helpers/arrayHelpers';
import { Address } from '../common/Address';
import DailyAverageRateModel from '../finance/DailyAverageRateModel';
import FormModel from '../forms/FormModel';
import HotelBookingQuoteInquiryTaxRateModel from './HotelBookingQuoteInquiryTaxRateModel';

class HotelBookingQuoteInquiryHotelRateModel extends FormModel {
  constructor(hotelName?: string) {
    super();
    // @ts-expect-error added by automation
    this.HotelName = hotelName;
    this.HotelAddress = new Address();
    this.AverageDailyRate = new Array<DailyAverageRateModel>() as IObservableArray;
    this.QuoteTaxRate = new HotelBookingQuoteInquiryTaxRateModel();
    makeObservable(this);
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['QuoteTaxRate', 'AverageDailyRate', 'HotelAddress']);
    this.QuoteTaxRate.SetData(dbData.QuoteTaxRate);
    this.HotelAddress.SetData(dbData.HotelAddress);
    arraySetData(this.AverageDailyRate, DailyAverageRateModel, dbData.AverageDailyRate);
  }
  private formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  @observable
  // @ts-expect-error added by automation
  public Id: number;
  @observable
  // @ts-expect-error added by automation
  public RoomType: string;
  @observable
  // @ts-expect-error added by automation
  public FrontierRate: number;
  @observable
  // @ts-expect-error added by automation
  public Rate: number;
  @observable
  // @ts-expect-error added by automation
  public HotelId: number;
  @observable
  public HotelName: string;

  @observable
  public HotelAddress: Address;

  @observable
  // @ts-expect-error added by automation
  public CancellationPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public RateNote: string;
  @observable
  public QuoteTaxRate: HotelBookingQuoteInquiryTaxRateModel;
  @observable
  public AverageDailyRate: IObservableArray<DailyAverageRateModel>;
  @observable
  // @ts-expect-error added by automation
  public IsBlackOut: boolean;

  @action
  public SetCancellationPolicy = (value: string) => {
    this.CancellationPolicy = value;
  };

  @computed
  public get RateValueString() {
    return this.formatter.format(this.Rate);
  }
  @computed
  public get FrontierRateRateValueString() {
    return this.formatter.format(this.FrontierRate);
  }

  @action
  public TotalAveragedRate() {
    if (this.AverageDailyRate.length == 0) return this.Rate;
    let totalDay = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalDay += value.NumberOfDays;
    });
    let totalRate = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalRate += value.Rate * value.NumberOfDays;
    });
    let calc = totalRate / totalDay;
    this.Rate = Number(calc.toFixed(2));
    return this.Rate;
  }

  @computed
  public get DisplayTotalAveragedRate() {
    if (this.AverageDailyRate.length == 0) return { displayString: this.formatter.format(0), rate: 0 };
    let totalDay = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalDay += value.NumberOfDays;
    });
    let totalRate = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalRate += value.Rate * value.NumberOfDays;
    });
    return { displayString: this.formatter.format(totalRate / totalDay), rate: totalRate / totalDay };
  }
  @computed
  public get DisplayTotalNights() {
    let totalDay = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalDay += value.NumberOfDays;
    });
    return totalDay;
  }

  @computed
  public get HasAveragedRates() {
    return this.AverageDailyRate?.length > 0;
  }

  @action
  public AddAverageDailyRate = (startDate?: string, endDate?: string) => {
    let newAverageDailyRate = new DailyAverageRateModel(startDate, endDate);
    newAverageDailyRate.Rate = this.Rate;
    this.AverageDailyRate.push(newAverageDailyRate);
  };
}
export default HotelBookingQuoteInquiryHotelRateModel;
