import { Box, useDisclosure } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import WorkflowTool from 'src-new/features/WorkflowTool/WorkflowTool';
import { NavBar } from 'src-new/features/header/NavBar';
import { useLayoutStore } from 'src-new/stores/layoutStore';
import Callout from 'src/components/callout/Callout';
import Modal from 'src/components/dialog/Modal';
import OverlayLoadingSpinner from '../loadingSpinner/OverlayLoadingSpinner';

const Layout = () => {
  const auth = useAuth();
  const joinWorkflowDiscolureLayout = useDisclosure();
  const [isInWorkflow, setIsInWorkflow] = useState(false);
  const layoutStore = useLayoutStore();

  return (
    <Box paddingBottom={'30px'}>
      <NavBar isInWorkflow={isInWorkflow} joinWorkflowDisclosure={joinWorkflowDiscolureLayout} />
      {auth.isAuthenticated && (
        <WorkflowTool disclosure={joinWorkflowDiscolureLayout} setIsInWorkflow={setIsInWorkflow} />
      )}
      <Box
        w={['100%', '100%', '80%']}
        maxW={'1800px'}
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}>
        <Outlet />
      </Box>
      <Callout />
      <Modal />
      <OverlayLoadingSpinner />
      {layoutStore.isDataDisplayOpen && <Box paddingBottom={'35vh'}></Box>}
    </Box>
  );
};
export default Layout;
