import ActivitySetAsFolioModel from '../../models/QuoteInquiry/ActivitySetAsFolioModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class ActivityFolioManagementService extends ServiceBase {
  constructor() {
    super(endpoints.activity.setFolioEmail);
  }
  public addToFolioList = async (model: ActivitySetAsFolioModel) => {
    await this.post({ route: 'AddEmail' }, model);
  };
}

export default ActivityFolioManagementService;
