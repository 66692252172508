import { Icon, Menu, MenuButton } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavMenuSubItemList } from './NavMenuSubItemList';
import { NavItem } from './types/NavItem';
import { useShouldRenderNavItem } from './useShouldRenderNavItem';

type NavMenuItemProps = {
  item: NavItem;
};

export const NavMenuItem = ({ item }: NavMenuItemProps) => {
  const nav = useNavigate();
  const { shouldRender } = useShouldRenderNavItem(item);

  const onNavClick = useCallback(
    (navItem: NavItem) => {
      if (navItem.onClick) {
        navItem.onClick();
      }
      if (navItem.path) {
        nav(navItem.path);
      }
    },
    [nav]
  );

  const renderedSubItems: Array<NavItem> = useMemo(() => {
    if (!item?.subItems) {
      return [];
    }
    return item.subItems.filter((value) => {
      return value.text != undefined;
    });
  }, [item]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        onContextMenu={(e) => {
          e.preventDefault();
          if (item.path) {
            window.open(item.path, '_blank');
          }
        }}
        onClick={() => {
          onNavClick(item);
        }}>
        {item.isActive && <strong>{item.text}</strong>}
        {!item.isActive && item.text}

        {renderedSubItems && renderedSubItems.length > 0 && (
          <Icon as={FontAwesomeIcon} icon={faChevronDown} w={'13px'} paddingLeft={'2px'} />
        )}
      </MenuButton>
      {renderedSubItems && renderedSubItems.length > 0 && <NavMenuSubItemList subItems={item.subItems!} />}
    </Menu>
  );
};
