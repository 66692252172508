import { IContextualMenuItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import ActivityAlertLevel from '../../../../enums/activities/ActivityAlertLevel';
import ActivityContextMenuDefinitionModel from '../../../../models/activities/ActivityContextMenuDefinitionModel';
import ActivityPostModel from '../../../../models/activities/ActivityPostModel';
import ActivityManagementService from '../../../../services/Activities/ActivityManagementService';

const setAlertLevel = async (activityId: number, level: ActivityAlertLevel) => {
  const postModel = new ActivityPostModel(activityId, +level);
  const service = new ActivityManagementService();
  await service.updateActivityAlertLevel(postModel);
};

export const InboxContextMenuPriority = (model: ActivityContextMenuDefinitionModel, setPriorityNote: () => void) => {
  const [contextItems, setContextItems] = useState(new Array<IContextualMenuItem>());

  const onSetUpcoming = () => {
    setAlertLevel(model.Id, ActivityAlertLevel.Upcoming);
    setPriorityNote();
  };

  useEffect(() => {
    const array = new Array<IContextualMenuItem>();
    if (model.AlertLevel !== ActivityAlertLevel.Normal) {
      array.push({
        key: 'Basic',
        text: 'Set to Basic',
        onClick: () => {
          setAlertLevel(model.Id, ActivityAlertLevel.Normal);
        }
      });
    }
    if (model.AlertLevel !== ActivityAlertLevel.Today) {
      array.push({
        key: 'Today',
        text: 'Set To Today',
        onClick: () => {
          setAlertLevel(model.Id, ActivityAlertLevel.Today);
        }
      });
    }
    if (model.AlertLevel !== ActivityAlertLevel.Tomorrow) {
      array.push({
        key: 'Tomorrow',
        text: 'Set To Tomorrow',
        onClick: () => {
          setAlertLevel(model.Id, ActivityAlertLevel.Tomorrow);
        }
      });
    }
    if (model.AlertLevel !== ActivityAlertLevel.Upcoming) {
      array.push({
        key: 'Upcomming',
        text: 'Set To Upcoming',
        onClick: () => onSetUpcoming()
      });
    }
    if (model.AlertLevel !== ActivityAlertLevel.Urgent) {
      array.push({
        key: 'Urgent',
        text: 'Set To Urgent',
        onClick: () => {
          setAlertLevel(model.Id, ActivityAlertLevel.Urgent);
        }
      });
    }
    setContextItems(array);
  }, [model.AlertLevel, model.Id]);

  return contextItems;
};
