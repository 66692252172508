enum ModalWidth {
  Normal = 0,
  Mid = 1,
  Fullscreen = 2,
  FitContent = 3,
  Half = 4,
  TrueFullscreen = 5,
}

export default ModalWidth;
