import { observer } from 'mobx-react-lite';
import { ActivityAlertColor } from '../../common/ActivityEmailColorPickers';
import ActivityAlertLevel from '../../enums/activities/ActivityAlertLevel';
import { IEmailActivityOverviewModel } from '../../models/activities/IEmailActivityOverviewModel';
import { PriorityDisplay } from '../../containers/agent/Inbox/styledComponents';
import ActivityStatus from '../../enums/activities/ActivityStatus';
interface IActivityEmailPriorityDisplay {
  model: IEmailActivityOverviewModel;
}

const ActivityEmailPriorityDisplay: React.FC<IActivityEmailPriorityDisplay> = (
  props: IActivityEmailPriorityDisplay
) => {
  let priority = '';
  const alertColor = ActivityAlertColor(props.model.AlertLevel);
  if (props.model.Status == ActivityStatus.Waiting && !props.model.NewReply) {
    priority = '';
  } else {
    switch (props.model.AlertLevel) {
      case ActivityAlertLevel.Urgent:
        priority = 'Urg';
        break;
      case ActivityAlertLevel.Upcoming:
        priority = 'Upc';
        break;
      case ActivityAlertLevel.Today:
        priority = 'Tod';
        break;
      case ActivityAlertLevel.Tomorrow:
        priority = 'Tom';
        break;
    }
  }

  return <PriorityDisplay color={alertColor.color}>{priority}</PriorityDisplay>;
};

export default observer(ActivityEmailPriorityDisplay);
