import { useQuery } from '@tanstack/react-query';
import { getAxios } from 'src/lib/axios';
import { AnnouncementModel } from '../models/AnnouncementModel';

async function getCurrentAnnouncement(): Promise<AnnouncementModel> {
  return (await getAxios().get(`/api/admin/Announcement`)).data;
};

export function useCurrentAnnouncementQuery() {
 return useQuery({
    queryKey: ['currentAnnouncement'],
    retry: false,
    queryFn: getCurrentAnnouncement
  });
}

