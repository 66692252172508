import { makeObservable, runInAction } from 'mobx';
import { lazyObservable } from 'mobx-utils';
import UserService from 'src/services/UserManagement/UserService';
import UserModel from '../models/account/UserModel';

class UserStore {
  constructor() {
    makeObservable(this);
  }

  public LazyUserModel = lazyObservable<UserModel>((sink) =>
    new UserService().getUser().then((x) => {
      runInAction(() => {
        if (x === null) {
          sink(new UserModel());
          return;
        }
        let model = new UserModel();
        model.SetFromNewUserModel(x);
        sink(model);
      });
    })
  );
}
export default UserStore;
