/* eslint-disable array-callback-return */
import dayjs from 'dayjs';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { SetDatePropertyName } from '../../utils/helpers/dateHelpers';
import { numberIsNullOrZero, stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import { isNullOrUndefined } from '../../utils/helpers/typeGuards';
import ActivityPdfFileModel from '../activities/ActivityPdfFileModel';
import HotelBookingReportingRequirementModel from '../activities/HotelBookingReportingRequirementModel';
import { CreditCardTypeModel } from '../admin/activity/CreditCardTypeModel';
import CompanyQueryResponseModel from '../common/CompanyQueryResponseModel';
import ICityNameModel from '../common/Interfaces/ICityNameModel';
import IPriorityNote from '../common/Interfaces/IPriorityNote';
import DailyAverageRateModel from '../finance/DailyAverageRateModel';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';
import HotelBookingQuoteInquiryHotelRateModel from './HotelBookingQuoteInquiryHotelRateModel';
import HotelBookingQuoteInquiryIssueReconcilationNoteModel from './HotelBookingQuoteInquiryIssueReconcilationNoteModel';
import QuoteInquiryAdditionalChargeModel from './QuoteInquiryAdditionalChargeModel';
import QuoteInquiryCreditCardTypeModel from './QuoteInquiryCreditCardTypeModel';
import QuoteInquirySelectedPdfModel from './QuoteInquirySelectedPdfModel';
import QuoteInquiryTravellerModel from './QuoteInquiryTravellerModel';

class HotelBookingQuoteInquiryModel extends TimeSeriesEntityFormModel implements ICityNameModel, IPriorityNote {
  @observable
  public PreferredHotelId: number | undefined;
  @observable
  public PreferredCityId: number | undefined;

  @observable
  // @ts-expect-error added by automation
  public PreferredCityName: string;

  @observable
  // @ts-expect-error added by automation
  public PriorityNote: string;
  @observable
  // @ts-expect-error added by automation
  public ETA: string;
  @observable
  public OriginalFlsqs: IObservableArray<number>;
  @observable
  // @ts-expect-error added by automation
  public CancelledReason: string;
  @observable
  // @ts-expect-error added by automation
  public ServiceFeeReason: string;
  @observable
  // @ts-expect-error added by automation
  public FrontDeskAgentName: string;
  @observable
  // @ts-expect-error added by automation
  public CancelledCompany: boolean;
  @observable
  // @ts-expect-error added by automation
  public CancelledInGds: boolean;
  @observable
  public CancellationFees: number | undefined;
  @observable
  // @ts-expect-error added by automation
  public CancellationNumber: string;
  @observable
  // @ts-expect-error added by automation
  public IsLongTerm: boolean;
  @observable
  // @ts-expect-error added by automation
  public CancelledAgent: boolean;
  @observable
  // @ts-expect-error added by automation
  public CancelledUser: string;
  @observable
  // @ts-expect-error added by automation
  public ItinerarySelectedRateId: number;
  @observable
  public ItinerarySelectedRate: HotelBookingQuoteInquiryHotelRateModel;
  @observable
  public AverageDailyRate: IObservableArray<DailyAverageRateModel>;
  @observable
  public CreditCardTypes: IObservableArray<QuoteInquiryCreditCardTypeModel>;
  @observable
  // @ts-expect-error added by automation
  public ReservationNumber: string;
  @observable
  // @ts-expect-error added by automation
  public GraspVCC: string;
  @observable
  // @ts-expect-error added by automation
  public ConfirmationNumber: string;
  @observable
  // @ts-expect-error added by automation
  public BestAvailableRate: number;
  @observable
  // @ts-expect-error added by automation
  public FolioTotal: number;
  @observable
  // @ts-expect-error added by automation
  public ItineraryComment: string;
  @observable
  public Travellers: IObservableArray<QuoteInquiryTravellerModel>;
  @observable
  public AuthorizedBooker: QuoteInquiryTravellerModel;
  @observable
  public ApprovedBooker: QuoteInquiryTravellerModel;
  @observable
  public HotelRates: IObservableArray<HotelBookingQuoteInquiryHotelRateModel>;
  @observable
  public IssueReconcilationNotes: IObservableArray<HotelBookingQuoteInquiryIssueReconcilationNoteModel>;
  @observable
  public AdditionalCharges: IObservableArray<QuoteInquiryAdditionalChargeModel>;
  @observable
  // @ts-expect-error added by automation
  public AdditionalHotelNotes: string;
  @observable
  public ReportingRequirements: IObservableArray<HotelBookingReportingRequirementModel>;
  @observable
  public TravellerReportingRequirements: IObservableArray<HotelBookingReportingRequirementModel>;
  @observable
  // @ts-expect-error added by automation
  public FrontierRate: number;
  @observable
  // @ts-expect-error added by automation
  public FrontierRateSelectedReason: string;
  @observable
  // @ts-expect-error added by automation
  public HasDetailedPayment: boolean;
  @observable
  // @ts-expect-error added by automation
  public HasDetailedPaymentReason: string;
  @observable
  // @ts-expect-error added by automation
  public InvoiceDate: string;
  @observable
  // @ts-expect-error added by automation
  public InvoiceInformation: string;
  @observable
  // @ts-expect-error added by automation
  public ServiceFeeInformation: string;
  @observable
  // @ts-expect-error added by automation
  public AttachFolio: boolean;
  @observable
  // @ts-expect-error added by automation
  public FopComment: string;
  @observable
  // @ts-expect-error added by automation
  public FolioNotes: string;
  @observable
  public SelectedPdfs: IObservableArray<QuoteInquirySelectedPdfModel>;
  @observable
  public GuestTravellers: number;
  //for FE use only
  @observable
  // @ts-expect-error added by automation
  public ItinerarySent: boolean;
  @observable
  // @ts-expect-error added by automation
  public ItinerarySentByUserName: string;
  @observable
  // @ts-expect-error added by automation
  public ItinerarySentDate: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedByObt: boolean;
  @observable
  // @ts-expect-error added by automation
  public LongTermNote: string;
  @observable
  public PreferredHotel: string | undefined;
  @observable
  public PreferredCity: string | undefined;
  @observable
  // @ts-expect-error added by automation
  public ExcludeTravelerFromQuote: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  public constructor() {
    super();
    makeObservable(this);
    this.AuthorizedBooker = new QuoteInquiryTravellerModel();
    this.ApprovedBooker = new QuoteInquiryTravellerModel();
    this.Travellers = new Array<QuoteInquiryTravellerModel>() as IObservableArray;
    this.HotelRates = new Array<HotelBookingQuoteInquiryHotelRateModel>() as IObservableArray;
    this.ReportingRequirements = new Array<HotelBookingReportingRequirementModel>() as IObservableArray;
    this.TravellerReportingRequirements = new Array<HotelBookingReportingRequirementModel>() as IObservableArray;
    this.IssueReconcilationNotes = new Array<HotelBookingQuoteInquiryIssueReconcilationNoteModel>() as IObservableArray;
    this.AdditionalCharges = new Array<ActivityPdfFileModel>() as IObservableArray;
    this.SelectedPdfs = new Array<QuoteInquirySelectedPdfModel>() as IObservableArray;
    this.AverageDailyRate = new Array<DailyAverageRateModel>() as IObservableArray;
    this.ItinerarySelectedRate = new HotelBookingQuoteInquiryHotelRateModel();
    this.OriginalFlsqs = new Array<number>() as IObservableArray;
    this.GuestTravellers = 0;
    this.CreditCardTypes = new Array<CreditCardTypeModel>() as IObservableArray;
  }

  @computed
  public get StartDateMoment() {
    return this.StartDate ? dayjs.utc(this.StartDate).local() : null;
  }

  @computed
  public get EndDateMoment() {
    return this.EndDate ? dayjs.utc(this.EndDate).local() : null;
  }

  @computed
  public get CanSendItinerary() {
    return !!this.ConfirmationNumber && !!this.ReservationNumber && this.Travellers.length > 0;
  }

  @computed
  public get HasTravellerRequirements() {
    return this.Travellers.map((value) => value.ReportingRequirements.length > 0).includes(true);
  }

  @computed
  public get TotalNights() {
    if (stringIsNullOrEmpty(this.EndDate) || stringIsNullOrEmpty(this.StartDate)) return 0;
    const startDate = dayjs(this.StartDate);
    const endDate = dayjs(this.EndDate);
    return endDate.diff(startDate, 'days');
  }

  @computed
  public get SelectedRate() {
    return this.HotelRates.find((value) => value.Id === this.ItinerarySelectedRateId);
  }

  @computed
  public get CompanyReportingRequirements() {
    return this.ReportingRequirements;
  }

  @computed
  public get GetCityName() {
    if (this.ItinerarySelectedRate) {
      return this.ItinerarySelectedRate.HotelAddress.City;
    }
    return '';
  }

  @computed
  public get GetHotelName() {
    if (this.ItinerarySelectedRate) {
      return this.ItinerarySelectedRate.HotelName;
    }
    return '';
  }

  @computed
  public get GetHotelId(): number | undefined {
    if (this.ItinerarySelectedRate) {
      return this.ItinerarySelectedRate.HotelId;
    }

    // @ts-expect-error added by automation
    return null;
  }

  @computed
  public get CantSendItinerary() {
    let rate = this.ItinerarySelectedRate;
    let message = new Array<string>();
    let check = false;
    if (!this.FrontierRate || (this.FrontierRate == 2 && stringIsNullOrEmpty(this.FrontierRateSelectedReason))) {
      !this.FrontierRate && message.push('Frontier Rate');
      this.FrontierRate == 2 &&
        stringIsNullOrEmpty(this.FrontierRateSelectedReason) &&
        message.push('Reason Rate was not selected');
    }

    if (
      this.ReservationNumber == this.Id.toString() ||
      this.ReservationNumber == String(this.Id.toString()).padStart(6, '0')
    ) {
      message.push('Record Locator must not equal FLSQ Number.');
    }

    if (stringIsNullOrEmpty(rate?.CancellationPolicy)) {
      message.push('Cancellation Policy');
    }
    if (!rate.QuoteTaxRate.Commissionable) {
      message.push('Commissionable');
    }
    if (
      rate.QuoteTaxRate.Commissionable == 1 &&
      this.CheckIfZeroOrNull(rate?.QuoteTaxRate.Commision) &&
      this.CheckIfZeroOrNull(rate?.QuoteTaxRate.CommisionPercent)
    ) {
      message.push('Commission');
    }
    if (rate.QuoteTaxRate.Commissionable == 2 && stringIsNullOrEmpty(this.ServiceFeeReason)) {
      message.push('Reason For Service Fee');
    }
    if (rate.QuoteTaxRate.Commissionable == 3 && this.CheckIfZeroOrNull(rate?.QuoteTaxRate.CampMarkUp)) {
      message.push('Camp Mark Up');
    }
    if (this.Travellers.length == 0 && this.GuestTravellers == 0) {
      message.push('Travellers');
    }
    if (!this.ReservationNumber || this.ReservationNumber.length < 6) {
      message.push('Record Locator must be at least 6 characters');
    }
    if (numberIsNullOrZero(this.BestAvailableRate)) {
      message.push('Best Available Rate');
    }
    if (numberIsNullOrZero(this.ItinerarySelectedRate?.Rate)) {
      message.push('Selected Rate');
    }
    if (!this.ConfirmationNumber) {
      message.push('Confirmation Number');
    }
    this.CreditCardTypes.forEach((element) => {
      if (element.CreditCardTypeId === null || element.CreditCardTypeId === undefined) {
        message.push('Form of Payment');
      }
    });
    if (stringIsNullOrEmpty(this.EndDate) && stringIsNullOrEmpty(this.StartDate)) {
      message.push('Start Date and End Date');
    }
    if (!this.AuthorizedBooker.Id) {
      message.push('Authorized Booker');
    }
    if (!this.ApprovedBooker.Id) {
      message.push('Approved Booker');
    }
    this.ReportingRequirements.forEach((element) => {
      if (element.Mandatory && stringIsNullOrEmpty(element.Value)) {
        message.push(`Reporting Requirement: ${element.Name}`);
      }
    });
    this.TravellerReportingRequirements.forEach((element) => {
      if (element.Mandatory && stringIsNullOrEmpty(element.Value)) {
        message.push(`Reporting Requirement: ${element.Name}`);
      }
    });
    check = message.length > 0;
    return { check, message };
  }

  @computed
  public get TotalDailyRate() {
    return !!this.ItinerarySelectedRate && !!this.ItinerarySelectedRate.Rate
      ? (this.ItinerarySelectedRate.Rate * this.TotalNights).toFixed(2)
      : 0;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, [
      'Travellers',
      'HotelRates',
      'AuthorizedBooker',
      'ApprovedBooker',
      'ReportingRequirements',
      'IssueReconcilationNotes',
      'AdditionalCharges',
      'TaxRates',
      'ItinerarySelectedRate',
      'SelectedPdfs',
      'AverageDailyRate',
      'TravellerReportingRequirements',
      'CreditCardTypes'
    ]);
    if (isNullOrUndefined(dbData)) {
      return;
    }

    SetDatePropertyName(dbData, this, 'InvoiceDate');
    this.ItinerarySelectedRate.SetData(dbData.ItinerarySelectedRate);
    this.AuthorizedBooker.SetData(dbData.AuthorizedBooker);
    this.ApprovedBooker.SetData(dbData.ApprovedBooker);
    arraySetData(this.Travellers, QuoteInquiryTravellerModel, dbData.Travellers);
    arraySetData(this.HotelRates, HotelBookingQuoteInquiryHotelRateModel, dbData.HotelRates);
    arraySetData(this.ReportingRequirements, HotelBookingReportingRequirementModel, dbData.ReportingRequirements);
    arraySetData(
      this.TravellerReportingRequirements,
      HotelBookingReportingRequirementModel,
      dbData.TravellerReportingRequirements
    );
    arraySetData(this.CreditCardTypes, QuoteInquiryCreditCardTypeModel, dbData.CreditCardTypes);
    arraySetData(this.AdditionalCharges, QuoteInquiryAdditionalChargeModel, dbData.AdditionalCharges);
    arraySetData(this.SelectedPdfs, QuoteInquirySelectedPdfModel, dbData.SelectedPdfs);
    arraySetData(this.AverageDailyRate, DailyAverageRateModel, dbData.AverageDailyRate);
    arraySetData(
      this.IssueReconcilationNotes,
      HotelBookingQuoteInquiryIssueReconcilationNoteModel,
      dbData.IssueReconcilationNotes
    );
  }

  @action
  public SetCompanyProperties = (company: CompanyQueryResponseModel) => {
    this.HasDetailedPayment = company.HasDetailedPayment;
    this.ExcludeTravelerFromQuote = company.ExcludeTravelerFromQuote;
    this.HasDetailedPaymentReason = company.HasDetailedPaymentReason;
    this.ServiceFeeInformation = company.ServiceFeeInformation;
    this.AttachFolio = company.AttachFolio;
  };

  @action
  public SetItinerarySelectedRateId = (value: number) => {
    this.ItinerarySelectedRateId = value;
  };

  @action
  public ClearItinerarySelectedRate = () => {
    // @ts-expect-error added by automation
    this.ItinerarySelectedRate = null;
    // @ts-expect-error added by automation
    this.ItinerarySelectedRateId = null;
    this.ItinerarySent = false;
  };

  @action
  public AddAverageDailyRate = () => {
    let newAverageDailyRate = new DailyAverageRateModel();
    newAverageDailyRate.Rate = this.ItinerarySelectedRate.Rate;
    newAverageDailyRate.NumberOfDays = this.TotalNights;
    this.AverageDailyRate.push(newAverageDailyRate);
  };

  @action
  public RemoveAverageDailyRate = (index: number) => {
    this.AverageDailyRate.splice(index, 1);
  };

  @action
  public AddAdditionalCharge = () => {
    let AddAdditionalCharge = new QuoteInquiryAdditionalChargeModel();
    AddAdditionalCharge.Rate = 0;
    AddAdditionalCharge.Tax = 0;
    this.AdditionalCharges.push(AddAdditionalCharge);
  };

  @action
  public RemoveAdditionalCharge = (index: number) => {
    this.AdditionalCharges.splice(index, 1);
  };

  @action
  public UpdateItinerarySelectedRate = () => {
    let rate = this.HotelRates.find((x) => x.Id == this.ItinerarySelectedRateId);
    if (rate == null) return;
    this.ItinerarySelectedRate = rate;
  };

  @action
  public UpdateCancellationPolicy = () => {
    let rate = this.HotelRates.find((x) => x.Id == this.ItinerarySelectedRateId);
    if (rate == null) return;
    rate.CancellationPolicy = this.ItinerarySelectedRate.CancellationPolicy;
  };

  @action
  public TotalAveragedRate() {
    if (this.AverageDailyRate.length == 0) return this.formatter.format(this.ItinerarySelectedRate.Rate);
    let totalDay = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalDay += value.NumberOfDays;
    });
    let totalRate = 0;
    // eslint-disable-next-line array-callback-return
    this.AverageDailyRate.map((value) => {
      totalRate += value.Rate * value.NumberOfDays;
    });
    this.ItinerarySelectedRate.Rate = totalRate / totalDay;
    return this.formatter.format(totalRate / totalDay);
  }

  @action TotalAveragedDays() {
    let totalDay = 0;
    this.AverageDailyRate.map((value) => {
      totalDay += value.NumberOfDays;
    });
    return totalDay;
  }

  @action
  public TotalFeesAndLevees() {
    let totalCharges = 0;
    this.AdditionalCharges.map((value) => {
      totalCharges += value.Rate;
    });
    totalCharges += this.ItinerarySelectedRate.QuoteTaxRate.TotalLevy;
    return this.formatter.format(totalCharges);
  }

  @action
  public TotalTaxes() {
    let totalCharges = 0;
    this.AdditionalCharges.map((value) => {
      totalCharges += value.Tax;
    });
    totalCharges +=
      this.ItinerarySelectedRate.QuoteTaxRate.TotalLevyTaxes + this.ItinerarySelectedRate.QuoteTaxRate.TotalGst;
    return this.formatter.format(totalCharges);
  }

  public CheckIfZeroOrNull(value: number) {
    return value == null || value == 0;
  }

  @action
  public TotalFare(totalDays: number, rate: number) {
    let totalCharges = 0;
    this.AdditionalCharges.map((value) => {
      totalCharges += value.Tax + value.Rate;
    });
    return this.formatter.format(
      totalCharges +
        this.ItinerarySelectedRate.QuoteTaxRate.TotalGst +
        this.ItinerarySelectedRate.QuoteTaxRate.TotalLevy +
        this.ItinerarySelectedRate.QuoteTaxRate.TotalLevyTaxes +
        rate * totalDays
    );
  }

  @action
  public TotalFareAmount(totalDays: number, rate: number): number {
    let totalCharges = 0;
    this.AdditionalCharges.map((value) => {
      totalCharges += value.Tax + value.Rate;
    });
    return Number(
      (
        totalCharges +
        this.ItinerarySelectedRate.QuoteTaxRate.TotalGst +
        this.ItinerarySelectedRate.QuoteTaxRate.TotalLevy +
        this.ItinerarySelectedRate.QuoteTaxRate.TotalLevyTaxes +
        rate * totalDays
      ).toFixed(2)
    );
  }
}

export default HotelBookingQuoteInquiryModel;
