import { DirectionalHint, FocusTrapCallout, FocusZone } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useUtilityStore } from '../../stores/useMobxStores';

interface ICallout {
  header?: string;
  cancelCallback?: () => void;
  okCallback?: () => void;
  okButtonText?: string;
}

const Callout: React.FC<ICallout> = () => {
  const store = useUtilityStore();
  return (
    <>
      {store.calloutState.IsOpen ? (
        <FocusTrapCallout
          role='alertdialog'
          styles={{ calloutMain: { width: store.calloutState.ExactCalloutWidth } }}
          gapSpace={0}
          target={store.calloutState.Target}
          onDismiss={store.calloutState.close}
          isBeakVisible={false}
          directionalHint={store.calloutState.Direction ? store.calloutState.Direction : DirectionalHint.rightCenter}
          setInitialFocus
          preventDismissOnEvent={store.calloutState.preventDismiss}>
          <FocusZone>{store.calloutState.Content}</FocusZone>
        </FocusTrapCallout>
      ) : null}
    </>
  );
};

export default observer(Callout);
