import React from 'react';
import ConfirmationCallout from './ConfirmationCallout';

interface ICalloutGenericConfirm {
  header: string;
  okCallback?: () => void;
  cancelCallback?: () => void;
}

const CalloutGenericConfirm: React.FunctionComponent<ICalloutGenericConfirm> = (props: ICalloutGenericConfirm) => {
  return (
    <ConfirmationCallout
      header={props.header}
      cancelCallback={props.cancelCallback}
      okCallback={props.okCallback}></ConfirmationCallout>
  );
};

export default CalloutGenericConfirm;
