import { action, computed, observable, makeObservable } from 'mobx';
import * as React from 'react';
import ModalGenericAlert from '../../components/dialog/ModalGenericAlert';
import ModalGenericConfirm from '../../components/dialog/ModalGenericConfirm';
import ModalWidth from '../../enums/common/ModalWidth';

class ModalState {
  @observable
  public Content: React.ReactNode;
  @observable
  public CloseOnClick: boolean;
  @observable
  public CloseOnEscape: boolean;
  @observable
  public IsOpen: boolean;
  @observable
  private modalWidth?: ModalWidth;

  public constructor() {
    makeObservable(this);
    this.IsOpen = false;
    this.CloseOnClick = false;
    this.CloseOnEscape = false;
  }

  @action
  public showGenericAlert = (alert: string, okCallback?: () => void) => {
    this.open(<ModalGenericAlert header={alert} okCallback={okCallback} />);
  };

  @action
  public showGenericConfirmation = (
    alert: string,
    okCallback?: () => void,
    cancelCallback?: () => void,
    okayButtonText?: string,
    closeButtonText?: string
  ) => {
    this.open(
      <ModalGenericConfirm
        header={alert}
        cancelCallback={cancelCallback}
        okCallback={okCallback}
        okayButtonText={okayButtonText}
        closeButtonText={closeButtonText}
      />
    );
  };

  @action
  public open = (
    content: React.ReactNode,
    modalWidth?: ModalWidth,
    closeOnClick?: boolean,
    closeOnEscape?: boolean
  ) => {
    this.IsOpen = true;
    this.modalWidth = modalWidth;
    this.Content = content;
    if (closeOnClick !== undefined && closeOnClick !== null) {
      this.CloseOnClick = closeOnClick;
    }
    if (closeOnEscape !== undefined && closeOnEscape !== null) {
      this.CloseOnEscape = closeOnEscape;
    }
  };

  @action
  public close = () => {
    this.IsOpen = false;
  };

  @computed
  public get ExactModalWidth(): string {
    switch (this.modalWidth) {
      case ModalWidth.Mid:
        return '75%';
      case ModalWidth.Fullscreen:
        return '90%';
      case ModalWidth.FitContent:
        return 'auto';
      case ModalWidth.Half:
        return '50%';
      case ModalWidth.TrueFullscreen:
        return '100%';
      case ModalWidth.Normal:
      default:
        return '750px';
    }
  }
}

export default ModalState;
