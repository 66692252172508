import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class ActivitySetAsFolioModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public Subject: string;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
}
export default ActivitySetAsFolioModel;
