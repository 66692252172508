import { observable, makeObservable } from 'mobx';
import { isNullOrUndefined } from '../../utils/helpers/typeGuards';

class DropdownItem<T = any> {
  constructor(text: string, value?: any, data?: T, overrideValue?: boolean, disabled?: boolean) {
    makeObservable(this);
    this.Text = text;
    if (overrideValue || !isNullOrUndefined(value)) {
      this.Value = value;
    } else {
      this.Value = text;
    }
    this.Data = data;
    // @ts-expect-error added by automation
    this.Disabled = disabled;
  }

  @observable
  public Value: any;
  @observable
  public Text: string;
  @observable
  public Disabled: boolean;

  /**
   * Data available to custom onRender functions.
   */
  @observable
  public Data?: T;
  @observable
  public title?: string;
}

export default DropdownItem;
