import { observer } from 'mobx-react-lite';
import React from 'react';

interface IMailtoLinkProps {
  address: string;
  displayAddress: boolean;
  // @ts-expect-error added by automation
  onClick?: (value) => void;
}
const MailtoLink: React.FC<IMailtoLinkProps> = (props: IMailtoLinkProps) => {
  let address = props.address;
  if (!address) {
    address = '';
  }

  const renderAnchor = React.useCallback(
    (value: string) => {
      return (
        <a href={`mailto:${value}`} rel='noreferrer' target='_blank'>
          {!props.displayAddress && <i className='fas fa-envelope'></i>}
          {props.displayAddress && value}
        </a>
      );
    },
    [props.displayAddress]
  );

  return renderAnchor(address);
};

export default observer(MailtoLink);
