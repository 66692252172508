import { action, makeObservable, observable } from 'mobx';
import { ActivitySort } from '../../enums/activities/ActivitySort';
import FormModel from '../forms/FormModel';

class ActivitySortFilterModel extends FormModel {
  @observable
  public ActivitySort: ActivitySort;
  @observable
  public ShowOnlyMine: boolean;
  @observable
  public SortAscending: boolean;
  @observable
  // @ts-expect-error added by automation
  public ColumnnKey: string;

  constructor() {
    super();
    makeObservable(this);
    this.ActivitySort = ActivitySort.Priority;
    this.ShowOnlyMine = false;
    this.SortAscending = false;
  }

  @action
  public Reset = () => {
    this.ActivitySort = ActivitySort.CreatedOn;
    this.ShowOnlyMine = false;
    this.SortAscending = false;
  };
  @action
  public SetColumnKey = (columnnKey: string) => {
    this.ColumnnKey = columnnKey;
  };

  @action
  public ToggleShowOnlyMine = () => {
    this.ShowOnlyMine = !this.ShowOnlyMine;
  };

  @action
  public ToggleSortAscending = () => {
    this.SortAscending = !this.SortAscending;
  };
  @action
  public SetSortAscending = () => {
    this.SortAscending = true;
  };
  @action
  public SetSortDescending = () => {
    this.SortAscending = false;
  };
}

export default ActivitySortFilterModel;
