import dayjs from 'dayjs';
import { action, IObservableArray, observable, makeObservable } from 'mobx';
import ICityModel from '../common/Interfaces/ICityModel';
import { IEndDate } from '../common/Interfaces/IEndDate';
import { IStartDate } from '../common/Interfaces/IStartDate';
import FormModel from '../forms/FormModel';
import AgentQueryTravellersResponseModel from './AgentQueryTravellersResponseModel';

class AgentQueryModel extends FormModel implements ICityModel, IStartDate, IEndDate {
  constructor() {
    super();
    makeObservable(this);
    this.TravellerIds = observable.array(new Array<number>());
    this.City = 'Calgary (Alberta)';
    this.StartDate = dayjs().startOf('day').toISOString();
    this.EndDate = '';
    // @ts-expect-error added by automation
    this.CompanyId = null;
    this.Travellers = observable.array(new Array<AgentQueryTravellersResponseModel>());
  }

  @observable
  public CompanyId: number;

  @observable
  public TravellerIds: IObservableArray<number>;

  @observable
  public Travellers: IObservableArray<AgentQueryTravellersResponseModel>;

  @observable
  // @ts-expect-error added by automation
  public AuthorizedBookerId: number;

  @observable
  public City: string;

  @observable
  public StartDate: string;

  @observable
  public EndDate: string;

  @observable
  // @ts-expect-error added by automation
  public AmenityId: number;

  @action
  public Reset = () => {
    this.TravellerIds.length = 0;
    // @ts-expect-error added by automation
    this.CompanyId = null;
    this.City = 'Calgary (Alberta)';
    // @ts-expect-error added by automation
    this.StartDate = null;
    // @ts-expect-error added by automation
    this.EndDate = null;
    // @ts-expect-error added by automation
    this.AuthorizedBookerId = null;
    // @ts-expect-error added by automation
    this.AmenityId = null;
  };
  @action
  public SetTravellerIds = () => {
    if (this.Travellers.length > 0) {
      this.TravellerIds = this.Travellers.map((x) => x.Id) as IObservableArray;
    }
  };
}
export default AgentQueryModel;
