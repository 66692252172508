import { BranchOfficeModel } from '../companies/BranchOfficeModel';
import FormModel from '../forms/FormModel';

class AgentQuerySearchBranchOfficeResultModel extends FormModel {
  public constructor() {
    super();
    this.BranchOffice = new BranchOfficeModel();
  }

  public BranchOffice: BranchOfficeModel;

  // @ts-expect-error added by automation
  public CompanyName: string;
  // @ts-expect-error added by automation
  public CompanyId: number;

  public SetData(dbData: any): void {
    this.BranchOffice.SetData(dbData.BranchOffice);
    this.CompanyName = dbData.CompanyName;
    this.CompanyId = dbData.CompanyId;
  }
}
export default AgentQuerySearchBranchOfficeResultModel;
