import dayjs from 'dayjs';
import { computed, observable, makeObservable } from 'mobx';
import formatters from '../../common/formatters';
import { SetEndDate, SetStartDate } from '../../utils/helpers/dateHelpers';
import { isNullOrUndefined } from '../../utils/helpers/typeGuards';
import { IEndDate } from '../common/Interfaces/IEndDate';
import { IStartDate } from '../common/Interfaces/IStartDate';
import EntityFormModel from './EntityFormModel';

abstract class TimeSeriesEntityFormModel extends EntityFormModel implements IStartDate, IEndDate {
  public constructor(defaultDatesToStartAndEndOfYear: boolean = false) {
    super();
    makeObservable(this);
    if (defaultDatesToStartAndEndOfYear) {
      this.DefaultDatesToStartEndOfYear();
    }
  }

  @observable
  // @ts-expect-error added by automation
  public StartDate: string;

  @observable
  // @ts-expect-error added by automation
  public EndDate: string;

  public SetData(dbData: any, ignoreProps: Array<string> = []): void {
    super.SetData(dbData, ignoreProps);
    if (isNullOrUndefined(dbData)) {
      return;
    }
    SetStartDate(dbData, this);
    SetEndDate(dbData, this);
  }

  @computed
  public get IsCurrent(): boolean {
    const now = dayjs().startOf('day').format(formatters.momentDate);
    return this.IsCurrentDateRange(now);
  }

  public IsCurrentDateRange = (date: string) => {
    const dateToCompare = dayjs(date, formatters.momentDate);
    if (this.StartDate && this.EndDate) {
      const renewalDate = dayjs(this.StartDate);
      const expiryDate = dayjs(this.EndDate);
      return dateToCompare.isSameOrAfter(renewalDate) && dateToCompare.isSameOrBefore(expiryDate);
    } else if (this.StartDate) {
      const renewalDate = dayjs(this.StartDate);
      return dateToCompare.isSameOrAfter(renewalDate);
    } else if (this.EndDate) {
      const expiryDate = dayjs(this.EndDate);
      return dateToCompare.isSameOrBefore(expiryDate);
    } else {
      return true;
    }
  };

  public DefaultDatesToStartEndOfYear = () => {
    const start = dayjs().startOf('year');
    const end = dayjs().endOf('year');
    this.StartDate = dayjs(start, formatters.momentDate).format(formatters.momentDate);
    this.EndDate = dayjs(end, formatters.momentDate).format(formatters.momentDate);
  };
}

export default TimeSeriesEntityFormModel;
