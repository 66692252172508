import { extendTheme } from '@chakra-ui/react';
import { ChakraThemeConfig } from '@cksoftware/react-base';
import { alertTheme } from './components/alert';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { drawerTheme } from './components/drawer';
import { formTheme } from './components/form';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { numberInputTheme } from './components/inputNumber';
import { selectTheme } from './components/inputSelect';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tab';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';
import { textAreaTheme } from 'src-new/theme/components/inputTextArea';

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 200
  }
};

export const chakraTheme = extendTheme({
  ...themeConfig,
  config: {
    cssVarPrefix: 'chak'
  },
  components: {
    Card: cardTheme,
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Table: tableTheme,
    Switch: switchTheme,
    Alert: alertTheme,
    Drawer: drawerTheme,
    Textarea: textAreaTheme
  },
  styles: {
    global: {
      body: {
        background: '#faf9f67f'
      },
      html: {
        overflowY: 'scroll'
      }
    }
  },
  fonts: {
    heading: `'Montserrat Variable', 'sans-serif'`,
    body: `'Montserrat Variable', 'sans-serif'`
  },
  colors: {
    WorkflowGroupActionType: {
      None: '#f8d8d8',
      Status: '#f8d8d8',
      Ready: '#d8f8db',
      Assigned: '#d8eaf8'
    },
    activityTypes: {
      colorSchemes: {
        cancelled: 'red',
        longTerm: 'purple',
        obt: 'orange'
      }
    },
    radioButton: {
      selected: '#f05a29',
      unselected: 'white',
      border: '#f05a29'
    },
    icons: {
      selectedBackground: 'white',
      unselectedBackground: 'white',
      selected: '#f05a29',
      unselected: '#255993'
    },
    primary: {
      50: '#fff5f2',
      100: '#ffe4da',
      200: '#ffc2ab',
      300: '#ff9e7c',
      400: '#ff7d4e',
      500: '#f05a29', // Base Color
      600: '#d04821',
      700: '#b0371a',
      800: '#902611',
      900: '#701609'
    },
    secondary: {
      50: '#e4eeff',
      100: '#bfcff4',
      200: '#98b3e6',
      300: '#7098db',
      400: '#4a80d0',
      500: '#255993',
      600: '#24568f',
      700: '#183967',
      800: '#091e40',
      900: '#00071b'
    }
  }
});
