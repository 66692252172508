import { Box, Text } from '@chakra-ui/react';
import { useCurrentAnnouncementQuery } from '../api/useCurrentAnnouncmentQuery';

export const AnnouncementBox = () => {
  const announcement = useCurrentAnnouncementQuery();

  if (announcement.isLoading || !announcement.data) {
    return null;
  }

  return (
    <Box color={'white'} backgroundColor={'secondary.500'} maxH={'70px'} overflow={'auto'} padding={'5px'}>
      <Text whiteSpace={'pre-wrap'} fontSize={'10px'} fontWeight={'bold'}>
        {announcement.data.Notes}
      </Text>
    </Box>
  );
};
