/**
 *
 * InputDefinitionBuilder
 *
 */
import { Validate } from 'react-hook-form';
import TextBoxCase from '../../enums/form/TextBoxCase';
import InputDefinition from '../../models/forms/InputDefinition';
import { stringIsNullOrEmpty } from '../helpers/stringHelpers';
import { isNullOrUndefined } from '../helpers/typeGuards';
import {
  getMaxStringLength,
  getMaxValue,
  getMinStringLength,
  getMinValue,
  getRequiredMessageForCheckbox,
  getRequiredMessageForSelect,
  getRequiredMessageForTextBox
} from '../validation/validationMessages';

class InputDefinitionBuilder {
  private definition: InputDefinition;

  constructor(labelOrData: any = undefined) {
    this.definition = new InputDefinition();

    if (labelOrData === undefined) {
      return;
    }
    if (typeof labelOrData === 'string') {
      this.definition.Label = labelOrData;
    } else if (typeof labelOrData === 'object') {
      // If the object is passed, it should have all data (Label, HelpText etc.)
      this.definition = Object.assign(this.definition, labelOrData);
    } else {
      throw new Error('Invalid prop passed to InputDefinitionBuilder');
    }
    if (stringIsNullOrEmpty(this.definition.Name)) {
      // @ts-expect-error added by automation
      this.definition.Name = this.definition.Label.toLowerCase();
      this.definition.Placeholder = 'Enter a ' + this.definition.Name;
    }
  }

  public WithTextBoxCase = (textBoxCase: TextBoxCase) => {
    this.definition.TextBoxCase = textBoxCase;
    return this;
  };

  public WithHelpText = (helpText: string) => {
    this.definition.HelpText = helpText;
    return this;
  };

  public WithMask = (mask: string) => {
    this.definition.Mask = mask;
    return this;
  };

  public WithLabel = (label: string) => {
    this.definition.Label = label;
    this.definition.Name = this.definition.Label.toLowerCase();
    return this;
  };

  public WithName = (name: string) => {
    this.definition.Name = name;
    return this;
  };

  public WithPlaceholder = (placeholder: string) => {
    this.definition.Placeholder = placeholder;
    return this;
  };

  public WithRequiredTextBoxField = (required: boolean = true, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.required = { value: required, message: message };
    } else {
      this.definition.RegisterOptions.required = {
        value: required,
        message: getRequiredMessageForTextBox(this.definition.Name)
      };
    }
    return this;
  };

  public WithRequiredCheckBoxField = (required: boolean = true, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.required = { value: required, message: message };
    } else {
      this.definition.RegisterOptions.required = {
        value: required,
        // @ts-expect-error added by automation
        message: getRequiredMessageForCheckbox(this.definition.Name)
      };
    }
    return this;
  };

  public WithRequiredSelectField = (required: boolean = true, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.required = { value: required, message: message };
    } else {
      this.definition.RegisterOptions.required = {
        value: required,
        message: getRequiredMessageForSelect(this.definition.Name)
      };
    }
    return this;
  };

  public WithMinValue = (min: number, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.min = { value: min, message: message };
    } else {
      // @ts-expect-error added by automation
      this.definition.RegisterOptions.min = { value: min, message: getMinValue(this.definition.Name, min) };
    }
    return this;
  };

  public WithMaxValue = (max: number, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.max = { value: max, message: message };
    } else {
      // @ts-expect-error added by automation
      this.definition.RegisterOptions.max = { value: max, message: getMaxValue(this.definition.Name, max) };
    }
    return this;
  };

  public WithMaxLength = (maxLength: number, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.maxLength = { value: maxLength, message: message };
    } else {
      this.definition.RegisterOptions.maxLength = {
        value: maxLength,
        // @ts-expect-error added by automation
        message: getMaxStringLength(this.definition.Name, maxLength)
      };
    }
    return this;
  };

  public WithMinLength = (minLength: number, message?: string) => {
    if (message) {
      this.definition.RegisterOptions.minLength = { value: minLength, message: message };
    } else {
      this.definition.RegisterOptions.minLength = {
        value: minLength,
        // @ts-expect-error added by automation
        message: getMinStringLength(this.definition.Name, minLength)
      };
    }
    return this;
  };

  public WithPattern = (pattern: RegExp, message: string) => {
    this.definition.RegisterOptions.pattern = { value: pattern, message: message };
    return this;
  };

  public WithCustomValidationRule = (validate: Validate<any, any>) => {
    if (!isNullOrUndefined(validate)) {
      this.definition.RegisterOptions.validate = validate;
    }
    return this;
  };

  public Build = () => {
    return this.definition;
  };
}

export default InputDefinitionBuilder;
