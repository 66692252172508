import { ProtectedRoute } from '@cksoftware/react-base';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import WorkflowProvider from 'src-new/features/WorkflowTool/WorkflowProvider';
import EmailActivitesHubProvider from 'src-new/features/activites/EmailActivitesHubProvider';
import { AllAccessRoles } from 'src/constants/applicationRoles';
import { RouteError } from './RouteError';
import { FrontierAccessDenied } from './FrontierAccessDenied';

function getLayoutElement(navItem: NavItem, parentLayout: React.ReactNode) {
  if (navItem.allowedRoles) {
    return (
      <ProtectedRoute
        allowedRoles={[...AllAccessRoles, ...navItem.allowedRoles]}
        accessDeniedOverride={<FrontierAccessDenied />}>
        <WorkflowProvider>
          <EmailActivitesHubProvider>{navItem.layout ?? parentLayout}</EmailActivitesHubProvider>
        </WorkflowProvider>
      </ProtectedRoute>
    );
  }
  return navItem.layout ?? parentLayout;
}

export function BuildRoute(navItem: NavItem): RouteObject {
  const subItems = new Array<RouteObject>();
  if (navItem.subItems) {
    navItem.subItems.forEach((value) => {
      subItems.push({
        path: value.path,
        lazy: value.lazy,
        errorElement: <RouteError />
      });
      if (value.additionalPaths) {
        value.additionalPaths.forEach((additionalPath) => {
          subItems.push({
            path: additionalPath,
            lazy: value.lazy,
            errorElement: <RouteError />
          });
        });
      }
    });
  }

  const toReturn = new Array<RouteObject>(...subItems);

  if (navItem.path) {
    toReturn.push({
      path: navItem.path,
      lazy: navItem.lazy,
      errorElement: <RouteError />
    });
  }

  if (navItem.additionalPaths) {
    navItem.additionalPaths.forEach((path) => {
      toReturn.push({
        path,
        lazy: navItem.lazy,
        errorElement: <RouteError />
      });
    });
  }

  return {
    path: '/',
    element: getLayoutElement(navItem, null),
    errorElement: <RouteError />,
    children: [...toReturn, ...subItems]
  };
}
