import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

export class ActivityRoomType extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public RoomType: string;

  constructor() {
    super();

    makeObservable(this);
  }
}
