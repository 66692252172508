import hubEndpoints from '../../services/Common/hubEndpoints';
import ActivitiesHubBase from './ActivitiesHubBase';

class HotelActivitiesHub extends ActivitiesHubBase {
  public constructor(entityId: number) {
    super(entityId, hubEndpoints.activities.HotelActivitiesHub);
  }
}

export default HotelActivitiesHub;
