import { EmailSignatureModel } from '../../models/email/EmailSignatureModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class ItinerarySignatureService extends ServiceBase {
  constructor() {
    super(endpoints.admin.itinerarySignatureManagement);
  }

  public getAll = async () => {
    return await this.get<EmailSignatureModel>();
  };

  public addOrUpdate = async (model: EmailSignatureModel) => {
    await this.post({}, model);
  };
}

export default ItinerarySignatureService;
