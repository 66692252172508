import { observable, makeObservable } from 'mobx';
import CompanyStatus from '../../enums/crm/CompanyStatus';
import ShowActiveModel from '../common/ShowActiveModel';

class CompanyQueryModel extends ShowActiveModel {
  constructor() {
    super();
    makeObservable(this);
    this.Status = observable.array(new Array<number>());
    this.Status.push(CompanyStatus.Client);
    this.Filter = '';
  }

  @observable
  public Status: Array<number>;
  @observable
  public Filter: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;
}
export default CompanyQueryModel;
