import { LoyaltyAccount } from '../../models/contacts/LoyaltyAccount';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class LoyaltyAccountService extends ServiceBase {
  constructor() {
    super(endpoints.admin.loyaltyAccountManagement);
  }

  public getAll = async () => {
    return await this.get<Array<LoyaltyAccount>>({ id: '' });
  };

  public add = async (model: LoyaltyAccount) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: LoyaltyAccount) => {
    await this.put({ id: '' }, model);
  };
  public deleteAccount = async (id: number) => {
    await this.delete({ id: `${id}` });
  };
}

export default LoyaltyAccountService;
