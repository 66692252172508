import { getAxios } from '../../lib/axios';
import { getFormattedUrl } from '../../utils/helpers/stringHelpers';
import Endpoint from './Endpoint';

abstract class ServiceBase {
  protected endpoint: Endpoint;
  // @ts-expect-error added by automation
  protected transformedUrl: string;

  constructor(endpoint: Endpoint) {
    this.endpoint = endpoint;
  }

  protected post = async <T>(urlParams?: any, params?: any) => {
    this.transformedUrl = getFormattedUrl(this.endpoint, urlParams);
    const request = getAxios().post<T>(this.transformedUrl, params);
    return await request;
  };

  protected get = async <T>(urlParams?: any) => {
    this.transformedUrl = getFormattedUrl(this.endpoint, urlParams);
    const request = getAxios().get<T>(this.transformedUrl);
    return await request;
  };

  protected put = async <T>(urlParams?: any, params?: any) => {
    this.transformedUrl = getFormattedUrl(this.endpoint, urlParams);
    const request = getAxios().put<T>(this.transformedUrl, params);
    return await request;
  };

  protected delete = async <T>(urlParams?: any) => {
    this.transformedUrl = getFormattedUrl(this.endpoint, urlParams);
    const request = getAxios().delete<T>(this.transformedUrl);
    return await request;
  };
}

export default ServiceBase;
