import { PropsWithChildren } from 'react';

import { observer } from 'mobx-react-lite';
import ModalState from '../../../models/modal/ModalState';
import { ModalContext } from './ModalContext';

interface EmailActivityProvider {
  modalState: ModalState;
}

const ModalContextProvider = ({ children, modalState }: PropsWithChildren<EmailActivityProvider>) => {
  return <ModalContext.Provider value={{ modalState }}>{children}</ModalContext.Provider>;
};

export default observer(ModalContextProvider);
