import CompanyStatus from '../../enums/crm/CompanyStatus';

export const getCompanyStatusStyle = (type: CompanyStatus) => {
  switch (type) {
    case CompanyStatus.Lead:
      return 'secondary';
    case CompanyStatus.Prospect:
    case CompanyStatus.HotProspect:
      return 'info';
    case CompanyStatus.Client:
      return 'primary';
  }
};

export const getYesNoFromBoolean = (val: boolean | string) => {
  switch (val) {
    case true:
    case 'True':
      return 'Yes';
    case false:
    case 'False':
      return 'No';
  }
};

// @ts-expect-error added by automation
export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};
