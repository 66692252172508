import { action, makeObservable, runInAction } from 'mobx';
import ActivityFormHelperModel from '../../models/activities/ActivityFormHelperModel';
import ActivityFormHelperResponseModel from '../../models/activities/ActivityFormHelperResponseModel';
import EmailActivityStateModel from '../../models/activities/EmailActivityStateModel';
import Result from '../../models/common/Result';
import CrmStore from '../../stores/crmStore';
import Endpoint from '../Common/Endpoint';
import ServiceBase from '../Common/ServiceBase';
import endpoints from '../Common/endpoints';

class ActivityFormHelperService extends ServiceBase {
  constructor() {
    super(endpoints.activity.activityFormHelper);
    makeObservable(this);
  }

  @action
  public load = async (store: EmailActivityStateModel) => {
    const model = await this.get<ActivityFormHelperModel>();
    runInAction(() => {
      store.EmailSignatures.replace(model.data.EmailSignatures);
    });
  };

  @action
  public getCriteria = async (store: CrmStore) => {
    let originalEndpoint = this.endpoint;
    this.endpoint = new Endpoint('QuoteInquiry', '/api/activityFormHelper/Companies');
    const response = await this.get<Result<ActivityFormHelperResponseModel>>();
    this.endpoint = originalEndpoint;
    runInAction(() => {
      store.ActivityFormSearch.Cities.replace(response.data.Value.Cities);
      store.ActivityFormSearch.Companies.replace(response.data.Value.Companies);
    });
  };
}

export default ActivityFormHelperService;
