import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { useEffect, useState } from 'react';
dayjs.extend(durationPlugin);

interface TimerOptions {
  startTime: string;
  increment: boolean;
}

function initTimer(startTime: string, increment: boolean) {
  const start = dayjs(startTime);
  const now = dayjs();
  if (increment) {
    return dayjs.duration(now.diff(start));
  } else {
    return dayjs.duration(start.diff(now));
  }
}
export default function useTimer({ increment, startTime }: TimerOptions) {
  const [timer, setTimer] = useState(initTimer(startTime, increment));
  const [intervalId, setIntervalId] = useState(() => {
    return setInterval(() => {
      setTimer((prev) => {
        if (increment) {
          return prev.add(1, 'second');
        } else if (prev.asSeconds() > 0) {
          return prev.subtract(1, 'second');
        } else {
          return prev;
        }
      });
    }, 1000);
  });

  useEffect(() => {
    clearInterval(intervalId);
    setTimer(initTimer(startTime, increment));
    setIntervalId(
      setInterval(() => {
        setTimer((prev) => {
          if (increment) {
            return prev.add(1, 'second');
          } else if (prev.asSeconds() > 0) {
            return prev.subtract(1, 'second');
          } else {
            return prev;
          }
        });
      }, 1000)
    );
  }, [startTime]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return timer;
}
