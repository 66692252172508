import { makeObservable, observable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class AdditionalChargeModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
    this.IsEnabled = true; // default enabled.
  }
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public TravelTypeNumber: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  public IsEnabled: boolean;
}

export default AdditionalChargeModel;
