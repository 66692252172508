import dayjs from 'dayjs';
import { stringIsNullOrEmpty } from '../utils/helpers/stringHelpers';

const formatters = {
  momentDate: 'M/D/YYYY',
  momentDateTimeUSA: 'M/D/YYYY h:mm A',
  momentDateTimeUSANoTime: 'M/D/YYYY',
  momentDateNoYear: 'DD MMM',
  momentDayMonthYear: 'DD/MM/YYYY',
  momentShortMonthNameDayNoYear: 'MMM D',
  momentTime: ' h:mm A',
  momentDatePicker: 'DD-MMM-YYYY'
};

export const formatDate = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDate);
};

export const formatDateDatePicker = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDatePicker);
};

export const formatDateMonthDayYearTimeUSA = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  let dateForFormat = date;
  if (date.indexOf('Z') < 0) {
    dateForFormat = date + 'Z';
  }
  return dayjs(dateForFormat, formatters.momentDate).format(formatters.momentDateTimeUSA);
};

export const formatTravelportDateTime = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date).format(formatters.momentDateTimeUSA);
};

export const formatDayMonthYear = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDayMonthYear);
};

export const formatMonthNameDayShortYear = (date: string) => {
  return dayjs(date, formatters.momentDate).format(formatters.momentShortMonthNameDayNoYear);
};

export const formatDateMonthDayYearNoTimeUSA = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDateTimeUSANoTime);
};

export const formatStringDate = (date: string, format = 'D-MMM-YYYY', includeTimeZone = false) => {
  if (date == null || date === undefined) {
    return '';
  }
  if (date.endsWith('Z') && !includeTimeZone) {
    date = date.substring(0, date.length - 1);
  }
  const dayjsDate = dayjs(date);
  return dayjsDate.format(format);
};

export default formatters;
