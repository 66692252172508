import { action, observable, makeObservable } from 'mobx';
import { IPhoneNumberModel } from '../common/Interfaces/IPhoneNumberModel';
import FormModel from '../forms/FormModel';

class AgentQuerySearchModel extends FormModel implements IPhoneNumberModel {
  constructor() {
    super();
    makeObservable(this);
    this.Reset();
  }

  @observable
  // @ts-expect-error added by automation
  public PhoneNumber: string;

  @observable
  // @ts-expect-error added by automation
  public Text: string;
  @observable
  // @ts-expect-error added by automation
  public FirstName: string;
  @observable
  // @ts-expect-error added by automation
  public LastName: string;

  @observable
  // @ts-expect-error added by automation
  public ShowInactive: boolean;

  @action
  public Reset = () => {
    this.Text = '';
    this.PhoneNumber = '';
    this.FirstName = '';
    this.LastName = '';
    this.ShowInactive = false;
  };

  @action
  public Toggle = () => {
    this.ShowInactive = !this.ShowInactive;
  };
}
export default AgentQuerySearchModel;
