import { makeObservable, observable } from 'mobx';

import FormModel from './FormModel';

abstract class EntityFormModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public Id: number;
}

export default EntityFormModel;
