import { observer } from 'mobx-react-lite';
import * as React from 'react';
import AgentQuerySearchModel from '../../../../models/search/AgentQuerySearchModel';
import { StyledSearchInputDiv } from '../../../styled/baseStyledComponents';
import PhoneNumberField from '../common/general/PhoneNumberField';
import { IInputFieldProps } from '../common/IInputFieldProps';

interface ISearchPhoneNumberField extends IInputFieldProps {
  model: AgentQuerySearchModel;
  onEnter: () => void;
}

const SearchPhoneNumberField: React.FC<ISearchPhoneNumberField> = (props: ISearchPhoneNumberField) => {
  return (
    <StyledSearchInputDiv>
      <PhoneNumberField model={props.model} onEnter={props.onEnter} isRequired={false} />
    </StyledSearchInputDiv>
  );
};

export default observer(SearchPhoneNumberField);
