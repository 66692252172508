import Endpoint from './Endpoint';

const hub = (resource: string) => `/hubs/${resource}`;

const hubEndpoints = {
  activities: {
    CompanyActivitiesHub: new Endpoint('CompanyActivitiesHub', hub('CompanyActivitiesHub')),
    HotelActivitiesHub: new Endpoint('HotelActivitiesHub', hub('HotelActivitiesHub')),
    EmailActivitiesHub: new Endpoint('EmailActivitiesHub', hub('EmailActivitiesHub')),
    SingleEmailActivityHub: new Endpoint('SingleEmailActivityHub', hub('SingleEmailActivityHub'))
  },
  workflow: {
    WorkflowHub: new Endpoint('WorkflowHub', hub('WorkflowHub'))
  },
  system: {
    SystemRefresh: new Endpoint('SystemRefresh', hub('SystemRefreshHub'))
  }
};

export default hubEndpoints;
