import { makeObservable, observable } from 'mobx';
import { INotesModel } from '../common/Interfaces/INotesModel';
import EntityFormModel from '../forms/EntityFormModel';

class Announcement extends EntityFormModel implements INotesModel {
  @observable
  // @ts-expect-error added by automation
  public Notes: string;

  constructor() {
    super();

    makeObservable(this);
  }
}

export default Announcement;
