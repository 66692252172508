import { observable, makeObservable } from 'mobx';
import { IFileModel } from '../activities/IFileModelInterface';
import { INotesModel } from '../common/Interfaces/INotesModel';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';

export class CompanyPaymentInformationModel extends TimeSeriesEntityFormModel implements INotesModel, IFileModel {
  public constructor(defaultDatesToStartAndEndOfYear: boolean = false) {
    super(defaultDatesToStartAndEndOfYear);
    makeObservable(this);
  }

  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public Notes: string;
  @observable
  // @ts-expect-error added by automation
  public NewlyCreated: boolean;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
}
