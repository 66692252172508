import { DetailsRow, getTheme, IDetailsListProps, IDetailsRowStyles } from '@fluentui/react';
const theme2 = getTheme();

export const onStyledDetailListRow: IDetailsListProps['onRenderRow'] = (props) => {
  const customStyles: Partial<IDetailsRowStyles> = {};
  if (props) {
    if (props.itemIndex % 2 === 0) {
      // Every other row renders with a different background color
      customStyles.root = { backgroundColor: theme2.palette.neutralLight };
    }

    return <DetailsRow {...props} styles={customStyles} />;
  }
  return null;
};

export const onStyledClickableDetailListRow: IDetailsListProps['onRenderRow'] = (props) => {
  const customStyles: Partial<IDetailsRowStyles> = {};
  if (props) {
    customStyles.root = { cursor: 'pointer' };
    if (props.itemIndex % 2 === 0) {
      // Every other row renders with a different background color
      customStyles.root = { backgroundColor: theme2.palette.neutralLight, cursor: 'pointer' };
    }

    return <DetailsRow {...props} styles={customStyles} />;
  }
  return null;
};
