import { observer } from 'mobx-react-lite';
import * as React from 'react';
import HotelBookingQuoteInquiryModel from '../../../../models/QuoteInquiry/HotelBookingQuoteInquiryModel';
import InputDefinitionBuilder from '../../../../utils/inputDefinitions/InputDefinitionBuilder';
import StandardTextBoxField from '../../textbox/StandardTextBoxField';
import { IInputFieldProps } from '../common/IInputFieldProps';

interface IActivityDisplayPriorityNotesField extends IInputFieldProps {
  model: HotelBookingQuoteInquiryModel;
  multiline?: boolean;
}

const ActivityDisplayPriorityNotesField: React.FC<IActivityDisplayPriorityNotesField> = (
  props: IActivityDisplayPriorityNotesField
) => {
  const [inputDefinition] = React.useState(
    new InputDefinitionBuilder('Comments').WithPlaceholder('Enter Comments').Build()
  );

  return (
    <StandardTextBoxField
      name='PriorityNote'
      value={props.model.PriorityNote}
      onModelChange={props.model.OnModelChange}
      inputDefinition={inputDefinition}
      disabled={props.disabled}
      multiline={props.multiline}
    />
  );
};

export default observer(ActivityDisplayPriorityNotesField);
