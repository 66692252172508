import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { arraySetData } from '../../utils/helpers/arrayHelpers';
import { stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import EntityFormModel from '../forms/EntityFormModel';
import ReportingRequirementOptionModel from './ReportingRequirementOptionModel';

class ReportingRequirement extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public TravelTypeNumber: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  // @ts-expect-error added by automation
  public Comment: string;
  @observable
  // @ts-expect-error added by automation
  public Example: string;
  @observable
  // @ts-expect-error added by automation
  public FrontierOnlineExample: string;
  @observable
  // @ts-expect-error added by automation
  public UDID: string;
  @observable
  // @ts-expect-error added by automation
  public Value: string;
  @observable
  // @ts-expect-error added by automation
  public Required: boolean;
  @observable
  // @ts-expect-error added by automation
  public Mandatory: boolean;
  @observable
  // @ts-expect-error added by automation
  public NonStaticValue: boolean;
  @observable
  // @ts-expect-error added by automation
  public IsForContact: boolean;
  @observable
  // @ts-expect-error added by automation
  public CoId: number;
  @observable
  // @ts-expect-error added by automation
  public IsActive: boolean;
  @observable
  // @ts-expect-error added by automation
  public HasOptions: boolean;
  @observable
  public Options: IObservableArray<ReportingRequirementOptionModel>;

  constructor() {
    super();
    makeObservable(this);
    this.Options = observable.array<ReportingRequirementOptionModel>([]);
  }

  @computed
  public get DisplayString() {
    return `${this.UDID} - ${this.Name}: ${this.Value}`;
  }

  @computed
  public get DisplayReport() {
    let Comment = !stringIsNullOrEmpty(this.Comment) ? ` - ${this.Comment}` : '';
    let UDID = !stringIsNullOrEmpty(this.UDID) ? ` - UDID: ${this.UDID}` : '';
    let string = this.Description + Comment + UDID;
    return string;
  }

  @computed
  public get FindMaxValue() {
    let maxValue = 0;
    if (this.Options.length === 0) {
      return maxValue + 1;
    }
    this.Options.forEach((option) => {
      if (option.Value > maxValue) {
        maxValue = option.Value;
      }
    });
    return maxValue + 1;
  }

  @computed
  public get GroupedRequirements(): { [key: number]: ReportingRequirementOptionModel[] } {
    const result: { [key: number]: ReportingRequirementOptionModel[] } = this.Options.reduce((acc, myObject) => {
      const value = myObject.Value;
      // @ts-expect-error added by automation
      if (acc[value]) {
        // @ts-expect-error added by automation
        acc[value].push(myObject);
      } else {
        // @ts-expect-error added by automation
        acc[value] = [myObject];
      }
      return acc;
    }, {});

    return result;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['Options']);
    arraySetData(this.Options, ReportingRequirementOptionModel, dbData.Options);
  }

  @action
  public FindMaxOrder(value: number) {
    let maxValue = 0;
    let filteredOptions = this.Options.filter((option) => option.Value === value);
    filteredOptions.forEach((option) => {
      if (option.Order > maxValue) {
        maxValue = option.Order;
      }
    });
    return maxValue + 1;
  }
}

export default ReportingRequirement;
