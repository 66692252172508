import { defineStyleConfig } from '@chakra-ui/react';
import { defineStyle } from '@chakra-ui/styled-system';

const frontierInput = defineStyle({
  background: 'white',
  borderRadius: '4px',
  border: '1px solid #969696',
  marginBottom: '4px',
  height: '35px',
  padding: '12px',
  _hover: {
    borderColor: '#c0bfbf'
  },
  _focus: {
    borderColor: '#1054c0',
    backgroundColor: '#fafafa'
  },
  _invalid: {
    border: '1px dashed red'
  },
  _readOnly: {
    opacity: '0.5'
  }
});

export const textAreaTheme = defineStyleConfig({
  variants: {
    frontierInput
  },
  defaultProps: {
    variant: 'frontierInput'
  }
});
