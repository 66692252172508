import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import applicationRoutes from 'src/constants/applicationRoutes';
import NarrowLayout from 'src/containers/layout/NarrowLayout';

export const UserNavItems: NavItem[] = [
  { text: 'Manage Account', path: applicationRoutes.account.myProfile },
  { text: 'Logout', path: applicationRoutes.account.logout }
];

//not rendered in the nav bar (no Text)
export const AccountManagementItems: NavItem = {
  path: '/',
  layout: <NarrowLayout />,
  subItems: [
    {
      path: applicationRoutes.account.resetPassword,
      async lazy() {
        let component = await import('src-new/features/auth/ResetPassword');
        return { Component: component.ResetPasswordPage };
      }
    },
    {
      path: applicationRoutes.account.forgotPassword,
      async lazy() {
        let component = await import('src-new/features/auth/ForgotPassword');
        return { Component: component.ForgotPasswordPage };
      }
    },
    {
      path: applicationRoutes.account.logout,
      async lazy() {
        let component = await import('src-new/features/auth/Logout');
        return { Component: component.Logout };
      }
    }
  ]
};

export const LoginRouteItem: NavItem = {
  text: 'Login',
  path: applicationRoutes.account.login,
  layout: <NarrowLayout />,
  async lazy() {
    let component = await import('src-new/features/auth/Login');
    return { Component: component.LoginPage };
  }
};

export const MyAccountItem = {
  layout: <NarrowLayout />,
  path: applicationRoutes.account.myProfile,
  async lazy() {
    let component = await import('src-new/features/myProfile/MyProfile');
    return { Component: component.MyProfile };
  }
};

export const ThirdPartyPaymentDetailsItem: NavItem = {
  text: 'Third-Party Payment Details Viewer',
  path: applicationRoutes.account.thirdPartyPaymentDetailsViewer,
  layout: <NarrowLayout />,
  async lazy() {
    let component = await import('src-new/features/thirdPartyPaymentDetailsViewer/ThirdPartyPaymentDetailsViewer');
    return { Component: component.ThirdPartyPaymentDetailsViewer };
  }
};
