import { computed, makeObservable } from 'mobx';
import ActivityStatus from 'src/enums/activities/ActivityStatus';
import MyActivityModel from 'src/models/activities/MyActivityModel';
import ActivityAlertLevel from '../../enums/activities/ActivityAlertLevel';
import { ActivitySort } from '../../enums/activities/ActivitySort';
import ActivityPagingModel from '../../models/activities/ActivityPagingModel';
import ActivitySortFilterModel from '../../models/activities/ActivitySortFilterModel';
import { getDateObjectByName, sortModelsByDate } from '../helpers/dateHelpers';
import { partition } from 'lodash';

class MyActivitiesSortProvider {
  public ActivitySortFilterModel: ActivitySortFilterModel;
  private pagingModel: ActivityPagingModel;

  public constructor(pagingModel: ActivityPagingModel) {
    this.pagingModel = pagingModel;
    makeObservable(this);
    this.ActivitySortFilterModel = new ActivitySortFilterModel();
  }

  @computed
  public get SortedMyActivities() {
    let sorted: Array<MyActivityModel>;

    switch (this.ActivitySortFilterModel.ActivitySort) {
      case ActivitySort.DueDate:
        sorted = this.sortActivitiesByDueDate(this.pagingModel.MyActivities);
        break;
      case ActivitySort.Priority:
        sorted = this.sortActivitiesByPriority(this.pagingModel.MyActivities);
        break;
      case ActivitySort.CreatedOn:
        sorted = this.sortActivitiesByCreatedOn(this.pagingModel.MyActivities);
        break;
      case ActivitySort.AssignedUser:
        sorted = this.sortActivitiesByAssigned(this.pagingModel.MyActivities);
        break;
      case ActivitySort.From:
        sorted = this.sortActivitiesByFrom(this.pagingModel.MyActivities);
        break;
      case ActivitySort.Subject:
        sorted = this.sortActivitiesBySubject(this.pagingModel.MyActivities);
        break;
      // case ActivitySort.ClientName:
      //   sorted = this.sortActivitiesByCustomerName(activities);
      //   break;
      default:
        sorted = [];
    }

    const [holding, noneHolding] = partition(sorted!, (r) => r.Status == ActivityStatus.Holding);
    if (this.ActivitySortFilterModel.SortAscending) {
      return noneHolding.reverse().concat(holding.reverse());
    }
    return noneHolding.concat(holding);
  }

  private sortActivitiesByCreatedOn = (array: Array<MyActivityModel>, sortDescending: boolean = false) => {
    const specialStatuses = [ActivityStatus.Waiting, ActivityStatus.Holding];
    return array.slice().sort((a, b) => {
      if (a.NewReply || b.NewReply) {
        // If either a or b has isNewReply true, they are excluded from sorting
        return 0;
      }

      if (a.Status === ActivityStatus.Holding && b.Status === ActivityStatus.Waiting) {
        return 1; // Sort "Holding" lower than "Waiting"
      } else if (a.Status === ActivityStatus.Waiting && b.Status === ActivityStatus.Holding) {
        return -1; // Sort "Waiting" higher than "Holding"
      } else if (specialStatuses.includes(a.Status)) {
        return 1;
      } else if (specialStatuses.includes(b.Status)) {
        return -1;
      } else if (a.CreatedOn == null && b.CreatedOn == null) {
        return 0;
      } else if (a.CreatedOn == null) {
        return sortDescending ? -1 : 1;
      } else if (b.CreatedOn == null) {
        return sortDescending ? 1 : -1;
      }
      const aDate = getDateObjectByName(a, 'CreatedOn');
      const bDate = getDateObjectByName(b, 'CreatedOn');
      if (sortDescending) {
        // @ts-expect-error added by automation
        return bDate.diff(aDate);
      }
      // @ts-expect-error added by automation
      return aDate.diff(bDate);
    });
  };

  private sortActivitiesByAssigned = (array: Array<MyActivityModel>) => {
    return array.slice().sort(
      (a, b) =>
        this.sortByUserName(a, b, 'AssignedUserName') ||
        //sortModelsByDate(a, b, 'LatestEmailDate', true) ||
        sortModelsByDate(a, b, 'CreatedOnMoment', true) ||
        sortModelsByDate(a, b, 'DueDateMoment')
    );
  };

  private sortActivitiesByFrom = (array: Array<MyActivityModel>) => {
    return array.slice().sort(function (a, b) {
      let aStr = a.Notes[0].ClientEmail.From.charAt(0);
      let bStr = b.Notes[0].ClientEmail.From.charAt(0);
      return aStr.localeCompare(bStr);
    });
  };

  private sortActivitiesBySubject = (array: Array<MyActivityModel>, sortDescending: boolean = false) => {
    return array.slice().sort(function (a, b) {
      let aStr = a.Notes[0].ClientEmail.Subject.charAt(0);
      let bStr = b.Notes[0].ClientEmail.Subject.charAt(0);
      if (aStr == null && bStr == null) {
        return 0;
      }
      if (aStr == null) {
        return sortDescending ? -1 : 1;
      }
      if (bStr == null) {
        return sortDescending ? 1 : -1;
      }
      return aStr.localeCompare(bStr);
    });
  };

  private sortActivitiesByDueDate = (array: Array<MyActivityModel>) => {
    return array
      .slice()
      .sort(
        (a, b) =>
          sortModelsByDate(a, b, 'DueDateMoment') ||
          sortModelsByDate(a, b, 'LatestEmailDate', true) ||
          sortModelsByDate(a, b, 'CreatedOnMoment', true)
      );
  };
  //TODO:MARC Remove waiting for payment from this once payment email automerge is tested
  //ActivityStatus.WaitingForPayment
  private sortActivitiesByPriority = (array: Array<MyActivityModel>) => {
    const order = [
      ActivityAlertLevel.None,
      ActivityAlertLevel.Upcoming,
      ActivityAlertLevel.Tomorrow,
      ActivityAlertLevel.Today,
      ActivityAlertLevel.Urgent
    ];
    const specialStatuses = [ActivityStatus.Waiting, ActivityStatus.Holding];
    return array.slice().sort((a, b) => {
      if (specialStatuses.includes(a.Status) && !a.NewReply) {
        return 1;
      } else if (specialStatuses.includes(b.Status) && !b.NewReply) {
        return -1;
      } else {
        const indexA = order.indexOf(a.AlertLevel);
        const indexB = order.indexOf(b.AlertLevel);
        return indexA - indexB; // Sort based on AlertLevel
      }
    });
  };

  private sortByUserName = <T extends keyof MyActivityModel>(
    a: MyActivityModel,
    b: MyActivityModel,
    propertyName: T,
    sortDescending: boolean = false
    // @ts-expect-error added by automation
  ): number => {
    if (a[propertyName] == null && b[propertyName] == null) {
      return 0;
    }
    if (a[propertyName] == null) {
      return sortDescending ? -1 : 1;
    }
    if (b[propertyName] == null) {
      return sortDescending ? 1 : -1;
    }
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
  };

  private sortByAlertLevel = <T extends keyof MyActivityModel>(
    a: MyActivityModel,
    b: MyActivityModel,
    propertyName: T,
    sortDescending: boolean = false
  ): number => {
    if (a[propertyName] == ActivityAlertLevel.Today && b[propertyName] == ActivityAlertLevel.Upcoming) {
      return 0;
    }
    if (a[propertyName] == ActivityAlertLevel.Upcoming && b[propertyName] == ActivityAlertLevel.Today) {
      return 0;
    }
    if (a[propertyName] == null && b[propertyName] == null) {
      return 0;
    }
    if (a[propertyName] == null) {
      return sortDescending ? -1 : 1;
    }
    if (b[propertyName] == null) {
      return sortDescending ? 1 : -1;
    }
    return b.AlertLevel - a.AlertLevel;
  };
}

export default MyActivitiesSortProvider;
