import { makeObservable, observable } from 'mobx';
import ConvertProvince from '../../common/ConvertProvince';
import FormModel from '../forms/FormModel';
import { IProvinceState } from './Interfaces/IProvinceState';

export class Address extends FormModel implements IProvinceState {
  @observable
  // @ts-expect-error added by automation
  public AddressLineOne: string;
  @observable
  // @ts-expect-error added by automation
  public AddressLineTwo: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;
  @observable
  // @ts-expect-error added by automation
  public Quadrant: string;
  @observable
  // @ts-expect-error added by automation
  public ProvinceState: string;
  @observable
  // @ts-expect-error added by automation
  public PostalZipCode: string;
  @observable
  // @ts-expect-error added by automation
  public Country: string;
  @observable
  // @ts-expect-error added by automation
  public TemporaryCity: string;

  @observable
  public CountryId: number | undefined;

  @observable
  public ProvinceId: number | undefined;

  @observable
  public CityId: number | undefined;

  @observable
  public NewCity: string | undefined;

  constructor() {
    super();

    makeObservable(this);
  }

  public GetAddressString(): string {
    let toReturn = this.AddressLineOne;
    if (this.AddressLineTwo != null) {
      toReturn += this.AddressLineTwo;
    }
    return toReturn;
  }

  public GetCityProvPostal(): string {
    return `${this.City} ${ConvertProvince(this.ProvinceState)} ${this.PostalZipCode}`;
  }

  public GetFullAddressString(): string {
    return `${this.GetAddressString()} ${this.GetCityProvPostal()}`;
  }
}
