import { UserStatus } from 'src/enums/common/UserStatus';

export enum WorkflowJoinStatus {
  None = 0,
  Prompt = 1,
  Spectate = 2,
  Join = 3
}
export enum WorkflowGroupActionType {
  None = 0,
  Assignment = 1,
  Status = 2,
  Ready = 3,
  Assigned = 4
}
export interface WorkflowInitialJoinModel {
  StartDate: string;
  EndDate: string;
  IsInboxManager: boolean;
  JoinWorkflowSystem: boolean;
  WorkflowGroupId: number;
}
export interface WorkflowGroupModel {
  InboxManager?: string;
  InboxManagerId?: string;
  Actions: Array<WorkflowGroupActionModel>;
  CurrentUser: WorkflowGroupUserModel;
  Users: Array<WorkflowGroupUserModel>;
  WorkflowGroupId: number;
}
export interface WorkflowGroupUserModel {
  UserId: string;
  Name: string;
  ActionTotal: Array<number>;
  CurrentStatus: WorkflowGroupCurrentStatusModel;
  WorkingHours: WorkflowGroupWorkingHoursModel;
  BubbleColor: string;
}
export interface WorkflowGroupActionModel {
  ActionName: string;
  Id: number;
  ActionAbbreviation: string;
  RequiresStatusMessage: boolean;
  SortOrder: number;
  Status: UserStatus; //enum
  Type: WorkflowGroupActionType; //enum
  ColorHexCode: string;
}
export interface WorkflowGroupCurrentStatusModel {
  AssignedAction: string;
  AssignedActionId: number;
  AssignedActionType: WorkflowGroupActionType;
  Status: UserStatus;
  StatusChangedOn: string;
  StatusMessage: string;
  StatusReturnTime: string;
}
type WorkflowGroupWorkingHoursModel = {
  StartDate: string;
  EndDate: string;
};
