import { observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class QuoteInquirySelectedPdfModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public FileId: number;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public IsFolio: boolean;
}
export default QuoteInquirySelectedPdfModel;
