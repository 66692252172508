import { observable, makeObservable, computed } from 'mobx';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import dayjs from 'dayjs';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';

class DailyAverageRateModel extends TimeSeriesEntityFormModel {
  public constructor(startDate?: string, endDate?: string) {
    super();
    makeObservable(this);
    // @ts-expect-error added by automation
    this.StartDate = startDate;
    // @ts-expect-error added by automation
    this.EndDate = endDate;
    this.Rate = 0;
  }

  @observable
  public Rate: number;
  @observable
  // @ts-expect-error added by automation
  public NumberOfDays: number;

  @computed
  public get TotalNights() {
    if (stringIsNullOrEmpty(this.EndDate) || stringIsNullOrEmpty(this.StartDate)) return 0;
    const startDate = dayjs(this.StartDate);
    const endDate = dayjs(this.EndDate);
    this.NumberOfDays = endDate.diff(startDate, 'days');
    return this.NumberOfDays;
  }
}

export default DailyAverageRateModel;
