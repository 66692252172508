import { Box, useDisclosure } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import WorkflowTool from 'src-new/features/WorkflowTool/WorkflowTool';
import { NavBar } from 'src-new/features/header/NavBar';
import { useLayoutStore } from 'src-new/stores/layoutStore';
import Callout from 'src/components/callout/Callout';
import Modal from 'src/components/dialog/Modal';
import OverlayLoadingSpinner from '../loadingSpinner/OverlayLoadingSpinner';

const Layout = () => {
  const auth = useAuth();
  const joinWorkflowDisclosure = useDisclosure();
  const [isInWorkflow, setIsInWorkflow] = useState(false);
  const layoutStore = useLayoutStore();
  return (
    <Box paddingBottom={'10px'}>
      <NavBar joinWorkflowDisclosure={joinWorkflowDisclosure} isInWorkflow={isInWorkflow} />
      {auth.isAuthenticated && (
        <WorkflowTool
          disclosure={joinWorkflowDisclosure}
          setIsInWorkflow={setIsInWorkflow}
          {...joinWorkflowDisclosure}
        />
      )}
      <Box width={'100%'} p={1}>
        <Outlet />
      </Box>
      <Callout />
      <Modal />
      <OverlayLoadingSpinner />
      {layoutStore.isDataDisplayOpen && <Box paddingBottom={'35vh'}></Box>}
      {layoutStore.isExpanderRendered && <Box paddingBottom={'35px'}></Box>}
    </Box>
  );
};
export default Layout;
