import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardBody, CardHeader } from 'reactstrap';
import FaIconWithToolTip from '../../components/common/FaIconWithToolTip';
import PaddedCard from '../../components/common/PaddedCard';
import { PreWrapTextDiv } from '../../components/common/PreWrapTextDiv';
import { ApplicationRoles } from '../../constants/applicationRoles';
import applicationRoutes from '../../constants/applicationRoutes';
import AgentQuerySearchBranchOfficeResultModel from '../../models/search/AgentQuerySearchBranchOfficeResultModel';
import { useUserStore, useUtilityStore } from '../../stores/useMobxStores';
import { formatPhoneNumber } from '../../utils/helpers/crmHelpers';
import { onStyledDetailListRow } from '../../utils/styled/styledDetailListRow';
import { BranchOfficeAgentColumns, BranchOfficecolumns } from './searchcolumns';

interface IBranchOfficeResultTable {
  branchOffices: Array<AgentQuerySearchBranchOfficeResultModel>;
}

const BranchOfficeResultTable: React.FC<IBranchOfficeResultTable> = (props: IBranchOfficeResultTable) => {
  const userStore = useUserStore();
  const utilStore = useUtilityStore();
  const user = userStore.LazyUserModel.current();
  const navigate = useNavigate();

  const onNavigate = (id: number) => {
    navigate(applicationRoutes.crm.company.edit + `/${id}`);
    utilStore.modalState.close();
  };

  const renderBranchOffice = (item: AgentQuerySearchBranchOfficeResultModel, index: number, column: IColumn) => {
    switch (column.key) {
      case 'CompanyName':
        return <PreWrapTextDiv>{item[column.key]}</PreWrapTextDiv>;
      case 'PhoneNumber':
      case 'FaxNumber':
        return formatPhoneNumber(item.BranchOffice[column.key]);
      case 'Actions':
        return (
          <FaIconWithToolTip
            tooltip={'Go to Company Page'}
            iconName={'fa-pen'}
            clickable
            onClick={() => onNavigate(item.CompanyId)}
          />
        );
      default:
        // @ts-expect-error added by automation
        return <PreWrapTextDiv>{item.BranchOffice[column.key]}</PreWrapTextDiv>;
    }
  };

  return (
    <div>
      {props.branchOffices?.length > 0 && (
        <PaddedCard>
          <CardHeader>Branch Offices</CardHeader>
          <CardBody>
            <DetailsList
              items={props.branchOffices}
              columns={user?.IsInRole([ApplicationRoles.crm]) ? BranchOfficecolumns : BranchOfficeAgentColumns}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              onRenderRow={onStyledDetailListRow}
              // @ts-expect-error added by automation
              onRenderItemColumn={renderBranchOffice}
            />
          </CardBody>
        </PaddedCard>
      )}
    </div>
  );
};
export default observer(BranchOfficeResultTable);
