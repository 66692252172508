import { computed, IObservableArray, observable, makeObservable } from 'mobx';
import { CompanyModel } from '../companies/CompanyModel';
import { Hotel } from '../hotel/Hotel';
import AgentQueryNotesWrapper from './AgentQueryNotesWrapper';

class AgentQueryResponseModel {
  constructor() {
    makeObservable(this);
    this.Hotels = observable.array(new Array<Hotel>());
    this.NearbyCityHotels = observable.array(new Array<Hotel>());
    this.ProjectHotels = observable.array(new Array<Hotel>());
    this.CompanyProjectNotes = observable.array(new Array<AgentQueryNotesWrapper>());
    this.HotelNotes = observable.array(new Array<AgentQueryNotesWrapper>());
    this.CompanyModel = new CompanyModel();
    this.CompanyNotes = new AgentQueryNotesWrapper();
  }

  @observable
  public Hotels: IObservableArray<Hotel>;
  @observable
  public NearbyCityHotels: IObservableArray<Hotel>;
  @observable
  public ProjectHotels: IObservableArray<Hotel>;
  @observable
  public CompanyNotes: AgentQueryNotesWrapper;
  @observable
  public CompanyProjectNotes: IObservableArray<AgentQueryNotesWrapper>;
  @observable
  public HotelNotes: IObservableArray<AgentQueryNotesWrapper>;
  @observable
  public CompanyModel: CompanyModel;

  @computed
  public get AllHotels() {
    return this.Hotels.concat(this.NearbyCityHotels);
  }
}
export default AgentQueryResponseModel;
