import { action, IObservableArray, makeObservable, observable } from 'mobx';
import ActivityModel from '../../models/activities/ActivityModel';
import Endpoint from '../../services/Common/Endpoint';
import { isNullOrUndefined } from '../../utils/helpers/typeGuards';
import hubActions from '../hubActions';
import HubBase from '../HubBase';

abstract class ActivitiesHubBase extends HubBase {
  @observable
  public activities: IObservableArray<ActivityModel>;

  private entityId?: number;
  @observable
  public hasLoaded: boolean = false;

  public constructor(entityId: number, endpoint: Endpoint) {
    super(endpoint);
    makeObservable(this);
    this.entityId = entityId;
    this.activities = observable.array(new Array<ActivityModel>());

    this.setupHub();
    this.startConnection().then(() => {
      this.connection.invoke(hubActions.activities.AddToGroup, this.entityId).catch(this.errorHandler);
      this.connection.invoke(hubActions.activities.InitialLoad, this.entityId).catch(this.errorHandler);
    });
  }

  private setupHub = () => {
    this.connection.on(hubActions.activities.InitialLoad, this.initialLoad);

    this.connection.on(hubActions.activities.UpdateActivity, this.addOrUpdateActivity);
  };

  @action
  private initialLoad = (data: Array<ActivityModel>) => {
    const array = new Array<ActivityModel>();
    for (const index in data) {
      const am = new ActivityModel();
      am.SetData(data[index]);
      array.push(am);
    }
    this.activities.replace(array);
    this.hasLoaded = true;
  };

  @action
  private addOrUpdateActivity = (model: ActivityModel) => {
    const am = new ActivityModel();
    am.SetData(model);

    const foundIndex = this.activities.findIndex((x) => x.Id === am.Id);

    if (foundIndex !== -1) {
      this.activities[foundIndex].SetData(model);
    } else {
      this.activities.push(am);
    }
  };

  public removeFromGroupAndClose = () => {
    if (this.connection.state !== 'Connected') {
      return;
    }
    if (isNullOrUndefined(this.entityId)) {
      this.connection
        .invoke(hubActions.activities.RemoveFromGroup)
        .then(() => this.stopConnection())
        .catch(this.errorHandler);
    } else {
      this.connection
        .invoke(hubActions.activities.RemoveFromGroup, this.entityId)
        .then(() => this.stopConnection())
        .catch(this.errorHandler);
    }
  };
}

export default ActivitiesHubBase;
