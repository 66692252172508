import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const variants = {
  viewCardImagesModal: definePartsStyle({
    dialogContainer: {
      borderRadius: '16px'
    },
    header: {
      borderBottomWidth: '1px'
    },
    footer: {
      borderTopWidth: '1px'
    }
  })
};

const lg = defineStyle({
  maxH: '90vh',
  minW: '40vw',
  maxW: '500px'
});

const xl = defineStyle({
  maxH: '90vh',
  minW: '70vw',
  maxW: '1200px'
});

const xxl = defineStyle({
  maxH: '90vh',
  minW: '90vw',
  maxW: '1000px'
});

const header = defineStyle({
  paddingBottom: '4px'
});

const sizes = {
  xl: definePartsStyle({
    dialog: xl,
    header: header
  }),
  xxl: definePartsStyle({
    dialog: xxl,
    header: header
  }),
  lg: definePartsStyle({
    dialog: lg,
    header: header
  })
};

export const modalTheme = defineMultiStyleConfig({
  variants,
  sizes,
  defaultProps: {
    size: 'xl'
  }
});
