import { observer } from 'mobx-react-lite';
import React from 'react';
import ConfirmationCallout from '../../../../components/callout/ConfirmationCallout';
import ActivitySetFolioEmailField from '../../../../components/forms/inputfields/activities/ActivitySetFolioEmailField';
import ActivitySetFolioSubjectField from '../../../../components/forms/inputfields/activities/ActivitySetFolioSubjectField';
import ActivityContextMenuDefinitionModel from '../../../../models/activities/ActivityContextMenuDefinitionModel';
import ActivitySetAsFolioModel from '../../../../models/QuoteInquiry/ActivitySetAsFolioModel';
import ActivityFolioManagementService from '../../../../services/Activities/ActivityFolioManagementService';
import { PaddedBox } from '../../EditActivity/styledComponents';

interface IInboxContextMenuSetFolioCallout {
  model: ActivityContextMenuDefinitionModel;
}

const InboxContextMenuSetFolioCallout: React.FC<IInboxContextMenuSetFolioCallout> = (
  props: IInboxContextMenuSetFolioCallout
) => {
  const [postModel, setPostModel] = React.useState(new ActivitySetAsFolioModel());
  const service = new ActivityFolioManagementService();
  const onSubmit = () => {
    service.addToFolioList(postModel);
  };
  React.useEffect(() => {
    const model = new ActivitySetAsFolioModel();
    model.Subject = props.model.Subject;
    model.Email = props.model.From;
    setPostModel(model);
  }, []);

  return (
    <ConfirmationCallout okCallback={onSubmit} header={"Add to Folio's Rules List"}>
      <PaddedBox>
        <ActivitySetFolioEmailField model={postModel} />
        <ActivitySetFolioSubjectField model={postModel} />
      </PaddedBox>
    </ConfirmationCallout>
  );
};

export default observer(InboxContextMenuSetFolioCallout);
