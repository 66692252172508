import dayjs from 'dayjs';
import { computed, observable, makeObservable } from 'mobx';
import formatters from '../../common/formatters';
import FormModel from '../forms/FormModel';

class AuditModel extends FormModel {
  public constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public ColumnName: string;
  @observable
  // @ts-expect-error added by automation
  public OriginalValue: string;
  @observable
  // @ts-expect-error added by automation
  public NewValue: string;

  @computed
  public get CreatedInformation(): string {
    return `${this.CreatedBy} - ${dayjs.utc(this.CreatedOn).local().format(formatters.momentDateTimeUSA)}`;
  }
}

export default AuditModel;
