import React from 'react';
import ModalWithButtons from './ModalWithButtons';

interface IModalGenericConfirm {
  header: string;
  okCallback?: () => void;
  cancelCallback?: () => void;
  okayButtonText?: string;
  closeButtonText?: string;
}

const ModalGenericConfirm: React.FunctionComponent<IModalGenericConfirm> = (props: IModalGenericConfirm) => {
  return (
    <ModalWithButtons
      header={props.header}
      showCancelButton={true}
      cancelCallback={props.cancelCallback}
      okCallback={props.okCallback}
      okButtonText={props.okayButtonText ? props.okayButtonText : 'Okay'}
      cancelButtonText={props.closeButtonText ? props.closeButtonText : 'Cancel'}></ModalWithButtons>
  );
};

export default ModalGenericConfirm;
