enum ActivityStatus {
  None = 0,
  AgentNote = 1,
  Priority = 2,
  Urgent = 3,
  Open = 4,
  Waiting = 5,
  Cancelled = 6,
  Delete = 7,
  BookingComplete = 8,
  Reconciled = 9,
  Folio = 10,
  PreReconciliation = 11,
  IssueReconciliation = 12,
  //Merged is used for internal purpose only - i.e. when an activity is split and the original is no longer valid. Do not uncomment
  Merged = 13,
  Archive = 14,
  CheckedOut = 15,
  Holding = 16,
}
//WaitingForPayment = 17

export default ActivityStatus;
