import { action, makeObservable, runInAction } from 'mobx';
import { getAxios } from '../../../lib/axios';
import { Hotel } from '../../../models/hotel/Hotel';
import HotelManagementQueryModel from '../../../models/hotel/HotelManagementQueryModel';
import CrmStore from '../../../stores/crmStore';
import { downloadObjectBlob } from '../../../utils/helpers/fileHelper';
import { getFormattedUrl } from '../../../utils/helpers/stringHelpers';
import Endpoint from '../../Common/Endpoint';
import endpoints from '../../Common/endpoints';
import ServiceBase from '../../Common/ServiceBase';

class HotelListService extends ServiceBase {
  constructor() {
    super(endpoints.crm.hotelSearch);
    makeObservable(this);
  }

  @action
  public getHotels = async (store: CrmStore) => {
    const response = await this.post<Array<Hotel>>(null, store.HotelQueryModel);
    runInAction(() => {
      const list = new Array<Hotel>();
      response.data.forEach((x) => {
        const hotel = new Hotel();
        hotel.SetData(x);
        list.push(hotel);
      });

      store.Hotels.replace(list);
    });
  };

  @action
  public getHotelExpandedSearch = async (store: CrmStore) => {
    const originalEndpoint = this.endpoint;
    this.endpoint = new Endpoint('ExpandeSearch', '/api/Hotel/ExpandedSearch');
    const response = await this.post<Array<Hotel>>(null, store.HotelExpandedQueryModel);
    runInAction(() => {
      const list = new Array<Hotel>();
      response.data.forEach((x) => {
        const hotel = new Hotel();
        hotel.SetData(x);
        list.push(hotel);
      });

      store.Hotels.replace(list);
    });
    this.endpoint = originalEndpoint;
  };

  public exportToExcel = async (hotelQueryModel: HotelManagementQueryModel) => {
    const endpoint = new Endpoint('ExportToExcel', '/api/Hotel/ExportToExcel');
    const transformedUrl = getFormattedUrl(endpoint);
    await getAxios()
      .post(transformedUrl, hotelQueryModel, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      })
      .then(async (response) => {
        await downloadObjectBlob(new Blob([response.data]), 'hotels.xlsx');
      });
  };
}

export default HotelListService;
