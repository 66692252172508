import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { masks } from '../../../../../constants/masks';
import { IPhoneNumberModel } from '../../../../../models/common/Interfaces/IPhoneNumberModel';
import FormModel from '../../../../../models/forms/FormModel';
import InputDefinitionBuilder from '../../../../../utils/inputDefinitions/InputDefinitionBuilder';
import StandardTextBoxField from '../../../textbox/StandardTextBoxField';
import { IInputFieldProps } from '../IInputFieldProps';

interface IPhoneNumberField extends IInputFieldProps {
  model: IPhoneNumberModel & FormModel;
  overrideName?: string;
  overrideLabel?: string;
  onEnter?: () => void;
}

const PhoneNumberField: React.FC<IPhoneNumberField> = (props: IPhoneNumberField) => {
  const builder = new InputDefinitionBuilder(props.overrideLabel ? props.overrideLabel : 'Phone Number').WithMask(
    masks.phone
  );
  if (props.isRequired !== false) {
    builder.WithRequiredTextBoxField();
  }
  const name = props.overrideName ?? 'PhoneNumber';
  return (
    <StandardTextBoxField
      name='PhoneNumber'
      overrideName={name}
      value={props.model.PhoneNumber}
      onModelChange={props.model.OnModelChange}
      inputDefinition={builder.Build()}
      onEnter={props.onEnter}
    />
  );
};

export default observer(PhoneNumberField);
