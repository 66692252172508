import AppSettings from '../../models/settings/AppSettings';
import ServiceBase from '../Common/ServiceBase';
import endpoints from '../Common/endpoints';

class AppSettingsService extends ServiceBase {
  private settingsCache: AppSettings;

  constructor() {
    super(endpoints.appSettings);
    // @ts-expect-error added by automation
    this.settingsCache = null;
  }

  public getAppSettings = async () => {
    if (this.settingsCache === null) {
      const result = await this.get<AppSettings>();
      this.settingsCache = result.data;
    }
    return this.settingsCache;
  };
}

export default new AppSettingsService();
