import { QueryClient } from '@tanstack/react-query';
import { WorkflowGroupModel, WorkflowGroupUserModel } from './Types';
import { PropsWithChildren, createContext } from 'react';
import useReactQueryHub, { ReactQueryHubReturn } from 'src-new/util/hubs/ReactQueryHubHook';
import endpoints from 'src/services/Common/endpoints';

/**
 * some neat notes:
 *  this method dose not hot reload the updates,
 *  you need to invalidate the queryies that match the query key
 *  inoreder for the data to be passed through
 */
const QueryKey = 'WorkflowActivity';
namespace WorkflowClientRpc {
  export function OnJoinWorkflow(queryClient: QueryClient, model: WorkflowGroupModel) {
    queryClient.setQueryData<WorkflowGroupModel>([QueryKey], (workflowModel) => {
      if (!model.CurrentUser && workflowModel?.CurrentUser) {
        model.CurrentUser = workflowModel?.CurrentUser;
      }
      return model;
    });
    queryClient.invalidateQueries({ queryKey: [QueryKey] });
  }
  export function OnActionAssigned(queryClient: QueryClient, model: WorkflowGroupUserModel) {
    queryClient.setQueryData<WorkflowGroupModel>([QueryKey], (workflowModel) => {
      // @ts-expect-error added by automation
      if (model.UserId == workflowModel.CurrentUser?.UserId) {
        // @ts-expect-error added by automation
        workflowModel.CurrentUser = model;
        return workflowModel;
      }
      // @ts-expect-error added by automation
      let index = workflowModel.Users.findIndex((u) => u.UserId == model.UserId);
      if (index >= 0) {
        // @ts-expect-error added by automation
        workflowModel.Users.splice(index, 1);
        if (model.CurrentStatus.AssignedAction != 'Offline') {
          // @ts-expect-error added by automation
          workflowModel.Users.unshift(model);
        }
        return workflowModel;
      }
      if (model.CurrentStatus.AssignedAction != 'Offline') {
        // @ts-expect-error added by automation
        workflowModel.Users.unshift(model);
      }
      return workflowModel;
    });
    queryClient.invalidateQueries({ queryKey: [QueryKey] });
  }
  export function OnInboxManagerChange(queryClient: QueryClient, id: string, name: string) {
    queryClient.setQueryData<WorkflowGroupModel>([QueryKey], (model) => {
      // @ts-expect-error added by automation
      model.InboxManager = name;
      // @ts-expect-error added by automation
      model.InboxManagerId = id;
      return model;
    });
    queryClient.invalidateQueries({ queryKey: [QueryKey] });
  }
}

const URL = endpoints.workflowHub.endpointUrl;

interface WorkflowHubServerRpc {
  Join: () => void;
  ActionAssigned: (
    model: WorkflowGroupUserModel,
    initalActionIndex: number,
    actionId: number,
    userId: string,
    workflowId: number,
    message: string,
    returnTime: string | null
  ) => void;
  UndoLast: (model: WorkflowGroupUserModel, userId: string, workflowId: number) => void;
  ExtendWorkingHours: (model: WorkflowGroupUserModel, extendMinutes: number) => void;
  SetInboxManager: (userId: string, name: string, workflowId: number) => void;
  EndShift: (model: WorkflowGroupUserModel) => void;
}

export const WorkflowContext = createContext<ReactQueryHubReturn<WorkflowHubServerRpc, WorkflowGroupModel>>([
  // @ts-expect-error added by automation
  null,
  // @ts-expect-error added by automation
  null
]);

export default function WorkflowProvider({ children }: PropsWithChildren<any>) {
  const context = useReactQueryHub<WorkflowHubServerRpc, WorkflowGroupModel>(URL, WorkflowClientRpc, QueryKey);
  return <WorkflowContext.Provider value={context}>{children}</WorkflowContext.Provider>;
}
