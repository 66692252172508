import { EmailSignatureModel } from '../../models/email/EmailSignatureModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class EmailSignatureService extends ServiceBase {
  constructor() {
    super(endpoints.admin.emailSignatureManagement);
  }

  public getAll = async () => {
    return await this.get<Array<EmailSignatureModel>>({ id: '' });
  };

  public add = async (model: EmailSignatureModel) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: EmailSignatureModel) => {
    await this.put({ id: '' }, model);
  };

  public remove = async (model: EmailSignatureModel) => {
    await this.delete({ id: model.Id });
  };
}

export default EmailSignatureService;
