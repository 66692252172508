import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { getAxios } from 'src/lib/axios';

type EmailBodyRequestRequestType = {
  ClientEmailId: number | undefined | null;
};
export default function useActivityInfoEmailBodyQuery(doFetch: boolean, request: EmailBodyRequestRequestType) {
  return useQuery({
    queryKey: [ApiEndpoints.activities.getActivityInfoEmailBody(request.ClientEmailId ?? 0)],
    queryFn: fetchEmailBodyRequestType,
    enabled: doFetch && !!request.ClientEmailId
  });
}

async function fetchEmailBodyRequestType({ queryKey }: QueryFunctionContext<[string]>) {
  const [endpoint] = queryKey;
  return getAxios().get<string>(endpoint);
}
