import { makeObservable, observable } from 'mobx';

import EntityFormModel from '../forms/EntityFormModel';

class HotelBookingTypeModel extends EntityFormModel {
  @observable
  // @ts-expect-error added by automation
  public BookingType: string;

  constructor() {
    super();

    makeObservable(this);
  }
}

export default HotelBookingTypeModel;
