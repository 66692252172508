import { MaskedTextField, TextField } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import IInputProps from '../common/IInputProps';

// @ts-expect-error added by automation
interface IStandardTextBox extends IInputProps {
  showPlaceholderChars?: boolean;
  overridePlaceholderChar?: string;
  min?: number;
  max?: number;
  step?: number;
  autoComplete?: string;
  multiline?: boolean;
  autoAdjustHeight?: boolean;
  borderless?: boolean;
  prefix?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  onEnter?: () => void;
}

const StandardTextBox = (props: IStandardTextBox) => {
  let value = props.value;
  if (props.value === null || props.value === undefined) {
    value = '';
  }

  const inputName = props.overrideName ?? props.name;

  const formState = useFormState();
  const formContext = useFormContext();

  React.useEffect(() => {
    formContext.register(inputName, props.inputDefinition.RegisterOptions);
    formContext.setValue(inputName, value);
  }, [formContext, inputName, props.inputDefinition.RegisterOptions, value]);

  const onChangeCallback = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      formContext.setValue(inputName, newValue);
      // @ts-expect-error added by automation
      props.onChange(event, newValue);
    },
    [props, inputName]
  );

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && props.onEnter) {
        event.preventDefault();
        event.stopPropagation();
        props.onEnter();
      }
    },
    [props.onEnter]
  );

  const errorMessage = formState.errors[inputName];
  return props.inputDefinition.Mask ? (
    <MaskedTextField
      label={props.inputDefinition.Label}
      placeholder={props.inputDefinition.Placeholder}
      id={props.id}
      onBlur={props.onBlur}
      disabled={props.disabled}
      mask={props.inputDefinition.Mask}
      errorMessage={errorMessage?.message as any}
      value={value}
      name={inputName}
      onChange={onChangeCallback}
      onKeyUp={props.onEnter !== undefined ? onKeyUp : undefined}
    />
  ) : (
    <TextField
      label={props.inputDefinition.Label}
      placeholder={props.inputDefinition.Placeholder}
      id={props.id}
      onBlur={props.onBlur}
      disabled={props.disabled}
      errorMessage={errorMessage?.message as any}
      value={value}
      name={inputName}
      onChange={onChangeCallback}
      multiline={props.multiline}
      autoAdjustHeight={props.autoAdjustHeight}
      borderless={props.borderless}
      prefix={props.prefix}
      type={props.type}
      onKeyUp={props.onEnter !== undefined ? onKeyUp : undefined}
    />
  );
};

export default observer(StandardTextBox);
