import React from 'react';
import ModalWithButtons from './ModalWithButtons';

interface IModalGenericAlert {
  header: string;
  okCallback?: () => void;
}

const ModalGenericAlert: React.FunctionComponent<IModalGenericAlert> = (props: IModalGenericAlert) => {
  return (
    <ModalWithButtons header={props.header} showCancelButton={false} okCallback={props.okCallback}></ModalWithButtons>
  );
};

export default ModalGenericAlert;
