import { observer } from 'mobx-react-lite';
import * as React from 'react';
import AgentQuerySearchModel from '../../../../models/search/AgentQuerySearchModel';
import InputDefinitionBuilder from '../../../../utils/inputDefinitions/InputDefinitionBuilder';
import { StyledSearchInputDiv } from '../../../styled/baseStyledComponents';
import StandardTextBoxField from '../../textbox/StandardTextBoxField';
import { IInputFieldProps } from '../common/IInputFieldProps';

interface ISearchTextField extends IInputFieldProps {
  model: AgentQuerySearchModel;
  onEnter: () => void;
}

const SearchTextField: React.FC<ISearchTextField> = (props: ISearchTextField) => {
  const [inputDefinition] = React.useState(new InputDefinitionBuilder('Text').Build());
  return (
    <StyledSearchInputDiv>
      <StandardTextBoxField
        name='Text'
        value={props.model.Text}
        onModelChange={props.model.OnModelChange}
        inputDefinition={inputDefinition}
        onEnter={props.onEnter}
      />
    </StyledSearchInputDiv>
  );
};

export default observer(SearchTextField);
