import dayjs from 'dayjs';
import { observable, makeObservable } from 'mobx';
import formatters from '../../common/formatters';
import HotelContractType from '../../enums/crm/HotelContractType';
import { stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';

export class HotelContract extends TimeSeriesEntityFormModel {
  public constructor(defaultDatesToStartAndEndOfYear: boolean = false) {
    super(defaultDatesToStartAndEndOfYear);
    makeObservable(this);
  }

  @observable
  // @ts-expect-error added by automation
  public ContractName: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;
  @observable
  // @ts-expect-error added by automation
  public AttachedFile: File | null;
  @observable
  // @ts-expect-error added by automation
  public FileName: string;
  @observable
  // @ts-expect-error added by automation
  public FileHandle: string;
  @observable
  // @ts-expect-error added by automation
  public RenewalDate: string;
  @observable
  // @ts-expect-error added by automation
  public DiscountCommissionPercentage: number;
  @observable
  // @ts-expect-error added by automation
  public CommissionPercentageDescription: string;
  @observable
  // @ts-expect-error added by automation
  public CommissionPercentage: number;
  @observable
  // @ts-expect-error added by automation
  public DiscountPercentage: number;
  @observable
  // @ts-expect-error added by automation
  public HotelContractType: HotelContractType;

  public SetData(dbData: any, ignoreProps: Array<string> = []): void {
    super.SetData(dbData, ignoreProps);
    if (dbData === undefined || dbData === null) {
      return;
    }
    if (!stringIsNullOrEmpty(dbData.RenewalDate)) {
      let dbRenewalDate = dbData.RenewalDate as string;
      if (dbRenewalDate.endsWith('Z')) {
        //remove timezone information from start/end dates as they do not have times at all. only dates
        dbRenewalDate = dbRenewalDate.substring(0, dbRenewalDate.length - 1);
      }
      this.RenewalDate = dayjs(dbRenewalDate, formatters.momentDate).format(formatters.momentDate);
    }
  }
}
