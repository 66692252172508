import { ItineraryHeaderModel } from 'src/models/activities/ItineraryHeaderModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class ItineraryHeaderService extends ServiceBase {
  constructor() {
    super(endpoints.admin.itineraryHeaderManagement);
  }

  public getAll = async () => {
    return await this.get<Array<ItineraryHeaderModel>>({ id: '' });
  };

  public add = async (model: ItineraryHeaderModel) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: ItineraryHeaderModel) => {
    await this.put({ id: '' }, model);
  };
  public deleteAccount = async (id: number) => {
    await this.delete({ id: `${id}` });
  };
}

export default ItineraryHeaderService;
