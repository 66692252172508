import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import ToolTipButton, { ToolTipButtonProps } from './ToolTipButton';

type ToolTipIconProps = Omit<IconButtonProps, 'icon' | 'aria-label' | 'disabled'> &
  Omit<ToolTipButtonProps, 'children'> & {
    icon: IconDefinition;
    toolTip: string;
  };

export default forwardRef<HTMLButtonElement, ToolTipIconProps>(
  ({ icon, toolTip, disabledWithMessage, ...props }, ref) => {
    return (
      <ToolTipButton disabledWithMessage={disabledWithMessage} toolTip={toolTip}>
        <IconButton
          ref={ref}
          isDisabled={
            disabledWithMessage?.disabledMessages && Array.isArray(disabledWithMessage.disabledMessages)
              ? disabledWithMessage.disabledMessages.length > 0
              : !!disabledWithMessage?.disabledMessages
          }
          variant='ghost'
          cursor={'pointer'}
          color={'secondary.500'}
          icon={<FontAwesomeIcon icon={icon} />}
          aria-label={toolTip}
          minW={'fit-content'}
          minH={'fit-content'}
          border={'1px'}
          width={'fit-content'}
          height={'fit-content'}
          p={1}
          {...props}
        />
      </ToolTipButton>
    );
  }
);
