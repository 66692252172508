import { makeObservable, observable } from 'mobx';
import { INotesModel } from '../common/Interfaces/INotesModel';
import EntityFormModel from '../forms/EntityFormModel';

export class PoliciesAndPreferencesCustomFieldModel extends EntityFormModel implements INotesModel {
  @observable
  // @ts-expect-error added by automation
  public Label: string;
  @observable
  // @ts-expect-error added by automation
  public Value: string;
  @observable
  // @ts-expect-error added by automation
  public Notes: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;

  constructor() {
    super();

    makeObservable(this);
  }
}
