import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';
import InboxManagerPostModel from 'src/models/activities/InboxManagerPostModel';

class InboxManagerService extends ServiceBase {
  constructor() {
    super(endpoints.InboxManager.inboxManager);
  }

  public addToManagerList = async (activityId: number) => {
    await this.post({ route: 'Add' }, { activityId });
  };
  public removeFromManagerList = async (activityId: number) => {
    await this.post({ route: 'Remove' }, { activityId });
  };
  public reOrderManagerList = async (model: InboxManagerPostModel) => {
    await this.post({ route: 'ReOrder' }, model);
  };
}

export default InboxManagerService;
