import ActivityStatus from '../enums/activities/ActivityStatus';

// wonder if we ccould just make the labels the value of the enum?  Not sure if we are using the ints anywhere
//deprecated - [ActivityStatus.Open, 'Ready'],
const activityStatusLabel = new Map<number, string>([
  [ActivityStatus.None, 'Open'],
  [ActivityStatus.Waiting, 'Waiting'],
  [ActivityStatus.Cancelled, 'Cancelled'],
  [ActivityStatus.Delete, 'Deleted'],
  [ActivityStatus.BookingComplete, 'Booking Complete'],
  [ActivityStatus.Folio, 'Folio'],
  [ActivityStatus.PreReconciliation, 'Pre-Reconciliation'],
  [ActivityStatus.IssueReconciliation, 'Issue-Reconciliation'],
  [ActivityStatus.Reconciled, 'Reconciled'],
  [ActivityStatus.Archive, 'Archive - Do Not Use'],
  [ActivityStatus.CheckedOut, 'Checked Out'],
  [ActivityStatus.Merged, 'Merged - Do Not Use'],
  [ActivityStatus.Holding, 'Holding'],
]);
//[ActivityStatus.WaitingForPayment, 'WFP']

export default activityStatusLabel;
