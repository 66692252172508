import dayjs from 'dayjs';
import { observable, makeObservable, computed } from 'mobx';
import { getFormattedServerDate } from '../../utils/helpers/dateHelpers';
import EntityFormModel from '../forms/EntityFormModel';

class HotelBookingQuoteInquiryIssueReconcilationNoteModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  // @ts-expect-error added by automation
  public Comment: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedOn: string;
  @observable
  // @ts-expect-error added by automation
  public CreatedBy: string;

  @computed
  public get CreatedOnMoment() {
    return this.CreatedOn ? dayjs.utc(this.CreatedOn).local() : null;
  }

  @computed
  public get CreatedInformation(): string {
    return `${this.CreatedBy} - ${this.CreatedOnFormatted}`;
  }

  @computed
  public get CreatedOnFormatted(): string {
    return getFormattedServerDate(this.CreatedOn);
  }
}
export default HotelBookingQuoteInquiryIssueReconcilationNoteModel;
