import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFrontierAuth } from 'src-new/util/auth/useFrontierAuth';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';

const Home: React.FC = () => {
  const auth = useFrontierAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (!auth.hasLoaded) {
      return;
    }
    if (auth.isAuthenticated) {
      if (auth.isInRole([ApplicationRoles.reservations])) {
        nav(applicationRoutes.agent.inbox);
      } else if (auth.isInRole([ApplicationRoles.folio])) {
        nav(applicationRoutes.agent.activityFolio);
      } else {
        nav(applicationRoutes.agent.query);
      }
    } else {
      nav(applicationRoutes.account.login);
    }
  }, [auth.isAuthenticated, auth.user, auth.hasLoaded, nav]);

  return (
    <div>
      <p>Welcome to the Frontier Lodging Solutions database system.</p>
    </div>
  );
};

export default observer(Home);
