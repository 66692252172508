import { useIdentityApi } from '@cksoftware/react-base';
import { FrontierUser } from 'src-new/globalModels/frontierUser';
import UserModel from '../../models/account/UserModel';
import ServiceBase from '../Common/ServiceBase';
import endpoints from '../Common/endpoints';

class UserService extends ServiceBase {
  constructor() {
    super(endpoints.accountManagement);
  }

  private identityApi = useIdentityApi();

  public getUser = async () => {
    let response = await this.identityApi.userInformation<FrontierUser>();
    return response;
  };

  public updateUser = async (model: UserModel) => {
    await this.put(null, model);
  };
}

export default UserService;
