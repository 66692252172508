import { action, runInAction, makeObservable } from 'mobx';
import { CompanyModel } from '../../../models/companies/CompanyModel';
import CrmStore from '../../../stores/crmStore';
import endpoints from '../../Common/endpoints';
import ServiceBase from '../../Common/ServiceBase';
import { getAxios } from '../../../lib/axios';
import Endpoint from '../../Common/Endpoint';
import { getFormattedUrl } from '../../../utils/helpers/stringHelpers';
import { downloadObjectBlob } from '../../../utils/helpers/fileHelper';
import CompanyQueryModel from '../../../models/companies/CompanyQueryModel';
import CompanyContactQueryModel from '../../../models/companies/CompanyContactQueryModel';

class CompanyListService extends ServiceBase {
  constructor() {
    super(endpoints.crm.companySearch);
    makeObservable(this);
  }

  @action
  public getCompanies = async (store: CrmStore) => {
    const response = await this.post<Array<CompanyModel>>(null, store.CompanyQueryModel);
    runInAction(() => {
      const list = new Array<CompanyModel>();
      response.data.forEach((x) => {
        const company = new CompanyModel();
        company.SetData(x);
        list.push(company);
      });
      store.Companies.replace(list);
    });
  };

  public exportToExcel = async (model: CompanyQueryModel) => {
    const endpoint = new Endpoint('ExportToExcel', '/api/Company/ExportToExcel');
    const transformedUrl = getFormattedUrl(endpoint);
    await getAxios()
      .post(transformedUrl, model, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      })
      .then(async (response) => {
        await downloadObjectBlob(new Blob([response.data]), 'companies.xlsx');
      });
  };
  public exportContactsToExcel = async (model: CompanyContactQueryModel) => {
    const endpoint = new Endpoint('ExportContactsToExcel', '/api/Company/ExportContactsToExcel');
    const transformedUrl = getFormattedUrl(endpoint);
    await getAxios()
      .post(transformedUrl, model, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      })
      .then(async (response) => {
        await downloadObjectBlob(new Blob([response.data]), 'contacts.xlsx');
      });
  };
}

export default CompanyListService;
