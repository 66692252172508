import { NavItem } from 'src-new/components/MenuNav/types/NavItem';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';

export const ReportingNavItems: NavItem = {
  text: 'Reporting',
  layout: <Layout />,
  allowedRoles: [ApplicationRoles.staff],
  subItems: [
    {
      text: 'Activity Report',
      path: applicationRoutes.agent.activityReport,
      async lazy() {
        let component = await import('src-new/features/activites/activityReport/activityReport');
        return { Component: component.default };
      }
    },
    {
      text: 'Invoicing Group',
      allowedRoles: [ApplicationRoles.accounting],
      path: applicationRoutes.agent.invoiceGroup,
      async lazy() {
        let component = await import('src-new/features/reporting/invoicingGroup/invoicingGroup');
        return { Component: component.default };
      }
    },
    {
      text: 'Booking Report',
      path: applicationRoutes.reporting.bookingReport,
      allowedRoles: [ApplicationRoles.crm, ApplicationRoles.accounting],
      async lazy() {
        let component = await import('src-new/features/reporting/BookingReport');
        return { Component: component.default };
      }
    },
    {
      text: 'Agent Activity Report',
      path: applicationRoutes.reporting.agentActivityReport,
      allowedRoles: [ApplicationRoles.activityAdmin],
      async lazy() {
        let component = await import('src-new/features/reporting/agentActivityReport/AgentActivityReport');
        return { Component: component.AgentActivityReport };
      }
    }
  ]
};
