import { action, observable, makeObservable } from 'mobx';
import ShowActiveModel from './ShowActiveModel';

class ShowApprovedModel extends ShowActiveModel {
  public constructor(Approved: boolean = true) {
    super();
    makeObservable(this);
    this.Approved = Approved;
  }

  @observable
  public Approved: boolean;

  @action
  public ToggleApproved = () => {
    this.Approved = !this.Approved;
  };
}

export default ShowApprovedModel;
