import { action, runInAction, makeObservable } from 'mobx';
import ApplicationManagementStore from '../../stores/applicationManagementStore';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class AdminUserRolesService extends ServiceBase {
  constructor() {
    super(endpoints.selectList.getAllSystemRoles);
    makeObservable(this);
  }

  @action
  public GetAllSystemRoles = async (store: ApplicationManagementStore) => {
    const response = await this.get<Array<string>>();
    runInAction(() => {
      store.Roles.length = 0;
      response.data.forEach((role) => {
        store.Roles.push(role);
      });
    });
  };
}

export default AdminUserRolesService;
