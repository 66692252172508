import { IObservableArray, observable, makeObservable } from 'mobx';
import CompanyQueryResponseModel from '../common/CompanyQueryResponseModel';


class ActivityFormHelperResponseModel {
  constructor() {
    makeObservable(this);
    this.Cities = observable.array(new Array<string>());
    this.Companies = observable.array(new Array<CompanyQueryResponseModel>());
  }

  @observable
  public Cities: IObservableArray<string>;

  @observable
  public Companies: IObservableArray<CompanyQueryResponseModel>;
}
export default ActivityFormHelperResponseModel;
