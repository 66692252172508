import { Box, Button, CloseButton, Flex, HStack, Link, Text, useToast } from '@chakra-ui/react';
import { useStandardToast } from '@cksoftware/react-base';
import { HubConnectionState } from '@microsoft/signalr';
import { AxiosError } from 'axios';
import { PropsWithChildren } from 'react';
import { setHubError } from 'src/hubs/HubBase';
import { setAxiosOnError } from 'src/lib/axios';
import { useThrottledCallback } from 'use-debounce';

export const ErrorContainer = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const toast = useStandardToast();
  const overrideToast = useToast();

  const errorToast = (error: any, url: string) => {
    overrideToast({
      id: 'ApiFailure',
      title: 'API Call Failed',
      status: 'error',
      duration: 15000,
      isClosable: true,
      render(e) {
        return (
          <Box bg={'#c53030'} textColor={'white'} p={3} borderRadius={'10px'}>
            <Flex justify='space-between'>
              <Text as='b'>{e.title}</Text>
              <HStack gap={0}>
                {error && (
                  <Button
                    as={Link}
                    size='xs'
                    variant={'secondary'}
                    href={`mailto:support@cksoftware.ca?subject=Error report for ${url}&body=${error}`}
                    onClick={() => {
                      if (e.id !== undefined) {
                        overrideToast.close(e.id);
                      }
                    }}>
                    Send
                  </Button>
                )}
                <CloseButton onClick={e.onClose} cursor={'pointer'} />
              </HStack>
            </Flex>
            <Text>
              An API call has failed. Please send the below information along with a full screenshot and a description
              of what you were doing to{' '}
              <Link
                textDecoration={'underline'}
                href={`mailto:support@cksoftware.ca?subject=Error report for ${url}&body=${error}`}>
                support@cksoftware.ca
              </Link>
            </Text>
          </Box>
        );
      }
    });
  };

  const throttledErrorToast = useThrottledCallback(errorToast, 5000);
  const throttleWarningToast = useThrottledCallback(toast.warningToast, 5000);

  setAxiosOnError((error: AxiosError) => {
    const url = error?.request?.responseURL ?? 'Unknown';
    const statusCode = error?.response?.status ?? 'Unknown';
    if (url.indexOf('auth/user') >= 0 && statusCode == 400) {
      return;
    }
    if (error.code == 'ERR_NETWORK') {
      throttleWarningToast(
        'Connection Failed',
        `Your connection to the server has failed. Please refresh your browser, if issues persist please contact support.`
      );
    } else {
      let text =
        `API Call to ${url} from ${window.location.href} failed:` +
        '%0D%0A %0D%0A' +
        JSON.stringify(error?.response?.data) +
        '%0D%0A %0D%0A' +
        JSON.stringify(error?.response?.data);

      throttledErrorToast(text, url);
    }
  });

  setHubError((error, connectionStatus: HubConnectionState) => {
    if (connectionStatus === HubConnectionState.Connected) {
      throttledErrorToast(error, 'SignalR');
    } else {
      throttleWarningToast(
        'SignalR Failed',
        `Your hub connection has failed. Please refresh your browser, if issues persist please contact support. ${error}`
      );
    }
  });

  return <>{children}</>;
};
