import styled from 'styled-components';

interface Color {
  backgroundColor?: string;
  color: string;
}

export const ActivityCard = styled.div`
  padding: 5px;
  padding: 10px;
`;

const ActivityBox = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgrey;
  margin: 8px;
  padding-right: 10px;
  justify-content: end;
  gap: 6px;
`;
export const ActivityExpandedBox = styled(ActivityBox)`
  width: 66%;
  justify-content: start;
`;

export const Margin = styled.div`
  margin-right: 10px;
`;

export const ActivityPriority = styled.div<Color>`
  width: 30%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.color};
  padding: 8px;
  margin-right: 10px;
  justify-content: end;
  gap: 2px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
`;

export const Label = styled.div`
  font-size: 13px;
  width: max-content;
  padding-right: 5px;
  font-weight: 400;
`;
export const InfoDisplay = styled.div<Color>`
  font-size: 13px;
  width: max-content;
  padding-right: 5px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.color};
`;

const FlexColumn = styled.div`
  display: flex;
  width: 100%;
`;

export const TopBox = styled(FlexColumn)`
  align-items: center;
  border: 2px solid rgb(175, 175, 175);
  border-bottom: none;
  border-radius: 3px 3px 0px 0px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 15px;
  background-color: rgb(248, 248, 248);
  justify-content: space-between;
`;

export const CommentBox = styled.div`
  width: 400px;
`;
export const FirstColumnBox = styled.div`
  width: 85px;
  padding-left: 10px;
  display: flex;
`;
export const DisplayBox = styled.div`
  width: 125px;
  display: flex;
`;
export const StatusBox = styled.div`
  width: 105px;
  display: flex;
`;

interface IEmailIframe {
  maxHeight: string;
}

export const EmailIframe = styled.iframe<IEmailIframe>`
  height: ${(props) => `calc(${props.maxHeight})`};
  max-height: 100vh;
  width: 100%;
`;
