import { observable, makeObservable } from 'mobx';
import ShowApprovedModel from '../common/ShowApprovedModel';

class HotelManagementQueryModel extends ShowApprovedModel {
  constructor() {
    super();
    makeObservable(this);
    this.City = '';
    this.NameFilter = '';
    this.ManagerFilter = '';
    this.BrandFilter = '';
  }

  @observable
  public City: string;
  @observable
  public NameFilter: string;
  @observable
  public BrandFilter: string;
  @observable
  public ManagerFilter: string;
}
export default HotelManagementQueryModel;
