import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const striped = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          td: {
            background: '#5c91cc26'
          }
        },
        '&:nth-of-type(even)': {
          td: {
            background: 'white'
          }
        }
      }
    }
  };
});

const dataDisplay = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          td: {
            background: '#d2d2d226'
          }
        },
        '&:nth-of-type(even)': {
          td: {
            background: 'white'
          }
        }
      }
    }
  };
});

const fieldArray = definePartsStyle(() => {
  return {
    tbody: {
      tr: {
        td: {
          padding: '5px'
        }
      }
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
});

export const tableTheme = defineMultiStyleConfig({
  variants: { striped, dataDisplay, fieldArray },
  defaultProps: { size: 'sm', variant: 'striped' }
});
