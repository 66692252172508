import { MenuItem } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavItem } from './types/NavItem';
import { useShouldRenderNavItem } from './useShouldRenderNavItem';

type NavMenuSubItemProps = {
  item: NavItem;
};

export const NavMenuSubItem = ({ item }: NavMenuSubItemProps) => {
  const nav = useNavigate();
  const { shouldRender } = useShouldRenderNavItem(item);

  const onNavClick = useCallback(
    (navItem: NavItem) => {
      if (navItem.path) {
        nav(navItem.path);
      }
    },
    [nav]
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <MenuItem
      onContextMenu={(e) => {
        e.preventDefault();
        if (item.path) {
          window.open(item.path, '_blank');
        }
      }}
      onClick={() => {
        onNavClick(item);
      }}>
      {item.isActive && <strong>{item.text}</strong>}
      {!item.isActive && item.text}
    </MenuItem>
  );
};
