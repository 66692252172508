import { Box, HStack, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { UserStatus } from 'src/enums/common/UserStatus';
import { WorkflowGroupActionType, WorkflowGroupUserModel } from './Types';
import { WorkflowCurrentUser } from './WorkflowCurrentUser';
import { WorkflowContext } from './WorkflowProvider';
import { WorkflowUser } from './WorkflowUser';
import { useLayoutStore } from 'src-new/stores/layoutStore';

export enum WorkListMode {
  Max = 0,
  Min = 1,
  Hide = 2
}

function sortUserArray(users: WorkflowGroupUserModel[]) {
  if (!users) {
    return [];
  }
  let toReturn = new Array<WorkflowGroupUserModel>();
  let copy = users.slice();
  copy
    .filter(
      (value) =>
        value.CurrentStatus.AssignedActionType === WorkflowGroupActionType.Ready &&
        value.CurrentStatus.Status != UserStatus.Away
    )
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .forEach((value) => {
      toReturn.push(value);
    });

  copy
    .filter(
      (value) =>
        value.CurrentStatus.AssignedActionType !== WorkflowGroupActionType.Ready &&
        value.CurrentStatus.Status != UserStatus.Away
    )
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .forEach((value) => {
      toReturn.push(value);
    });
  copy
    .filter((value) => value.CurrentStatus.Status == UserStatus.Away)
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .forEach((value) => {
      toReturn.push(value);
    });
  return toReturn;
}

export default function WorkflowList({ mode }: { mode: WorkListMode }) {
  const [userQuery, invoke] = useContext(WorkflowContext);
  const layoutStore = useLayoutStore();
  const [once, setOnce] = useState(true);
  useEffect(() => {
    if (invoke) {
      if (once) {
        setOnce(false);
        invoke('Join');
      }
    }
  }, []);

  useEffect(() => {
    layoutStore.triggerResizeEvent();
  }, [mode]);

  let height = '109px';
  switch (mode) {
    case WorkListMode.Max:
      height = '100%';
      break;
    case WorkListMode.Min:
      height = '80px';
      break;
    case WorkListMode.Hide:
      height = '0';
      break;
  }
  if (mode == WorkListMode.Hide || userQuery == null) {
    return <></>;
  }

  if (userQuery?.isPending) {
    return <Text>Loading....</Text>;
  }

  return (
    <HStack gap={0} height={height} overflow={'hidden'}>
      {userQuery.data?.CurrentUser && (
        <Box alignSelf={'flex-start'} height={'100%'}>
          <WorkflowCurrentUser
            user={userQuery.data?.CurrentUser}
            inboxManagerId={userQuery.data.InboxManagerId}
            actions={userQuery.data.Actions}
            inboxManager={userQuery.data.InboxManager}
          />
        </Box>
      )}
      <HStack alignSelf={'flex-start'} spacing={0} wrap={'wrap'}>
        {mode == WorkListMode.Min
          ? // @ts-expect-error added by automation
            sortUserArray(userQuery.data.Users)
              .slice(0, 18)
              .map((workflowUser) => {
                return (
                  <WorkflowUser
                    key={`${workflowUser.UserId}-2`}
                    user={workflowUser}
                    // @ts-expect-error added by automation
                    inboxManagerId={userQuery.data.InboxManagerId}
                    // @ts-expect-error added by automation
                    actions={userQuery.data.Actions}
                  />
                );
              })
          : //only possible to see the first 18 so we dont wanna fill the DOM
            // @ts-expect-error added by automation
            sortUserArray(userQuery.data.Users).map((workflowUser) => {
              return (
                <WorkflowUser
                  key={`${workflowUser.UserId}-2`}
                  user={workflowUser}
                  // @ts-expect-error added by automation
                  inboxManagerId={userQuery.data.InboxManagerId}
                  // @ts-expect-error added by automation
                  actions={userQuery.data.Actions}
                />
              );
            })}
      </HStack>
    </HStack>
  );
}
