import { useEffect, useState } from 'react';
import styled from '../../utils/styled/styled';
import LoadingSpinner from './LoadingSpinner';

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
`;

const FullPageLoadingSpinner = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    //Only show if page takes longer than a second to load
    const id = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => {
      clearTimeout(id);
    };
  }, []);

  if (!show) {
    return null;
  }
  return (
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  );
};

export default FullPageLoadingSpinner;
