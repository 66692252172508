import { ContextualMenu, mergeStyleSets, Modal as OfficeModal } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import { useUtilityStore } from '../../stores/useMobxStores';
import styled from '../../utils/styled/styled';
import ModalContextProvider from './context/ModalContextProvider';
import { useModalContext } from './context/useModalContext';

const Body = styled.div`
  flex: 4 4 auto;
  padding: 0 24px 24px 24px;
  overflow-y: hidden;
  width: 100%;
`;

const Modal = () => {
  const context = useModalContext();
  const contentStyles = mergeStyleSets({
    container: {
      width: context.modalState.ExactModalWidth,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch'
    }
  });

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: false,
    dragHandleSelector: '.modal-header',
  };

  const customWrapper = `
    .modal-header {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  `;


  return (
    <div>
      <style>{customWrapper}</style>
      <OfficeModal
        scrollableContentClassName='none'
        isBlocking
        dragOptions={dragOptions}
        containerClassName={contentStyles.container}
        isOpen={context.modalState.IsOpen}
        onDismiss={context.modalState.close}>
        <Body>{context.modalState.Content}</Body>
      </OfficeModal>
    </div>
  );
};

export const NonContextModal = observer(Modal);

const Wrapped = () => {
  const store = useUtilityStore();
  return (
    <ModalContextProvider modalState={store.modalState}>
      <NonContextModal />
    </ModalContextProvider>
  );
};

export default observer(Wrapped);
