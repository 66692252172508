import { createContext } from 'react';
import EmailActivityStateModel from '../../../models/activities/EmailActivityStateModel';
import { PagedActivitiesHubBase } from 'src-new/features/activites/EmailActivitesHubProvider';

export interface IEmailActivityHubContext {
  hub: PagedActivitiesHubBase;
  activityState: EmailActivityStateModel;
}

// @ts-expect-error added by automation
const EmailActivityHubContext = createContext<IEmailActivityHubContext>(null);

export { EmailActivityHubContext };
