import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  blueCard: definePartsStyle({
    container: {
      backgroundColor: 'secondary.500',
      borderRadius: '0',
      padding: 0,
      margin: 0
    }
  }),
  roomType: definePartsStyle({
    container: {
      border: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      padding: '4px'
    },
    body: {
      background: '#F6F6F6',
      padding: '2px'
    }
  }),
  noteCard: definePartsStyle({
    container: {
      background: 'white',
      border: '1px solid rgb(221, 222, 224)',
      padding: 0,
      boxShadow: 'none',
      margin: 0
    },
    header: {
      m: 0,
      p: 0
    },
    body: {
      padding: '8px 16px',
      fontSize: 'sm'
    },
    footer: {
      padding: '8px 16px',
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      borderTop: '1px solid rgb(221, 222, 224)',
      fontSize: 'xs'
    }
  }),
  fieldArray: definePartsStyle({
    container: {
      background: 'rgb(243, 243, 243)',
      border: '1px solid #ebebeb',
      borderRadius: '4px',
      boxShadow: 'none'
    },
    header: {
      padding: '6px 6px 2px 6px'
    },
    body: {
      padding: '4px 6px'
    }
  }),
  pageCard: definePartsStyle({
    container: {
      background: 'white',
      border: '1px solid #ebebeb',
      borderRadius: '8px',
      boxShadow: 'md',
      padding: '8px',
      paddingBottom: '0px'
    },
    header: {
      padding: '6px 6px 0px 6px'
    },
    body: {
      padding: '6px'
    },
    footer: {
      padding: '6px'
    }
  }),
  textDisplayCard: definePartsStyle({
    container: {
      background: 'rgba(248,248,248,0.79)',
      border: '1px solid #646464',
      borderRadius: '8px',
      boxShadow: 'md',
      padding: '4px'
    },
    header: {
      padding: '4px 4px 0px 4px',
      fontWeight: 'bold'
    },
    body: {
      padding: '4px'
    }
  })
};

export const cardTheme = defineMultiStyleConfig({
  variants
});
