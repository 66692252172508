import { observable, makeObservable } from 'mobx';
import ICityModel from '../common/Interfaces/ICityModel';
import IHotelIdModel from '../common/Interfaces/IHotelIdModel';
import IHotelNameModel from '../common/Interfaces/IHotelNameModel';
import EntityFormModel from '../forms/EntityFormModel';

export class UnPreferredHotelModel extends EntityFormModel implements ICityModel, IHotelIdModel, IHotelNameModel {
  constructor() {
    super();
    makeObservable(this);
    this.HotelName = 'Save';
  }

  @observable
  // @ts-expect-error added by automation
  public HotelId: number;
  @observable
  public HotelName: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;
  @observable
  // @ts-expect-error added by automation
  public UnPreferredHotelNote: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyId: number;
}
