import { createContext } from 'react';
import ModalState from '../../../models/modal/ModalState';

export interface IModalContext {
  modalState: ModalState;
}

// @ts-expect-error added by automation
const ModalContext = createContext<IModalContext>(null);

export { ModalContext };
