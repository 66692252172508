import { Box, Button, HStack, Icon, Tooltip } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ForwardRefRenderFunction, MouseEventHandler, PropsWithChildren } from 'react';

export type ToolbarButtonProps = PropsWithChildren & {
  icon: IconDefinition;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconColor?: string;
  disabled?: {
    disabledMessages: string[];
  };
};

const ToolbarButton: ForwardRefRenderFunction<HTMLButtonElement, ToolbarButtonProps> = (props, ref) => {
  const getLabel = () => {
    if (!props?.disabled?.disabledMessages) {
      return undefined;
    }
    return (
      <Box>
        {props.disabled.disabledMessages.map((message, index) => (
          <Box key={index}>{message}</Box>
        ))}
      </Box>
    );
  };

  return (
    <Tooltip label={getLabel()}>
      <Button variant={'toolbar'} onClick={props.onClick} ref={ref} isDisabled={!!props.disabled}>
        <HStack>
          <Icon color={props.iconColor ?? 'secondary.500'} as={FontAwesomeIcon} icon={props.icon} aria-label=''></Icon>
          <Box>{props.children}</Box>
        </HStack>
      </Button>
    </Tooltip>
  );
};

export default React.forwardRef(ToolbarButton);
