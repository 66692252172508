import { IContextualMenuItem } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import ActivityStatusLabel from '../../../../constants/ActivityStatusLabels';
import ActivityStatus from '../../../../enums/activities/ActivityStatus';
import CalloutWidth from '../../../../enums/common/CalloutWidth';
import ActivityContextMenuDefinitionModel from '../../../../models/activities/ActivityContextMenuDefinitionModel';
import ActivityMergePostModel from '../../../../models/activities/ActivityPostModel';
import ActivityManagementService from '../../../../services/Activities/ActivityManagementService';
import { useUtilityStore } from '../../../../stores/useMobxStores';
import InboxContextMenuSetCancelledCallout from './InboxContextMenuSetCancelledCallout';
import { ActivityDeletedPostModel } from 'src/models/activities/ActivityDeletedPostModel';
import { useAuth } from '@cksoftware/react-base';
import { ApplicationRoles } from 'src/constants/applicationRoles';

const setStatusLevel = async (postModel: ActivityMergePostModel, activityId: number, level: ActivityStatus) => {
  postModel.ModelId = activityId;
  postModel.Id = +level;
  const service = new ActivityManagementService();
  await service.updateActivityStatus(postModel);
};

export const InboxContextMenuStatus = (
  model: ActivityContextMenuDefinitionModel,
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  const [contextItems, setContextItems] = useState(new Array<IContextualMenuItem>());
  const auth = useAuth();
  const utilStore = useUtilityStore();

  const onSetCancelled = useCallback(
    (postModel: ActivityMergePostModel) => {
      utilStore.calloutState.open(
        ref,
        <InboxContextMenuSetCancelledCallout
          model={postModel}
          onSave={() => {
            setStatusLevel(postModel, model.Id, ActivityStatus.Cancelled);
          }}
        />,
        CalloutWidth.Mid
      );
    },
    [model.Id, ref, utilStore.calloutState]
  );
  const onSetDeleted = useCallback(() => {
    new ActivityManagementService().updateActivityDeleted(new ActivityDeletedPostModel(model.Id));
  }, [model.Id, ref, utilStore.calloutState]);

  useEffect(() => {
    const array = new Array<IContextualMenuItem>();
    const itieraryArray = [
      ActivityStatus.BookingComplete,
      ActivityStatus.CheckedOut,
      ActivityStatus.PreReconciliation,
      ActivityStatus.Reconciled
    ];
    const statuses = [{ status: ActivityStatus.None }, { status: ActivityStatus.Waiting }];

    if (model.ReservationNumber && model.ItinerarySelectedRateId) {
      statuses.push({ status: ActivityStatus.Delete });
    }

    statuses.push(
      { status: ActivityStatus.Archive },
      { status: ActivityStatus.BookingComplete },
      { status: ActivityStatus.CheckedOut },
      { status: ActivityStatus.PreReconciliation },
      { status: ActivityStatus.Reconciled }
    );

    statuses.forEach((item) => {
      if (model.Status !== item.status) {
        if (
          (model.ItinerarySelectedRateId == null || model.ItinerarySelectedRateId == undefined) &&
          itieraryArray.includes(item.status) &&
          !auth.isInRole([ApplicationRoles.activityAdmin])
        ) {
          return;
        }
        const menuItem = {
          key: ActivityStatus[item.status],
          text: 'Set to ' + ActivityStatusLabel.get(item.status),
          onClick: () => {
            const postModel = new ActivityMergePostModel();
            if (item.status === ActivityStatus.Cancelled) {
              onSetCancelled(postModel);
            } else if (item.status === ActivityStatus.Delete) {
              onSetDeleted();
            } else {
              setStatusLevel(postModel, model.Id, item.status);
            }
          }
        };
        array.push(menuItem);
      }
    });

    setContextItems(array);
  }, [model.Id, model.Status, onSetCancelled, model.ItinerarySelectedRateId]);

  return contextItems;
};
