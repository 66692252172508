const hubActions = {
  activities: {
    InitialLoad: 'InitialLoad',
    LoadSingleActivity: 'LoadSingleActivity',
    LoadPage: 'LoadPage',
    UpdateActivity: 'UpdateActivity',
    UpdatePagedActivity: 'UpdatePagedActivity',
    ActivityStatusNotOpen: 'ActivityStatusNotOpen',
    AddToGroup: 'AddToGroup',
    RemoveFromGroup: 'RemoveFromGroup'
  },
  workflow: {
    server: {
      Join: 'Join',
      ActionAssigned: 'ActionAssigned',
      UndoLast: 'UndoLast',
      ExtendWorkingHours: 'ExtendWorkingHours',
      SetInboxManager: 'SetInboxManager'
    },
    client: {
      OnStatusChange: 'OnStatusChange',
      OnJoinWorkflow: 'OnJoinWorkflow',
      OnActionAssigned: 'OnActionAssigned',
      OnShowInitialJoinDialog: 'OnShowInitialJoinDialog',
      OnInboxManagerChange: 'OnInboxManagerChange',
      OnUserOffline: 'OnUserOffline',
      Update: 'Update'
    }
  }
};

export default hubActions;
