import { computed, observable, makeObservable } from 'mobx';
import RateType from '../../enums/crm/RateType';
import { IHotelRate } from '../common/Interfaces/IHotelRateType';
import TimeSeriesEntityFormModel from '../forms/TimeSeriesEntityFormModel';

export class HotelDefaultSeason extends TimeSeriesEntityFormModel implements IHotelRate {
  public constructor() {
    super();
    makeObservable(this);
  }

  @observable
  // @ts-expect-error added by automation
  public Description: string;

  @observable
  // @ts-expect-error added by automation
  public RateType: RateType;

  @computed
  public get RateTypeString() {
    return RateType[Number(this.RateType)];
  }
}
