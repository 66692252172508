import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEmailActivityHub } from '../../containers/agent/context/useEmailActivityHub';
import { IEmailActivityOverviewModel } from '../../models/activities/IEmailActivityOverviewModel';
import { runInAction } from 'mobx';
import { EmailOverviewTabModel } from '../../models/email/EmailOverviewTabModel';
import { useUtilityStore } from '../../stores/useMobxStores';
import FaIconWithToolTip from '../../components/common/FaIconWithToolTip';

interface IActivityOpenMerged {
  item: IEmailActivityOverviewModel;
}

const ActivityOpenMerged: React.FC<IActivityOpenMerged> = (props) => {
  const { activityState } = useEmailActivityHub();
  const utilStore = useUtilityStore();
  const onItemInvoked = () => {
    runInAction(() => {
      activityState.OpenActivityTabs.find((x) => x.ActivityId === props.item.MergedActivityId) == null &&
        activityState.OpenActivityTabs.push(
          // @ts-expect-error added by automation
          new EmailOverviewTabModel(props.item.MergedActivityId, props.item.Subject, props.item.Status)
        );
      // @ts-expect-error added by automation
      activityState.OpenActivityTabSelectedKey = props.item.MergedActivityId.toString();
      utilStore.calloutState.close();
    });
  };
  return props.item.MergedActivityId != null ? (
    <div>
      <FaIconWithToolTip
        tooltip={'Go to merged activity'}
        clickable
        iconName={'fa-folder-open'}
        onClick={onItemInvoked}></FaIconWithToolTip>
      <span onClick={onItemInvoked}> Open</span>
    </div>
  ) : (
    <div></div>
  );
};

export default observer(ActivityOpenMerged);
