import { DialogFooter, getTheme, IconButton } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ModalHeader } from 'reactstrap';
import FormWrapper from '../../app/form/FormWrapper';
import styled from '../../utils/styled/styled';
import { ClsDefaultButton, ClsSubmitButtonDisable } from '../base/buttons';
import { Flex, FlexItem } from '../base/flex';
import { useModalContext } from './context/useModalContext';

const theme = getTheme();

const MinHeightBody = styled.div`
  min-height: 50px;
`;

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '16px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

interface IModalWithButtons {
  header?: string;
  cancelCallback?: () => void;
  okCallback?: () => void;
  okSecondaryCallback?: () => void;
  okButtonText?: string;
  okSecondaryButtonText?: string;
  cancelButtonText?: string;
  closeOnOk?: boolean;
  closeOnOkSecondary?: boolean;
  showCancelButton: boolean;
  showCornerCloseButton?: boolean;
  showButtonsOnTop?: boolean;
  hideOkButton?: boolean;
  hideOkSecondaryButton?: boolean;
  disabledOk?: boolean;
  disabledOkSecondary?: boolean;
  form?: UseFormReturn;
  disabledMessage?: Array<string>;
  disabledHeader?: string;
  cancelCallbackOpensModal?: boolean;
}

const ModalWithButtons = (props: PropsWithChildren<IModalWithButtons>) => {
  const context = useModalContext();
  const [isSecondSubmit, setIsSecondSubmit] = useState(false);
  const onOk = React.useCallback(() => {
    if (props.okCallback) {
      props.okCallback();
    }
    if (props.closeOnOk !== false) {
      context.modalState.close();
    }
  }, [context.modalState, props]);

  const onCancel = React.useCallback(() => {
    if (props.cancelCallbackOpensModal === true) {
      context.modalState.close();
      // @ts-expect-error added by automation
      props.cancelCallback();
      return;
    }

    if (props.cancelCallback) {
      props.cancelCallback();
    }
    context.modalState.close();
  }, [context.modalState, props]);

  const onOkSecondary = React.useCallback(() => {
    if (props.okSecondaryCallback) {
      props.okSecondaryCallback();
    }
    if (props.closeOnOkSecondary !== false) {
      context.modalState.close();
    }
  }, [context.modalState, props]);

  // @ts-expect-error added by automation
  const submitHandler = (e) => {
    if (e === false) {
      setIsSecondSubmit(false);
    } else {
      setIsSecondSubmit(true);
    }
  };

  return (
    <FormWrapper
      form={props.form}
      onSubmit={() => {
        if (isSecondSubmit === false) onOk();
        if (isSecondSubmit === true) onOkSecondary();
      }}>
      {props.header && (
        <Flex>
          <FlexItem grow={1}>
            <ModalHeader>{props.header}</ModalHeader>
          </FlexItem>
          <FlexItem>
            {props.showCornerCloseButton && (
              <IconButton
                styles={iconButtonStyles}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel='Close popup modal'
                onClick={onCancel}
              />
            )}
          </FlexItem>
        </Flex>
      )}
      {props.showButtonsOnTop && (
        <DialogFooter>
          {!props.hideOkButton && (
            <ClsSubmitButtonDisable
              disabled={props.disabledOk}
              onClick={() => {
                submitHandler(false);
              }}
              text={props.okButtonText ?? 'Ok'}
              disabledMessage={props.disabledMessage}
              disabledHeader={props.disabledHeader}
            />
          )}
          {!props.hideOkSecondaryButton && props.okSecondaryButtonText && (
            <ClsSubmitButtonDisable
              disabled={props.disabledOkSecondary}
              onClick={() => {
                submitHandler(true);
              }}
              text={props.okSecondaryButtonText ?? 'Ok'}
              disabledMessage={props.disabledMessage}
              disabledHeader={props.disabledHeader}
            />
          )}
          {props.showCancelButton && <ClsDefaultButton onClick={onCancel} text={props.cancelButtonText ?? 'Cancel'} />}
        </DialogFooter>
      )}
      <MinHeightBody>{props.children}</MinHeightBody>
      <DialogFooter>
        {!props.hideOkButton && (
          <ClsSubmitButtonDisable
            disabled={props.disabledOk}
            onClick={() => {
              submitHandler(false);
            }}
            text={props.okButtonText ?? 'Ok'}
            disabledMessage={props.disabledMessage}
            disabledHeader={props.disabledHeader}
          />
        )}
        {!props.hideOkSecondaryButton && props.okSecondaryButtonText && (
          <ClsSubmitButtonDisable
            onClick={() => {
              submitHandler(true);
            }}
            text={props.okSecondaryButtonText ?? 'Ok'}
            disabledMessage={props.disabledMessage}
            disabledHeader={props.disabledHeader}
          />
        )}
        {props.showCancelButton && <ClsDefaultButton onClick={onCancel} text={props.cancelButtonText ?? 'Cancel'} />}
      </DialogFooter>
    </FormWrapper>
  );
};

export default observer(ModalWithButtons);
