import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class MyActivityAutoAssignService extends ServiceBase {
  constructor() {
    super(endpoints.autoAssign.myActivityAutoAssign);
  }

  public set = async () => {
    await this.get(null);
  };
}

export default MyActivityAutoAssignService;
