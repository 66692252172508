import { DirectionalHint, Point } from '@fluentui/react';
import { action, computed, makeObservable, observable } from 'mobx';
import * as React from 'react';
import CalloutGenericConfirm from '../../../components/callout/CalloutGenericConfirm';
import CalloutWidth from '../../../enums/common/CalloutWidth';

class CalloutState {
  @observable
  // @ts-expect-error added by automation
  public Target: React.MutableRefObject<HTMLDivElement> | Point;
  @observable
  public CloseOnClick: boolean;
  @observable
  public CloseOnEscape: boolean;
  @observable
  public IsOpen: boolean;
  @observable
  // @ts-expect-error added by automation
  public OkCallBack: () => void;
  @observable
  public Content: React.ReactNode;
  @observable
  private callOutWidth?: CalloutWidth;
  @observable
  public Direction?: DirectionalHint;
  @observable
  preventDismiss?: (
    ev:
      | Event
      | React.FocusEvent<Element, Element>
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
  ) => boolean;

  public constructor() {
    makeObservable(this);
    this.IsOpen = false;
    this.CloseOnClick = false;
    this.CloseOnEscape = false;
  }

  @action
  public showGenericConfirmation = (
    target: React.MutableRefObject<HTMLDivElement>,
    alert: string,
    okCallback?: () => void,
    cancelCallback?: () => void
  ) => {
    this.open(target, <CalloutGenericConfirm header={alert} cancelCallback={cancelCallback} okCallback={okCallback} />);
  };

  @action
  public open = (
    target: React.MutableRefObject<HTMLDivElement> | Point,
    content?: React.ReactNode,
    calloutWidth?: CalloutWidth,
    direction?: DirectionalHint,
    preventDismiss?: (
      ev:
        | Event
        | React.FocusEvent<Element, Element>
        | React.KeyboardEvent<Element>
        | React.MouseEvent<Element, MouseEvent>
    ) => boolean
  ) => {
    this.IsOpen = true;
    this.Target = target;
    this.Content = content;
    this.callOutWidth = calloutWidth;
    this.Direction = direction;
    this.preventDismiss = preventDismiss;
  };

  @action
  public close = () => {
    this.IsOpen = false;
  };

  @computed
  public get ExactCalloutWidth(): string {
    switch (this.callOutWidth) {
      case CalloutWidth.Large:
        return '800px';
      case CalloutWidth.Mid:
        return '600px';
      case CalloutWidth.Normal:
      default:
        return '400px';
    }
  }
}

export default CalloutState;
