import React from 'react';
import { formatPhoneNumber } from '../../utils/helpers/crmHelpers';
import TableTooltip from './TableTooltip';

interface IPhoneDisplayProps {
  number: string;
  displayNumber: boolean;
  isTextMagicNumber?: boolean;
}
const PhoneDisplay: React.FC<IPhoneDisplayProps> = (props: IPhoneDisplayProps) => {
  return props.displayNumber ? (
    <a href={`tel:${props.number}`} target='_blank' rel='noreferrer'>
      {props.isTextMagicNumber ? (
         formatPhoneNumber(props.number) + '@TextMagic.com')
         :
         formatPhoneNumber(props.number) }
    </a>
  ) : (
    <TableTooltip onRenderTooltip={<span>{props.number}</span>}>
      <i className='fas fa-phone'></i>
    </TableTooltip>
  );
};

export default PhoneDisplay;
